import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { getStyle } from '../../../../utils/utils';
import CBMediaQuery from '../../../shared/CBMediaQuery';

export default function TaskFilterBar({ filters, setFilters }) {
  const statusOptions = [
    { name: 'Luotu', value: 'created' },
    { name: 'Työn alla', value: 'in_progress' },
    { name: 'Valmis', value: 'done' },
    { name: 'Peruutettu', value: 'cancelled' },
  ]

  return (
    <CBMediaQuery>
      {screenSize => (
        <div style={getStyle(screenSize, styles, 'container')}>
          <div style={getStyle(screenSize, styles, 'filterContainer')}>
            <p>Haku</p>
            <InputText
              value={filters.search}
              placeholder='Hae tehtävää'
              onChange={(e) => {
                return setFilters({ ...filters, search: e.target.value })
              }}
            />
          </div>
          <div style={styles.filterContainer}>
            <p>Näytettävät tilat</p>
            <MultiSelect
              value={filters.statuses}
              onChange={(e) => {
                if (e.value.length === 0) {
                  return;
                }
                return setFilters({ ...filters, statuses: e.value })
              }}
              options={statusOptions}
              optionLabel="name"
              placeholder="Näytettävät tilat"
              style={{ width: 240 }}
            />
          </div>
        </div>
      )}
    </CBMediaQuery>
  )
}


const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      padding: 8,
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      padding: 8,
    },
  }
}