import React from 'react';

export default function ImportResultTable({resultRows}) {
    const headers = Object.keys(resultRows[0] || []);
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            {
              headers.map((head, index) => (
                <td key={`${head}-${index}`} style={styles.tableHeading}>{head}</td>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {resultRows.map((row, index) => (
            <tr style={styles.row} key={index}>
              {
                headers.map((head, index) => (
                  <td key={`${head}-${index}`} style={styles.tableTd}>{row[head]}</td>
                ))
              }
            </tr>
          ))}
        </tbody>
      </table>
    )
}

const styles = {
  table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderRadius: 8,
      padding: 16,
      marginBottom: 32,
    },
    textSubHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 16,
      color: '#ffffff',
      marginBottom: 16,
    },
    tableTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 16,
      paddingRight: 16,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 16,
      paddingBottom: 8,
    },
    row: {
      marginBottom: 8,
    },
};