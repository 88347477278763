import React from 'react';

class VehicleDetailsLabelAndValue extends React.Component {
  render() {
    const { alignRight, label, value, black=false } = this.props;

    return (
      <div style={alignRight ? styles.containerAlignRight : {}}>
        <p className="font-titillium-web-semi-bold" style={{...styles.infoLabel, color: black ? 'black' : '#FFF', textAlign: alignRight ? 'right' : 'left'}}>{label}</p>
        <p className="font-titillium-web-light" style={{...styles.infoValue, color: black ? 'black' : '#FFF', textAlign: alignRight ? 'right' : 'left'}}>{value}</p>
      </div>
    )
  }
}

const styles = {
  containerAlignRight: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  infoLabel: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600
  },
  infoValue: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 200
  },
}

export default VehicleDetailsLabelAndValue;
