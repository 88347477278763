import React from 'react';
import { translate } from 'react-i18next';
import { getStyle, styleMerge } from '../../../utils/utils';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import CBDropzone from '../../shared/CBDropzone';
import CBMediaQuery from '../../shared/CBMediaQuery';


class TaskEditRow extends React.Component {
  state = {
  }

  render() {
    const {
      t,
      openDeleteTaskModal,
      editTask,
      openStatusModal,
      task,
      isDone
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxContainer')}>
            <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxLeftContainer')}>
              {
                ['cancelled', 'created'].includes(task.status) && (
                  <CBButtonGarageAction
                    mainIcon='/img/icon_task_in_progress.svg'
                    text={t('taskMarkInProgress', { ns: 'TaskListView' })}
                    onClick={() => { openStatusModal('in_progress'); }}
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                  />
                )
              }
              {
                ['in_progress'].includes(task.status) && (
                  <CBButtonGarageAction
                    mainIcon='/img/icon_task_done.svg'
                    text={t('taskStatusDoneTitle', { ns: 'TaskListView' })}
                    onClick={() => { openStatusModal('done'); }}
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                  />
                )
              }
              {
                ['done', 'created', 'in_progress'].includes(task.status) && (
                  <CBButtonGarageAction
                    mainIcon='/img/icon_task_cancelled.svg'
                    text={t('taskCancelTitle', { ns: 'TaskListView' })}
                    onClick={() => { openStatusModal('cancelled'); }}
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                  />
                )
              }
            </div>
            {
              task.can_edit && (
                <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxRightContainer')}>
                  <CBButtonGarageAction
                    mainIcon='/img/icon_trashcan.svg'
                    text='Poista'
                    onClick={openDeleteTaskModal}
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                  />
                  <CBButtonGarageAction
                    mainIcon='/img/icon_edit.svg'
                    text='Muokkaa'
                    onClick={editTask}
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                  />
                </div>
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    boxLeftContainer: {
      display: 'flex',
    },
    boxRightContainer: {
      display: 'flex',
    },
  },
};

export default translate('VehicleDetailsView')(TaskEditRow);
