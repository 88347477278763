export const vehicleState = {
  garageVehicles: [],
  careServiceVehicles: [],
  vehicles: [],
  wsVehicles: [],
  wsVehiclePagination: {},
  vehicle: null,
  garageVehicles: [],
  documents: [],
  garageVehicleStatus: {
    loading: false,
    error: null,
  },
  vehicleStatus: {
    loading: false,
    error: null,
  },
  careServiceVehicleStatus: {
    loading: false,
    error: null,
  },
  garageVehicleStatus: {
    loading: false,
    error: null,
  },
  wsVehicleStatus: {
    loading: false,
    error: null,
  },
  maintenanceStatus: {
    loading: false,
    error: null,
  },
  treatmentStatus: {
    loading: false,
    error: null,
  },
  treatmentMessageStatus: {
    loading: false,
    error: null,
  },
  inspectionStatus: {
    loading: false,
    error: null,
  },
  descriptionStatus: {
    loading: false,
    error: null,
  },
  reminderStatus: {
    loading: false,
    error: null,
  },
  imageStatus: {
    loading: false,
    error: null,
  },
  treatmentImageStatus: {
    loading: false,
    error: null,
  },
  messageStatus: {
    loading: false,
    error: null,
  },
  messageImageStatus: {
    loading: false,
    error: null,
  },
  taskStatus: {
    loading: false,
    error: null,
  },
  mileageStatus: {
    loading: false,
    error: null,
  },
  documentStatus: {
    loading: false,
    error: null,
  },
};

export const careServiceState = {
  careServices: [],
  careServiceStatus: {
    loading: false,
    error: null,
  },
  careServiceListStatus: {
    loading: false,
    error: null,
  },
};

export const checkListState = {
  checkLists: [],
  vehicleCheckListItems: [],
  checkListStatus: {
    loading: false,
    error: null,
  },
};

export const organizationVehicleReminderState = {
  organizationVehicleReminders: [],
  organizationVehicleReminderStatus: {
    loading: false,
    error: null,
  },
};

export const priceRuleState = {
  priceRules: [],
  priceRuleStatus: {
    loading: false,
    error: null,
  },
  reportPriceRules: [],
  reportPriceRuleStatus: {
    loading: false,
    error: null,
  },
};

export const invoiceReportState = {
  invoiceReports: [],
  invoiceReport: null,
  invoiceReportStatus: {
    loading: false,
    error: null,
  },
};

export const searchState = {
  maintenanceIDs: null,
  treatmentIDs: null,
  messageIDs: null,
  taskIDs: null,
  treatmentFilters: {
    search: '',
    treatmentType: 'all',
  },
  maintenanceFilters: {
    search: '',
  },
  wsVehicleFilters: {
    search: '',
  },
  searchStatus: {
    loading: false,
    error: null,
  },
  messageFilters: {
    search: '',
  },
  taskFilters: {
    search: '',
  },
};

export const vehicleCategoryState = {
  vehicleCategories: [],
  categoryStatus: {
    loadingFetch: false,
    loading: false,
    error: null,
  },
  instructionsStatus: {
    loading: false,
    error: null,
  },
  instructions: null,
  tosStatus: {
    loading: false,
    error: null,
  },
  tos: null,
};

export const taskState = {
  tasks: [],
  taskStatus: {
    loading: false,
    error: null,
  },
};

export const predictedMaintenanceState = {
  predictedMaintenances: [],
  predictedMaintenanceStatus: {
    loading: false,
    error: null,
  },
};

export const messageState = {
  messages: [],
  messageStatus: {
    loading: false,
    error: null,
  },
};

export const vehiclePermissionState = {
  vehiclePermissions: [],
  loading: false,
  error: null,
  errorCreation: null,
};

export const workshopState = {
  favouriteWorkshops: [],
  workshops: [],
  smsAvailable: null,
  smsMax: null,
  selectedWorkshop: undefined,
  favouriteStatus: {
    loading: false,
    error: null,
  },
  workshopListStatus: {
    loading: false,
    error: null,
  },
  workshopStatus: {
    loading: false,
    error: null,
  },
  smsPackageStatus: {
    loading: false,
    error: null,
  },
  smsCountsStatus: {
    loading: false,
    error: null,
  },
};

export const organizationState = {
  organizations: [],
  selectedOrganization: undefined,
  organizationListStatus: {
    loading: false,
    error: null,
  },
  organizationStatus: {
    loading: false,
    error: null,
  },
};

export const garageState = {
  garages: [],
  garageListStatus: {
    loading: false,
    error: null,
  },
  garageStatus: {
    loading: false,
    error: null,
  },
};

export const authUserState = {
  user: null,
  userStatus: {
    loading: false,
    error: null,
  },
  profileStatus: {
    loading: false,
    error: null,
  },
};

export const userState = {
  users: [],
  search: '',
  totalUsers: 0,
  usersStatus: {
    loading: false,
    error: null,
  },
  profileStatus: {
    loading: false,
    error: null,
  },
};

export const advertisementState = {
  advertisements: [],
  advertisementsStatus: {
    loading: false,
    error: null,
  },
};

export const announcementState = {
  announcements: [],
  announcementsStatus: {
    loading: false,
    error: null,
  },
};

export const globalStatsState = {
  current: {},
  globalStatsStatus: {
    loading: false,
    error: null,
  },
};

export const invisibilityPeriodState = {
  invisibilityPeriods: [],
  invisibilityPeriodsStatus: {
    loading: false,
    error: null,
  },
};

export const appState = {
  service: '',
  lastListUrl: null,
};
