import React from 'react';
import PropTypes from 'prop-types';
import CBButtonSubmit from '../../shared/CBButtonSubmit';

function FileBox({ fileName, mimeType, onDelete }) {
  const styles = {
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fafafa',
      border: '1px solid #ddd',
      borderRadius: '4px',
      padding: '0.75rem 1rem',
      marginBottom: '0.75rem',
      justifyContent: 'space-between',
    },
    icon: {
      width: '40px',
      height: '40px',
      marginRight: '0.75rem',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    name: {
      fontWeight: 500,
      color: '#333',
    },
    mime: {
      fontSize: '0.875rem',
      color: '#888',
    },
    cancelButtonText: {
      fontSize: 14,
      fontWeight: 700,
      textDecoration: 'none solid rgb(0, 246, 0)',
      color: 'black',
      padding: 8,
      marginBottom: 0,
    },
    cancelButton: {
      maxWidth: 200,
      marginTop: 8,
      width: '100%',
      border: '1px solid #00000050',
      color: '#000000',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.container}>
      <img
        style={styles.icon}
        src="/img/icon_file_blue.svg"
        alt="File Icon"
      />
      <div style={styles.info}>
        <span style={styles.name}>{fileName}</span>
        <span style={styles.mime}>{mimeType}</span>
      </div>
      <CBButtonSubmit
        disabled={false}
        onClick={onDelete}
        text='Poista tiedosto'
        textStyle={styles.cancelButtonText}
        style={styles.cancelButton}
      />
    </div>
  );
}

FileBox.propTypes = {
  fileName: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
};

export default FileBox;
