import React, { Component } from 'react';
import CBMediaQuery from './CBMediaQuery';
import { getStyle, styleMerge } from '../../utils/utils';

class CBButtonGarageAction extends Component {
  render() {
    const {
      onClick,
      text,
      additionalIcon,
      mainIcon,
      additionalStylesMainIcon,
      disabled,
      containerStyle,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div className={!disabled ? 'opacity-and-border-on-hover' : ''} style={styleMerge(containerStyle || getStyle(screenSize, styles, 'container'), disabled ? { opacity: 0.5, cursor: 'default' } : {})} onClick={!disabled ? onClick : () => {}}>
            {
              additionalIcon && (
                <img src={additionalIcon} alt='' style={getStyle(screenSize, styles, 'additionalIcon')} />
              )
            }

            <img
              src={mainIcon}
              alt=''
              style={styleMerge(getStyle(screenSize, styles, 'mainIcon'), additionalStylesMainIcon)}
            />
            {screenSize !== 'small' && (
              <p style={getStyle(screenSize, styles, 'label')}>
                {text}
              </p>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid rgba(255,255,255,.3)',
      borderRadius: 50,
      paddingLeft: 10,
      paddingRight: 10,
      height: 40,
      marginRight: 10,
      cursor: 'pointer',
    },
    mainIcon: {
      height: 35,
      width: 35,
    },
    additionalIcon: {
      height: 24,
      width: 24,
    },
    label: {
      fontSize: 14,
      margin: 0,
      marginLeft: 10,
    },
  },
  small: {},
};

export default CBButtonGarageAction;
