import {
    API, GET, POST, PUT, DELETE,
  } from '../middleware/api';
  import { API_URL } from '../config';

  export function fetchPredictedMaintenances(params, workshopId = undefined, organizationId = undefined) {
    const prefix = workshopId ? 'cs_' : '';

    const newParams = {
      ...params,
    };

    if (workshopId) {
      newParams.workshop_id = workshopId;
    }

    if (organizationId) {
      newParams.organization_id = organizationId;
    }

    return {
      [API]: {
        method: GET,
        url: `${API_URL}/uiapi/1/${prefix}predicted_maintenance/`,
        types: [
          'FETCH_PREDICTED_MAINTENANCES_REQUEST',
          'FETCH_PREDICTED_MAINTENANCES_SUCCESS',
          'FETCH_PREDICTED_MAINTENANCES_FAIL',
        ],
        params: newParams,
      },
    };
  }