import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { getStyle } from '../../../../utils/utils';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import AddMileageModal from '../Modal/AddMileageModal';

class AddMileageButton extends Component {
  state = {
    modalIsOpen: false,
  };

  addMileage = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  afterOpenModal = () => {};

  render() {
    const { t, vehicle } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <div
              style={getStyle(screenSize, styles, 'container')}
              onClick={this.addMileage}
            >
              <img src='/img/icon_15.svg' alt='Add' style={getStyle(screenSize, styles, 'icon')} />
              <p style={getStyle(screenSize, styles, 'label')}>
                {t('addMileageLabel')}
              </p>
            </div>
            {
              modalIsOpen && (
                <AddMileageModal
                  vehicle={vehicle}
                  modalIsOpen={modalIsOpen}
                  closeModal={this.closeModal}
                  afterOpenModal={this.afterOpenModal}
                />
              )
            }

          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      height: 52,
      alignItems: 'center',
      backgroundColor: 'rgba(0, 246, 0, 0.3)',
      border: 'solid 2px rgba(0, 246, 0, 1)',
      cursor: 'pointer',
    },
    label: {
      fontSize: 14,
      color: '#00f600',
      marginLeft: 10,
      marginBottom: 0,
    },
    icon: {
      height: 32,
      width: 32,
    },
  },
};

export default translate('VehicleDetailsView')(AddMileageButton);
