import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle, finnishDateFormat } from '../../../../utils/utils';
import {
  updateVehicle as _updateVehicle,
  updateOrganizationVehicle as _updateOrganizationVehicle,
} from '../../../../actions/vehicleActions';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import CBButtonSubmit from '../../../shared/CBButtonSubmit';
import CBInputField from '../../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../../utils/validators';
import CBModal from '../../../shared/CBModal';
import CBDatePickerInput from '../../../shared/CBDatePickerInput';
import moment from 'moment';

Modal.setAppElement('#root');

class EditVehicleTopInfoModal extends Component {
  state = {
    formData: {
      year_model: '',
      owner_description: '',
      organization_data: {
        identifier: '',
        maintenance_interval_months: '',
        maintenance_interval_km: null,
      },
      last_periodic_maintenance: {
        date: '',
        km: null,
        maintenance: null,
      },
      organization_vehicle_status: {
        business_unit: '',
        employee: '',
      }
    },
  };

  componentDidMount = () => {
    const { vehicle } = this.props;
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);

    if (vehicle.year_model) {
      newFormData.year_model = vehicle.year_model;
    }

    if (vehicle.owner_description) {
      newFormData.owner_description = vehicle.owner_description;
    }

    if (vehicle.organization_data) {
      newFormData.organization_data.identifier = vehicle.organization_data.identifier;
      newFormData.organization_data.maintenance_interval_months = vehicle.organization_data.maintenance_interval_months;
      newFormData.organization_data.maintenance_interval_km = vehicle.organization_data.maintenance_interval_km;
    }

    if (vehicle.last_periodic_maintenance) {
      newFormData.last_periodic_maintenance.date = vehicle.last_periodic_maintenance.date;
      newFormData.last_periodic_maintenance.km = vehicle.last_periodic_maintenance.km;
      newFormData.last_periodic_maintenance.maintenance = vehicle.last_periodic_maintenance.maintenance;
    }

    if (vehicle.organization_vehicle_status) {
      newFormData.organization_vehicle_status.business_unit = vehicle.organization_vehicle_status.business_unit;
      newFormData.organization_vehicle_status.employee = vehicle.organization_vehicle_status.employee;
    }

    this.setState({
      formData: newFormData
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const {
      formData
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData
    });
  };

  updateOrganizationDataFormState = changedObject => {
    const {
      formData
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['organization_data'][changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData
    });
  };

  updateLastPeriodicMaintenanceFormState = changedObject => {
    const {
      formData
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['last_periodic_maintenance'][changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData
    });
  };

  updateOrganizationVehicleStatusFormState = changedObject => {
    const {
      formData
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['organization_vehicle_status'][changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData
    });
  };


  onSubmit = () => {
    const {
      vehicle,
      updateVehicle,
      updateOrganizationVehicle,
      isOrganizationVehicle,
      selectedOrganizationId,
      selectedWorkshopId,
      closeModal
    } = this.props;
    const { formData } = this.state;

    const copyFormData = Object.assign({}, formData);

    if (vehicle) {
      if (isOrganizationVehicle) {
        updateOrganizationVehicle(vehicle.id, copyFormData, vehicle.organization_id, selectedWorkshopId || null);
      } else {
        delete copyFormData.organization_data;
        delete copyFormData.last_periodic_maintenance;
        delete copyFormData.organization_vehicle_status;
        updateVehicle(vehicle.id, copyFormData);
      }
    }
    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t, isOrganizationVehicle
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={t('vehicleTopInfoModalHeading')}
            cancelText={t('cancel')}
            submitText={t('save')}
            submitForm={this.onSubmit}
            isValid={true}
            >
            {
              isOrganizationVehicle && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                    <p style={getStyle(screenSize, styles, 'subHeading')}>{t('organizationDataLabel')}</p>
                    <CBInputField
                      name='identifier'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={formData.organization_data.identifier}
                      onChange={this.updateOrganizationDataFormState}
                      labelText={t('identifierLabel')}
                      labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                    />
                    <CBInputField
                      name='business_unit'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={formData.organization_vehicle_status.business_unit}
                      onChange={this.updateOrganizationVehicleStatusFormState}
                      validators={[getLengthValidator(0, 21)]}
                      isValidCallback={this.setIsValid}
                      labelText={t('businessUnitLabel')}
                      labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                    />
                    <CBInputField
                      name='employee'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={formData.organization_vehicle_status.employee}
                      onChange={this.updateOrganizationVehicleStatusFormState}
                      validators={[getLengthValidator(0, 21)]}
                      isValidCallback={this.setIsValid}
                      labelText={t('userLabel')}
                      labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                    />
                  </div>

                  <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                    <p style={getStyle(screenSize, styles, 'subHeading')}>
                      {t('periodicMaintenanceLabel')}
                    </p>
                    <p style={getStyle(screenSize, styles, 'infoText')}>
                      {t('periodicMaintenanceInfo')}
                      <a href='/settings/predicted-maintenance-settings'>{t('here')}</a>
                    </p>
                    <CBInputField
                      name='maintenance_interval_months'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={formData.organization_data.maintenance_interval_months}
                      type='number'
                      onChange={this.updateOrganizationDataFormState}
                      labelText={t('mainentanceIntervalMonthsLabel')}
                      labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                    />
                    <CBInputField
                      name='maintenance_interval_km'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={formData.organization_data.maintenance_interval_km}
                      type='number'
                      onChange={this.updateOrganizationDataFormState}
                      labelText={t('mainentanceIntervalKmLabel')}
                      labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                    />
                    <div style={getStyle(screenSize, styles, 'inputContainer')}>
                      <p
                        className='font-titillium-web-semi-bold'
                        style={getStyle(screenSize, styles, 'dateInputLabel')}
                      >
                        {t('lastPeriodicMaintenanceDateLabel')}
                      </p>
                      <div className='datepicker-container-white-bg'>
                        <CBDatePickerInput
                          displayedDate={formData.last_periodic_maintenance.date ? moment(formData.last_periodic_maintenance.date) : null}
                          type='date'
                          dateFormat={finnishDateFormat}
                          changeDate={(value) => {
                            this.updateLastPeriodicMaintenanceFormState({ target: 'date', value: value ? moment(value).format('YYYY-MM-DD') : null});
                          }}
                        />
                      </div>
                    </div>
                    <CBInputField
                      name='km'
                      inputStyle={getStyle(screenSize, styles, 'input')}
                      value={formData.last_periodic_maintenance.km}
                      type='number'
                      onChange={this.updateLastPeriodicMaintenanceFormState}
                      labelText={t('lastPeriodicMaintenanceKmLabel')}
                      labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                    />
                  </div>
                </React.Fragment>
              )
            }

            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              <p style={getStyle(screenSize, styles, 'subHeading')}>{t('modelDataLabel')}</p>
              <CBInputField
                name='year_model'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.year_model}
                type='number'
                placeholder={t('yearModelPlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 4)]}
                isValidCallback={this.setIsValid}
                labelText={t('yearModelLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />
              <CBInputField
                name='owner_description'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.owner_description}
                placeholder={t('vehicleOwnerDescriptionPlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 21)]}
                isValidCallback={this.setIsValid}
                labelText={t('vehicleOwnerDescriptionLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    dateInputLabel: {
      color: 'black',
      textAlign: 'center',
      paddingBottom: 6,
    },
    infoText: {
      color: 'black',
      fontSize: 14,
      marginBottom: 16,
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    subHeading: {
      color: 'black',
      textAlign: 'center',
      fontSize: 18,
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    selectedOrganizationId: state.organization.selectedOrganization,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    updateVehicle: _updateVehicle,
    updateOrganizationVehicle: _updateOrganizationVehicle,
  },
)(translate('VehicleDetailsView')(EditVehicleTopInfoModal));
