import React from 'react';
import { translate } from 'react-i18next';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { formatDate, getStyle } from '../../../utils/utils';
import { connect } from 'react-redux';
import VehicleDetailsLabelAndValue from './VehicleDetailsLabelAndValue';

class PredictedMaintenanceInfo extends React.Component {
  state = {
    modalIsOpen: false,
  };

  addReminder = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  getUnsetFields = () => {
    const { vehicle } = this.props;

    const unsetFields = [];

    if (!vehicle.organization_data) {
      unsetFields.push('maintenance_interval_months');
      unsetFields.push('maintenance_interval_km');
    }


    return unsetFields;
  };

  afterOpenModal = () => {};

  render() {
    const { vehicle, t, isSettings, service } = this.props;
    const { modalIsOpen } = this.state;
    const { next_predicted_maintenance } = vehicle;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={isSettings ? getStyle(screenSize, styles, 'containerFullWidth') : getStyle(screenSize, styles, 'container')}>
            <p style={getStyle(screenSize, styles, 'headingText')}>
              {t('nextPredictedMaintenance')}
            </p>
            {
              next_predicted_maintenance && (
                <React.Fragment>
                  <VehicleDetailsLabelAndValue
                    label={t('nextPredictedMaintenanceDate')}
                    value={formatDate(next_predicted_maintenance.due_at)}
                  />
                  <VehicleDetailsLabelAndValue
                    label={t('nextPredictedMaintenanceType')}
                    value={t('predictedType-' + next_predicted_maintenance.prediction_type)}
                  />
                  {
                    next_predicted_maintenance.prediction_type === 'km' && (
                      <VehicleDetailsLabelAndValue
                        label={t('nextPredictedMaintenanceAverageKm')}
                        value={next_predicted_maintenance.vehicle_average_km_per_day}
                      />
                    )
                  }
                  <hr style={{opacity: 0.5}} />
                  <VehicleDetailsLabelAndValue
                    label={t('lastPeriodicMaintenanceDate')}
                    value={formatDate(next_predicted_maintenance.last_periodic_maintenance_date)}
                  />
                  {
                    !!next_predicted_maintenance.last_periodic_maintenance_km && (
                      <VehicleDetailsLabelAndValue
                        label={t('lastPeriodicMaintenanceKm')}
                        value={next_predicted_maintenance.last_periodic_maintenance_km}
                      />
                    )
                  }
                </React.Fragment>
              )
            }
            {
              !next_predicted_maintenance && (
                <React.Fragment>
                  <p>
                    {t('predictedMaintenanceNotFound')}
                  </p>
                  <p>
                    {t('predictedMaintenanceFieldsNotSet')}:
                  </p>
                  <ul>
                      <li style={{color: '#FFF'}}>Huoltoväli kuukausina tai kilometreissä</li>
                      <li style={{color: '#FFF'}}>Edellisen huollon päivämäärä ja kilometrilukema</li>
                  </ul>
                </React.Fragment>
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    headingText: {
      fontSize: 22,
      fontWeight: 200,
      color: 'white'
    },
    container: {
      marginTop: 0,
      marginLeft: 'auto',
      borderLeft: '1px solid rgba(255, 255, 255, 0.4)',
      width: '30%',
      padding: 16,
    },
    containerFullWidth: {
      marginTop: 0,
      marginLeft: 'auto',
      width: '100%',
    },
    remindersContainer: {
      paddingTop: 16,
      height: '100%',
    },
    buttonContainer: {
      width: '100%',
    },
  },
  medium: {
    container: {
      marginTop: 50,
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      width: '100%',
    },
    containerFullWidth: {
      marginTop: 50,
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      width: '100%',
    },
    buttonContainer: {
      width: 200,
    },
  },
};

function mapStateToProps(state) {
  return {
    service: state.app.service
  };
}

export default connect(
  mapStateToProps,
  {},
)(translate('VehicleDetailsView')(PredictedMaintenanceInfo));
