import React from 'react';
import axios from 'axios';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { formatDateTime, getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import { withRouter } from 'react-router';
import NavbarButton from '../../shared/NavbarButton';
import CBDropzone from '../../shared/CBDropzone';
import CBButtonSettingsWhite from '../../shared/CBButtonSettingsWhite';
import CBSpinner from '../../shared/CBSpinner';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBSelectField from '../../shared/form_components/CBSelectField';
import ImportResultTable from './ImportResultTable';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class DataImportMileage extends React.Component {
  state = {
    importRunId: null,
    suggestedHeaders: null,
    exampleRows: null,
    headers: null,
    file: null,
    importing: false,
    result: null,
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.importRunId) {
      this.fetchImportRun(match.params.importRunId);
    }
  }

  fetchImportRun = async (importRunId) => {
    const response = await axios.get(`/uiapi/1/data-import/${importRunId}/`);

    this.setState({
      result: response.data,
    });
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId, match } = this.props;

    if (match.params.importRunId != prevProps.match.params.importRunId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
        if (!match.params.importRunId) {
          this.setState({
            importRunId: null,
            suggestedHeaders: null,
            exampleRows: null,
            headers: null,
            file: null,
            importing: false,
            result: null,
          });
        }
    }
  }

  uploadFile = async (data, file) => {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    const response = await axios.post('/uiapi/1/data-import/', {
      data: data,
      filename: file.name,
      organization_id: selectedOrganizationId,
    })

    this.setState({
      importRunId: response.data.import_run_id,
      selectedHeaders: response.data.selected_headers,
      exampleRows: response.data.example_rows,
      headers: response.data.headers,
      file: file
    })

    console.log(response.data);

  };

  performImport = async () => {
    const { importRunId, selectedHeaders } = this.state;
    const { history } = this.props;

    this.setState({
      importing: true,
    });

    const response = await axios.put(`/uiapi/1/data-import/${importRunId}/`, {
      selected_headers: selectedHeaders
    });


    this.setState({
      importing: false,
      result: response.data,
    });

    history.push(`/settings/data-import/mileage/${importRunId}/`);

  };

  cancelUpload = () => {
    this.setState({
      importRunId: null,
      suggestedHeaders: null,
      exampleRows: null,
      headers: null,
      file: null,
      importing: false,
      result: null
    });
  };

  onDropFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      console.log(file)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadFile(reader.result, file);
      };
    });
  };

  getSuggestedHeader = (key) => {
    const { selectedHeaders } = this.state;

     return selectedHeaders[key];
  };

  capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  getHeaders = () => {
    const { headers } = this.state;

    if (!headers) {
      return [];
    }

    return headers.map((header) => ({
      value: header,
      label: header == 'import_date' ? 'Latauspäivämäärä' : this.capitalizeFirstLetter(header),
    }));
  };

  updateSelectedHeader = async (changedObject) => {
    const { target, value } = changedObject;
    const { selectedHeaders, importRunId } = this.state;

    const newSelectedHeaders = {
      ...selectedHeaders,
      [target]: value,
    };

    const response = await axios.patch(`/uiapi/1/data-import/${importRunId}/`, {
      selected_headers: newSelectedHeaders
    })

    this.setState({
      selectedHeaders: response.data.selected_headers,
      exampleRows: response.data.example_rows,
      headers: response.data.headers,
    });
  };

  getLinkForResult = (row) => {
    if (row.vehicle_id) {
      if (row.mileage_id) {
        return (<Link style={{color: 'white', textDecoration: 'underline'}} to={`/vehicle/${row.vehicle_id}/mileages/${row.mileage_id}/`}>Siirry kilometrimerkintään</Link>);
      } else {
        return (<Link style={{color: 'white', textDecoration: 'underline'}} to={`/vehicle/${row.vehicle_id}/mileages/`}>Siirry kilometritietoihin</Link>);
      }
    }
  };


  render() {
    const {
      t,
      service,
      selectedWorkshopId,
      user,
      selectedOrganizationId,
    } = this.props;

    const {
      exampleRows,
      selectedHeaders,
      headers,
      file,
      importing,
      result
    } = this.state;

    const isStaff = user.is_staff;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <CBSubHeading>
                {t('dataImportMileageHeading')}
              </CBSubHeading>
            </div>

            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              {
                !file && !result && (
                  <div style={getStyle(screenSize, styles, 'dropZoneContainer')}>
                    <CBDropzone
                      className='opacity-on-hover'
                      onDropAccepted={this.onDropFile}
                      style={getStyle(screenSize, styles, 'dropZone')}
                    >
                        <p style={getStyle(screenSize, styles, 'dropZoneText')}>
                          Pudota CSV muotoinen ajokilometritiedosto tähän
                        </p>
                    </CBDropzone>
                  </div>
                )
              }

              {
                !result && exampleRows && (
                  <div>
                    <p style={getStyle(screenSize, styles, 'textSubHeading')}>Esimerkkirivit</p>
                    <table style={getStyle(screenSize, styles, 'table')}>
                      <thead>
                        <tr>
                          <th style={getStyle(screenSize, styles, 'tableHeading')}>Rekisterinumero</th>
                          <th style={getStyle(screenSize, styles, 'tableHeading')}>Päivämäärä</th>
                          <th style={getStyle(screenSize, styles, 'tableHeading')}>Kilometrit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={getStyle(screenSize, styles, 'row')}>
                          <td style={getStyle(screenSize, styles, 'tableSelectTd')}>
                            <CBSelectField
                              optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                              value={'Automaattisesti tunnistettu'}
                              options={[{'value': 'Automaattisesti tunnistettu', 'label': 'Automaattisesti tunnistettu'}]}
                              onChange={() => {}}
                              disabled={true}
                            />
                          </td>
                          <td style={getStyle(screenSize, styles, 'tableSelectTd')}>
                            <CBSelectField
                              optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                              value={this.getSuggestedHeader('date')}
                              options={this.getHeaders()}
                              onChange={this.updateSelectedHeader}
                              disabled={false}
                              name='date'
                            />
                          </td>
                          <td style={getStyle(screenSize, styles, 'tableSelectTd')}>
                            <CBSelectField
                              optionStyle={getStyle(screenSize, styles, 'optionStyle')}
                              value={this.getSuggestedHeader('km')}
                              options={this.getHeaders()}
                              onChange={this.updateSelectedHeader}
                              disabled={false}
                              name='km'
                              />
                          </td>
                        </tr>
                        {exampleRows && exampleRows.map((row, index) => (
                          <tr style={getStyle(screenSize, styles, 'row')} key={index}>
                            <td style={getStyle(screenSize, styles, 'tableTd')}>{row[selectedHeaders.reg_num]}</td>
                            <td style={getStyle(screenSize, styles, 'tableTd')}>{row[selectedHeaders.date]}</td>
                            <td style={getStyle(screenSize, styles, 'tableTd')}>{row[selectedHeaders.km]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {
                      !importing && !result && (
                        <div style={getStyle(screenSize, styles, 'buttonContainer')}>
                          <CBButtonWithIconSmall text='Peruuta' onClick={this.cancelUpload} />
                          <CBButtonWithIconSmall icon='/img/icon_save.svg' text='Tuo tiedot' onClick={this.performImport} />
                        </div>

                      )
                    }
                  </div>
                )
              }
              {
                importing && (
                  <div style={getStyle(screenSize, styles, 'spinnerContainer')}>
                    <p>Tuodaan tietoja...</p>
                    <CBSpinner />
                  </div>
                )
              }

              {
                !!result && (
                  <div style={getStyle(screenSize, styles, 'resultContainer')}>
                    <p style={getStyle(screenSize, styles, 'successText')}>
                      Tietojen tuonti valmis!
                    </p>
                    <p>Tuontiaika: {formatDateTime(result.succeeded_at)}</p>
                    <p>Käyttäjä: {result.user_name}</p>
                    <p>Yhteensä tuotiin {result.succeeded_rows.length} riviä.</p>
                    {
                      result.succeeded_rows.length > 0 && (
                        <div>
                          <p>Seuraavat tiedot tuotiin onnistuneesti:</p>
                          <ImportResultTable
                            headers={['Rekisterinumero', 'Päivämäärä', 'Kilometrit']}
                            resultRows={result.succeeded_rows.map((row) => ({
                              'Rekisterinumero': row.reg_num,
                              'Päivämäärä': row.read_at,
                              'Kilometrit': row.value,
                              'Linkki': this.getLinkForResult(row)
                            }))} />
                        </div>
                      )
                    }

                    {
                      result.failed_rows.length > 0 && (
                        <div>
                          <p>
                            Seuraavien tietojen tuonti epäonnistui:
                          </p>
                          <ImportResultTable
                            headers={['Rekisterinumero', 'Syy']}
                            resultRows={result.failed_rows.map((row) => ({
                              'Rekisterinumero': row.reg_num,
                              'Syy': row.reason,
                              'Linkki': this.getLinkForResult(row)
                            }))} />
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    successText: {
      fontSize: 20,
    },
    resultContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 16,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderRadius: 8,
      padding: 16,
      marginBottom: 32,
    },
    textSubHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 16,
      color: '#ffffff',
      marginBottom: 16,
    },
    tableTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 16,
      paddingRight: 16,
    },
    tableSelectTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 8,
    },
    selectField: {
      minWidth: 300,
      height: 40,
      border: '1px solid rgb(255 255 255 / 55%)',
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: '#FFF',
      padding: '0px 0px 0px 16px',
    },
    selectContainer: {
      marginTop: 16,
      marginRight: 16,
      minWidth: 300,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 16,
      paddingBottom: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
    row: {
      marginBottom: 8,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    sectionContainer: {
      marginBottom: 16,
    },
    dropZoneContainer: {
      marginTop: 16,
      marginBottom: 16,
    },
    dropZoneText: {
      color: 'white',
      fontSize: 24,
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 16,
        padding: 8,
        backgroundColor: 'transparent',
    },
    dropZone: {
      position: 'relative',
      width: '100%',
      height: '150px',
      borderWidth: '2px',
      borderColor: 'white',
      borderStyle: 'dashed',
      borderRadius: '16px',
      opacity: '0.8',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#FFFFFF',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {

  },
)(withRouter(translate('SettingsView')(DataImportMileage)));






