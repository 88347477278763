import React from 'react';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import NavbarButton from '../shared/NavbarButton';

class FleetNavbar extends React.Component {
  getActiveTab = () => {
    const url = window.location.pathname;
    if (url.includes('/messages')) {
      return 'Messages';
    } else if (url.includes('/tasks')) {
      return 'Tasks';
    } else if (url.includes('/vehicle-report')) {
      return 'Vehicle report';
    } else if (url.includes('/predicted-maintenances')) {
      return 'Predicted maintenances';
    } else {
      return 'Garage vehicles';
    }
  };

  render() {
    const { t, organizationId, hasFeatureTasksAndMessages, hasFeaturePurchaseReports } = this.props;
    const activeTab = this.getActiveTab();
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'navbarContainer')}>
            <div style={getStyle(screenSize, styles, 'buttonGroup')}>
              <NavbarButton
                to={`/organization_garage/${organizationId}/vehicles/`}
                iconSrc='/img/icon_care_service.svg'
                alt='Garage vehicles'
                buttonText={t('navGarageVehicles')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />
              <NavbarButton
                to={`/organization_garage/${organizationId}/predicted-maintenances/`}
                iconSrc='/img/icon_car_service.svg'
                alt='Predicted maintenances'
                buttonText={t('navMaintenancePrediction')}
                setActiveTab={tabName => this.setState({ activeTab: tabName })}
                activeTab={activeTab}
              />
              {
                hasFeatureTasksAndMessages && (
                  <React.Fragment>
                    <NavbarButton
                      to={`/organization_garage/${organizationId}/messages/`}
                      iconSrc='/img/icon_messages.svg'
                      alt='Messages'
                      buttonText={t('navMessages')}
                      setActiveTab={tabName => this.setState({ activeTab: tabName })}
                      activeTab={activeTab}
                    />
                    <NavbarButton
                      to={`/organization_garage/${organizationId}/tasks/`}
                      iconSrc='/img/icon_task_list.svg'
                      alt='Tasks'
                      buttonText={t('navTasks')}
                      setActiveTab={tabName => this.setState({ activeTab: tabName })}
                      activeTab={activeTab}
                    />
                  </React.Fragment>
                )
              }
              {
                hasFeaturePurchaseReports && (
                  <NavbarButton
                    to={`/organization_garage/${organizationId}/vehicle-report/`}
                    iconSrc='/img/icon_vehicle_report.svg'
                    alt='Vehicle report'
                    buttonText={t('navVehicleReport')}
                    setActiveTab={tabName => this.setState({ activeTab: tabName })}
                    activeTab={activeTab}
                  />
                )
              }
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    navbarContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    buttonGroup: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    navbarButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #FFF',
      padding: 16,
      cursor: 'pointer',
    },
    buttonIcon: {
      display: 'inline-block',
      width: 64,
      height: 64,
    },
    buttonText: {
      display: 'inline',
      color: '#FFF',
      fontSize: 18,
    },
  },
};

export default translate('FleetHomeView')(FleetNavbar);
