import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DetailsDocumentListItem from './ListItems/DetailsDocumentListItem';
import CBSelect from '../../shared/CBSelect';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, getUrlId } from '../../../utils/utils';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import { set } from 'lodash';
import AddDocumentButton from './ListItems/AddDocumentButton';

class VehicleDetailsDocumentList extends React.Component {
  state = {
  };

  isSelected = (document, idx) => {
    const urlParts = window.location.pathname.split('/');
    const index = urlParts.indexOf('documents') + 1;

    if (index && urlParts[index]) {
      return document.id == urlParts[index];
    }

    return idx == 0;
  };

  documentSelected = e => {
    const { history, vehicle } = this.props;
    history.push(`/vehicle/${vehicle.id}/documents/${e.target.value}`);
  };

  //Do not remove. For unknown reasons the view won't re-render without this.
  static getDerivedStateFromProps(nextProps, prevState) {}

  render() {
    const { vehicle, location, history, selectedDocument, selectAllDocument, selectDocument, documents } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            style={getStyle(
              screenSize,
              styles,
              screenSize !== 'small' ? 'listContainer' : 'container',
            )}
          >
            {screenSize !== 'small' && (
              <div>
                <div style={getStyle(screenSize, styles, 'addButtonContainer')}>
                  {
                    false && (// Multiselect disabled for now
                      <CBLabelAndCheckbox
                        additionalStyles={{ padding: 8 }}
                        checked={documents.length === selectedDocument.length}
                        onChange={() => selectAllDocument()}/>
                    )
                  }
                  <AddDocumentButton vehicle={vehicle} />
                </div>

                {documents.map((document, idx) => (
                  <DetailsDocumentListItem
                    selectDocument={selectDocument}
                    isMultiSelected={selectedDocument.includes(document.id)}
                    key={document.id}
                    vehicle={vehicle}
                    document={document}
                    history={history}
                    isSelected={this.isSelected(document, idx)}
                  />
                ))}
              </div>
            )}
            {screenSize === 'small' && (
              <Fragment>
                <div>
                  <AddDocumentButton vehicle={vehicle} />
                </div>
                <div style={getStyle(screenSize, styles, 'selectContainer')}>
                  <CBSelect
                    value={getUrlId(location.pathname)}
                    onChange={this.documentSelected}
                    >
                    {documents.map(document => (
                      <option key={document.id} value={document.id}>
                        {`${moment(document.created_at).format('D.M.Y')} | ${
                          document.title
                        }`}
                      </option>
                    ))}
                  </CBSelect>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '25%',
    },
    addButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      marginRight: 15,
      marginLeft: 15,
      marginTop: 40,
    },
  },
  small: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '100%',
      overflow: 'auto',
    },
  },
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(withRouter(VehicleDetailsDocumentList));
