import {
  API, GET, POST, PUT, DELETE,
} from '../middleware/api';
import { API_URL } from '../config';

export function fetchVehiclesByGarage(garageId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/garage_vehicle/`,
      types: ['FETCH_GARAGE_VEHICLES_REQUEST', 'FETCH_GARAGE_VEHICLES_SUCCESS', 'FETCH_GARAGE_VEHICLES_FAIL'],
      params: {include_permissions: true, garage_id: garageId},
    },
  };
}

export function fetchVehiclesByOrganization(organizationId, params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/garage_vehicle/?organization_id=${organizationId}`,
      types: ['FETCH_ORGANIZATION_VEHICLES_REQUEST', 'FETCH_ORGANIZATION_VEHICLES_SUCCESS', 'FETCH_ORGANIZATION_VEHICLES_FAIL'],
      params,
    },
  };
}

export function fetchVehiclesForCareService(workshopId, params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/care_service_vehicle/?workshop_id=${workshopId}`,
      types: ['FETCH_CARE_SERVICE_VEHICLES_REQUEST', 'FETCH_CARE_SERVICE_VEHICLES_SUCCESS', 'FETCH_CARE_SERVICE_VEHICLES_FAIL'],
      params,
    },
  };
}

export function addCareServiceVehicle(registrationNumber, workshopId) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/care_service_vehicle/`,
      types: ['ADD_CARE_SERVICE_VEHICLE_REQUEST', 'ADD_CARE_SERVICE_VEHICLE_SUCCESS', 'ADD_CARE_SERVICE_VEHICLE_FAIL'],
      data: { registration_number: registrationNumber, workshop_id: workshopId },
    },
  };
}

export function removeVehicleFromCareServiceWorkshop(vehicleId, workshopId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/care_service_vehicle/${vehicleId}/remove_from_care_service/?workshop_id=${workshopId}`,
      types: ['REMOVE_CARE_SERVICE_VEHICLE_WORKSHOP_REQUEST', 'REMOVE_CARE_SERVICE_VEHICLE_WORKSHOP_SUCCESS', 'REMOVE_CARE_SERVICE_VEHICLE_WORKSHOP_FAIL'],
    },
  };
}

export function removeVehicleFromCareServiceFleet(vehicleId, organizationId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicleId}/remove_from_care_service/?organization_id=${organizationId}`,
      types: ['REMOVE_CARE_SERVICE_VEHICLE_FLEET_REQUEST', 'REMOVE_CARE_SERVICE_VEHICLE_FLEET_SUCCESS', 'REMOVE_CARE_SERVICE_VEHICLE_FLEET_FAIL'],
    },
  };
}

export function createOrganizationVehicleStatus(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/organization_vehicle_status/`,
      types: [
        'CREATE_ORGANIZATION_VEHICLE_STATUS_REQUEST',
        'CREATE_ORGANIZATION_VEHICLE_STATUS_SUCCESS',
        'CREATE_ORGANIZATION_VEHICLE_STATUS_FAIL',
      ],
      data,
    },
  };
}

export function removeVehicleFromGarage(vehicleId, garageId, vehicleIds) {
  const isBatchRequest = !vehicleId && !!vehicleIds;

  const urlExtenstion = isBatchRequest ? 'remove_vehicle_batch' : 'remove_vehicle';

  const data = isBatchRequest ? { vehicle_ids: vehicleIds } : { vehicle_id: vehicleId };

  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/garage/${garageId}/${urlExtenstion}/`,
      types: [
        'REMOVE_VEHICLE_FROM_GARAGE_REQUEST',
        'REMOVE_VEHICLE_FROM_GARAGE_SUCCESS',
        'REMOVE_VEHICLE_FROM_GARAGE_FAIL',
      ],
      data,
    },
  };
}

export function fetchVehicles(vehicleId) {
  return {
    [API]: {
      method: GET,
      url: !vehicleId ? `${API_URL}/uiapi/1/vehicle/` : `${API_URL}/uiapi/1/vehicle/${vehicleId}/`,
      types: ['FETCH_VEHICLES_REQUEST', 'FETCH_VEHICLES_SUCCESS', 'FETCH_VEHICLES_FAIL'],
    },
  };
}

export function fetchWSVehicles(workshopId, params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/ws_vehicle/?workshop_id=${workshopId}`,
      types: [
        'FETCH_WS_VEHICLES_REQUEST',
        'FETCH_WS_VEHICLES_SUCCESS',
        'FETCH_WS_VEHICLES_FAIL',
      ],
      params,
    },
  };
}

export function fetchWSVehiclesNextPage(workshopId, params) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/ws_vehicle/?workshop_id=${workshopId}`,
      types: [
        'FETCH_WS_VEHICLES_REQUEST',
        'FETCH_WS_VEHICLES_NEXT_PAGE_SUCCESS',
        'FETCH_WS_VEHICLES_FAIL',
      ],
      params,
    },
  };
}

export function updateVehicle(id, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle/${id}/`,
      types: [
        'UPDATE_VEHICLE_REQUEST',
        'UPDATE_VEHICLE_SUCCESS',
        'UPDATE_VEHICLE_FAIL',
      ],
      data: data,
    },
  };
}

export function updateOrganizationVehicle(id, data, organizationId, workshopId = null) {
  const prefix = workshopId ? 'care_service_vehicle' : 'garage_vehicle';
  const suffix = workshopId ? `&workshop_id=${workshopId}` : '';

  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}/${id}/update_organization_data/?organization_id=${organizationId}${suffix}`,
      types: [
        'UPDATE_VEHICLE_REQUEST',
        'UPDATE_VEHICLE_SUCCESS',
        'UPDATE_VEHICLE_FAIL',
      ],
      data: data,
    },
  };
}

export function createWSVehicle(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/ws_vehicle/`,
      types: [
        'CREATE_VEHICLE_REQUEST',
        'CREATE_VEHICLE_SUCCESS',
        'CREATE_VEHICLE_FAIL',
      ],
      data: data,
    },
  };
}

export function updateWSVehicle(data, vehicleId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/ws_vehicle/${vehicleId}/`,
      types: [
        'UPDATE_WS_VEHICLE_REQUEST',
        'UPDATE_WS_VEHICLE_SUCCESS',
        'UPDATE_WS_VEHICLE_FAIL',
      ],
      data,
    },
  };
}

export function fetchVehicle(vehicleId, params={}) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/vehicle/${vehicleId}/`,
      types: [
        'FETCH_VEHICLE_REQUEST',
        'FETCH_VEHICLE_SUCCESS',
        'FETCH_VEHICLE_FAIL',
      ],
      params,
    },
  };
}

export function fetchWSVehicle(vehicleId, workshopId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/ws_vehicle/${vehicleId}/?workshop_id=${workshopId}`,
      types: ['FETCH_WS_VEHICLE_REQUEST', 'FETCH_WS_VEHICLE_SUCCESS', 'FETCH_WS_VEHICLE_FAIL'],
    },
  };
}

export function clearCurrentVehicle() {
  return {
    type: 'CLEAR_CURRENT_VEHICLE',
  };
}

export function fetchVehicleMaintenance(maintenanceId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/vehicle_maintenance/${maintenanceId}/`,
      types: ['FETCH_MAINTENANCE_REQUEST', 'FETCH_MAINTENANCE_SUCCESS', 'FETCH_MAINTENANCE_FAIL'],
    },
  };
}

export function createVehicleMaintenance(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_maintenance/`,
      types: [
        'CREATE_VEHICLE_MAINTENANCE_REQUEST',
        'CREATE_VEHICLE_MAINTENANCE_SUCCESS',
        'CREATE_VEHICLE_MAINTENANCE_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleMaintenance(maintenanceId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle_maintenance/${maintenanceId}/`,
      types: [
        'UPDATE_VEHICLE_MAINTENANCE_REQUEST',
        'UPDATE_VEHICLE_MAINTENANCE_SUCCESS',
        'UPDATE_VEHICLE_MAINTENANCE_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleMaintenance(maintenanceId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/vehicle_maintenance/${maintenanceId}/`,
      types: [
        'DELETE_VEHICLE_MAINTENANCE_REQUEST',
        'DELETE_VEHICLE_MAINTENANCE_SUCCESS',
        'DELETE_VEHICLE_MAINTENANCE_FAIL',
      ],
    },
  };
}

export function createMaintenanceImage(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/maintenance_image/`,
      types: [
        'CREATE_MAINTENANCE_IMAGE_REQUEST',
        'CREATE_MAINTENANCE_IMAGE_SUCCESS',
        'CREATE_MAINTENANCE_IMAGE_FAIL',
      ],
      data,
    },
  };
}

export function deleteMaintenanceImage(imageId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/maintenance_image/${imageId}/`,
      types: [
        'DELETE_MAINTENANCE_IMAGE_REQUEST',
        'DELETE_MAINTENANCE_IMAGE_SUCCESS',
        'DELETE_MAINTENANCE_IMAGE_FAIL',
      ],
    },
  };
}

export function fetchVehicleTreatment(treatmentId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/vehicle_treatment/${treatmentId}/`,
      types: [
        'FETCH_TREATMENT_REQUEST',
        'FETCH_TREATMENT_SUCCESS',
        'FETCH_TREATMENT_FAIL',
      ],
    },
  };
}

export function fetchVehicleInspection(inspectionId) {
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/vehicle_inspection/${inspectionId}/`,
      types: ['FETCH_INSPECTION_REQUEST', 'FETCH_INSPECTION_SUCCESS', 'FETCH_INSPECTION_FAIL'],
    },
  };
}

export function createVehicleDescription(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_description/`,
      types: [
        'CREATE_VEHICLE_DESCRIPTION_REQUEST',
        'CREATE_VEHICLE_DESCRIPTION_SUCCESS',
        'CREATE_VEHICLE_DESCRIPTION_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleDescription(descriptionId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle_description/${descriptionId}/`,
      types: [
        'UPDATE_VEHICLE_DESCRIPTION_REQUEST',
        'UPDATE_VEHICLE_DESCRIPTION_SUCCESS',
        'UPDATE_VEHICLE_DESCRIPTION_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleTopInfo(vehicleId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle_info/${vehicleId}/`,
      types: [
        'UPDATE_VEHICLE_INFO_REQUEST',
        'UPDATE_VEHICLE_INFO_SUCCESS',
        'UPDATE_VEHICLE_INFO_FAIL',
      ],
      data,
    },
  };
}

export function createVehicleImage(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_image/`,
      types: [
        'CREATE_VEHICLE_IMAGE_REQUEST',
        'CREATE_VEHICLE_IMAGE_SUCCESS',
        'CREATE_VEHICLE_IMAGE_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleImage(imageId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle_image/${imageId}/`,
      types: [
        'UPDATE_VEHICLE_IMAGE_REQUEST',
        'UPDATE_VEHICLE_IMAGE_SUCCESS',
        'UPDATE_VEHICLE_IMAGE_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleImage(imageId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/vehicle_image/${imageId}/`,
      types: [
        'DELETE_VEHICLE_IMAGE_REQUEST',
        'DELETE_VEHICLE_IMAGE_SUCCESS',
        'DELETE_VEHICLE_IMAGE_FAIL',
      ],
    },
  };
}

export function createVehicleTreatment(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_treatment/`,
      types: [
        'CREATE_VEHICLE_TREATMENT_REQUEST',
        'CREATE_VEHICLE_TREATMENT_SUCCESS',
        'CREATE_VEHICLE_TREATMENT_FAIL',
      ],
      data,
    },
  };
}

export function createVehicleMaintenanceMessage(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_maintenance_message/`,
      types: [
        'CREATE_VEHICLE_MAINTENANCE_MESSAGE_REQUEST',
        'CREATE_VEHICLE_MAINTENANCE_MESSAGE_SUCCESS',
        'CREATE_VEHICLE_MAINTENANCE_MESSAGE_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleTreatment(treatmentId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle_treatment/${treatmentId}/`,
      types: [
        'UPDATE_VEHICLE_TREATMENT_REQUEST',
        'UPDATE_VEHICLE_TREATMENT_SUCCESS',
        'UPDATE_VEHICLE_TREATMENT_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleTreatment(treatmentId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/vehicle_treatment/${treatmentId}/`,
      types: [
        'DELETE_VEHICLE_TREATMENT_REQUEST',
        'DELETE_VEHICLE_TREATMENT_SUCCESS',
        'DELETE_VEHICLE_TREATMENT_FAIL',
      ],
    },
  };
}


export function createTreatmentImage(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/treatment_image/`,
      types: [
        'CREATE_TREATMENT_IMAGE_REQUEST',
        'CREATE_TREATMENT_IMAGE_SUCCESS',
        'CREATE_TREATMENT_IMAGE_FAIL',
      ],
      data,
    },
  };
}

export function deleteTreatmentImage(imageId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/treatment_image/${imageId}/`,
      types: [
        'DELETE_TREATMENT_IMAGE_REQUEST',
        'DELETE_TREATMENT_IMAGE_SUCCESS',
        'DELETE_TREATMENT_IMAGE_FAIL',
      ],
    },
  };
}

export function createVehicleReminder(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle_reminder/`,
      types: [
        'CREATE_VEHICLE_REMINDER_REQUEST',
        'CREATE_VEHICLE_REMINDER_SUCCESS',
        'CREATE_VEHICLE_REMINDER_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleReminder(reminderId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/vehicle_reminder/${reminderId}/`,
      types: [
        'UPDATE_VEHICLE_REMINDER_REQUEST',
        'UPDATE_VEHICLE_REMINDER_SUCCESS',
        'UPDATE_VEHICLE_REMINDER_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleReminder(reminderId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/vehicle_reminder/${reminderId}/`,
      types: [
        'DELETE_VEHICLE_REMINDER_REQUEST',
        'DELETE_VEHICLE_REMINDER_SUCCESS',
        'DELETE_VEHICLE_REMINDER_FAIL',
      ],
    },
  };
}

export function createOrganizationVehiclePermission(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/organization_vehicle_permission/`,
      types: [
        'CREATE_ORGANIZATION_VEHICLE_PERMISSION_REQUEST',
        'CREATE_ORGANIZATION_VEHICLE_PERMISSION_SUCCESS',
        'CREATE_ORGANIZATION_VEHICLE_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function updateOrganizationVehiclePermission(permissionId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/organization_vehicle_permission/${permissionId}/`,
      types: [
        'UPDATE_ORGANIZATION_VEHICLE_PERMISSION_REQUEST',
        'UPDATE_ORGANIZATION_VEHICLE_PERMISSION_SUCCESS',
        'UPDATE_ORGANIZATION_VEHICLE_PERMISSION_FAIL',
      ],
      data,
    },
  };
}

export function deleteOrganizationVehiclePermission(permissionId, baseObjId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/organization_vehicle_permission/${permissionId}/`,
      types: [
        'DELETE_ORGANIZATION_VEHICLE_PERMISSION_REQUEST',
        'DELETE_ORGANIZATION_VEHICLE_PERMISSION_SUCCESS',
        'DELETE_ORGANIZATION_VEHICLE_PERMISSION_FAIL',
      ],
      additionalData: {
        vehicleId: baseObjId,
        permissionId,
      },
    },
  };
}

export function toggleVehicleOrganizationSharing(vehicleId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicleId}/toggle_organization_sharing/`,
      types: [
        'TOGGLE_VEHICLE_ORGANIZATION_SHARING_REQUEST',
        'TOGGLE_VEHICLE_ORGANIZATION_SHARING_SUCCESS',
        'TOGGLE_VEHICLE_ORGANIZATION_SHARING_FAIL',
      ],
      data,
    },
  };
}

export function requestGarageVehiclePermission(registrationNumber, hetu, garageId) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/request_vehicle_permission/`,
      types: [
        'REQUEST_GARAGE_VEHICLE_PERMISSION_REQUEST',
        'REQUEST_GARAGE_VEHICLE_PERMISSION_SUCCESS',
        'REQUEST_GARAGE_VEHICLE_PERMISSION_FAIL',
      ],
      data: {
        registrationNumber,
        hetu,
        garageId,
      },
    },
  };
}

export function fetchVehicleMessage(messageId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/${messageId}/${suffix}`,
      types: ['FETCH_MESSAGE_REQUEST', 'FETCH_MESSAGE_SUCCESS', 'FETCH_MESSAGE_FAIL'],
    },
  };
}

export function createVehicleMessage(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  const urlExtenstion = data.vehicle_ids && data.vehicle_ids.length ? 'create_batch/' : '';

  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/${urlExtenstion}${suffix}`,
      types: [
        'CREATE_VEHICLE_MESSAGE_REQUEST',
        'CREATE_VEHICLE_MESSAGE_SUCCESS',
        'CREATE_VEHICLE_MESSAGE_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleMessage(messageId, data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/${messageId}/${suffix}`,
      types: [
        'UPDATE_VEHICLE_MESSAGE_REQUEST',
        'UPDATE_VEHICLE_MESSAGE_SUCCESS',
        'UPDATE_VEHICLE_MESSAGE_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleMessage(messageId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message/${messageId}/${suffix}`,
      types: [
        'DELETE_VEHICLE_MESSAGE_REQUEST',
        'DELETE_VEHICLE_MESSAGE_SUCCESS',
        'DELETE_VEHICLE_MESSAGE_FAIL',
      ],
    },
  };
}

export function createVehicleMessageResponse(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message_response/${suffix}`,
      types: [
        'CREATE_VEHICLE_MESSAGE_RESPONSE_REQUEST',
        'CREATE_VEHICLE_MESSAGE_RESPONSE_SUCCESS',
        'CREATE_VEHICLE_MESSAGE_RESPONSE_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleMessageResponse(responseId, data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message_response/${responseId}/${suffix}`,
      types: [
        'UPDATE_VEHICLE_MESSAGE_RESPONSE_REQUEST',
        'UPDATE_VEHICLE_MESSAGE_RESPONSE_SUCCESS',
        'UPDATE_VEHICLE_MESSAGE_RESPONSE_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleMessageResponse(responseId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}vehicle_message_response/${responseId}/${suffix}`,
      types: [
        'DELETE_VEHICLE_MESSAGE_RESPONSE_REQUEST',
        'DELETE_VEHICLE_MESSAGE_RESPONSE_SUCCESS',
        'DELETE_VEHICLE_MESSAGE_RESPONSE_FAIL',
      ],
    },
  };
}

export function createMessageImage(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}message_image/${suffix}`,
      types: [
        'CREATE_MESSAGE_IMAGE_REQUEST',
        'CREATE_MESSAGE_IMAGE_SUCCESS',
        'CREATE_MESSAGE_IMAGE_FAIL',
      ],
      data,
    },
  };
}

export function deleteMessageImage(imageId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}message_image/${imageId}/${suffix}`,
      types: [
        'DELETE_MESSAGE_IMAGE_REQUEST',
        'DELETE_MESSAGE_IMAGE_SUCCESS',
        'DELETE_MESSAGE_IMAGE_FAIL',
      ],
    },
  };
}

export function createMessageResponseImage(messageId, data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}message_image/${suffix}`,
      types: [
        'CREATE_MESSAGE_RESPONSE_IMAGE_REQUEST',
        'CREATE_MESSAGE_RESPONSE_IMAGE_SUCCESS',
        'CREATE_MESSAGE_RESPONSE_IMAGE_FAIL',
      ],
      data,
      additionalData: {
        messageId,
      },
    },
  };
}

export function deleteMessageResponseImage(messageId, imageId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}message_image/${imageId}/${suffix}`,
      types: [
        'DELETE_MESSAGE_RESPONSE_IMAGE_REQUEST',
        'DELETE_MESSAGE_RESPONSE_IMAGE_SUCCESS',
        'DELETE_MESSAGE_RESPONSE_IMAGE_FAIL',
      ],
      additionalData: {
        messageId,
      },
    },
  };
}

export function toggleVehicleMessageSubscription(vehicleId, garageId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicleId}/toggle_message_subscription/?garage_id=${garageId}`,
      types: [
        'TOGGLE_VEHICLE_MESSAGE_SUBSCRIPTION_REQUEST',
        'TOGGLE_VEHICLE_MESSAGE_SUBSCRIPTION_SUCCESS',
        'TOGGLE_VEHICLE_MESSAGE_SUBSCRIPTION_FAIL',
      ],
      data,
    },
  };
}

export function attachVehicle(vehicleId, garageId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicleId}/attach_to_vehicle/?garage_id=${garageId}`,
      types: [
        'ATTACH_VEHICLE_REQUEST',
        'ATTACH_VEHICLE_SUCCESS',
        'ATTACH_VEHICLE_FAIL',
      ],
      data,
    },
  };
}

export function changeVehicleGarage(vehicleId, data, selectedOrganizationId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicleId}/change_garage/?organization_id=${selectedOrganizationId}`,
      types: [
        'UPDATE_GARAGE_VEHICLE_REQUEST',
        'UPDATE_GARAGE_VEHICLE_SUCCESS',
        'UPDATE_GARAGE_VEHICLE_FAIL',
      ],
      data,
    },
  };
}

export function detachVehicle(vehicle, garageId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicle.id}/detach_vehicle/?garage_id=${garageId}`,
      types: [
        'ATTACH_VEHICLE_REQUEST',
        'ATTACH_VEHICLE_SUCCESS',
        'ATTACH_VEHICLE_FAIL',
      ],
      additionalData: {
        parent: vehicle.parent,
      },
    },
  };
}

export function fetchVehicleTask(taskId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/${prefix}task/${taskId}/${suffix}`,
      types: ['FETCH_VEHICLE_TASK_REQUEST', 'FETCH_VEHICLE_TASK_SUCCESS', 'FETCH_VEHICLE_TASK_FAIL'],
    },
  };
}

export function createVehicleTask(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}task/${suffix}`,
      types: [
        'CREATE_VEHICLE_TASK_REQUEST',
        'CREATE_VEHICLE_TASK_SUCCESS',
        'CREATE_VEHICLE_TASK_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleTask(taskId, data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}task/${taskId}/${suffix}`,
      types: [
        'UPDATE_VEHICLE_TASK_REQUEST',
        'UPDATE_VEHICLE_TASK_SUCCESS',
        'UPDATE_VEHICLE_TASK_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleTask(taskId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}task/${taskId}/${suffix}`,
      types: [
        'DELETE_VEHICLE_TASK_REQUEST',
        'DELETE_VEHICLE_TASK_SUCCESS',
        'DELETE_VEHICLE_TASK_FAIL',
      ],
    },
  };
}

export function fetchVehicleMileage(mileageId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/${prefix}mileage/${mileageId}/${suffix}`,
      types: ['FETCH_VEHICLE_MILEAGE_REQUEST', 'FETCH_VEHICLE_MILEAGE_SUCCESS', 'FETCH_VEHICLE_MILEAGE_FAIL'],
    },
  };
}

export function createVehicleMileage(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}mileage/${suffix}`,
      types: [
        'CREATE_VEHICLE_MILEAGE_REQUEST',
        'CREATE_VEHICLE_MILEAGE_SUCCESS',
        'CREATE_VEHICLE_MILEAGE_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleMileage(mileageId, data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}mileage/${mileageId}/${suffix}`,
      types: [
        'UPDATE_VEHICLE_MILEAGE_REQUEST',
        'UPDATE_VEHICLE_MILEAGE_SUCCESS',
        'UPDATE_VEHICLE_MILEAGE_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleMileage(mileageId, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}mileage/${mileageId}/${suffix}`,
      types: [
        'DELETE_VEHICLE_MILEAGE_REQUEST',
        'DELETE_VEHICLE_MILEAGE_SUCCESS',
        'DELETE_VEHICLE_MILEAGE_FAIL',
      ],
    },
  };
}

export function fetchVehicleDocument(documentId, workshopId = undefined) {
  const prefix = workshopId ? '' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: GET,
      url: `${API_URL}/uiapi/1/${prefix}document/${documentId}/${suffix}`,
      types: ['FETCH_VEHICLE_DOCUMENT_REQUEST', 'FETCH_VEHICLE_DOCUMENT_SUCCESS', 'FETCH_VEHICLE_DOCUMENT_FAIL'],
    },
  };
}

export function createVehicleDocument(data, workshopId = undefined) {
  const prefix = workshopId ? '' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}document/${suffix}`,
      types: [
        'CREATE_VEHICLE_DOCUMENT_REQUEST',
        'CREATE_VEHICLE_DOCUMENT_SUCCESS',
        'CREATE_VEHICLE_DOCUMENT_FAIL',
      ],
      data,
    },
  };
}

export function updateVehicleDocument(documentId, data, workshopId = undefined) {
  const prefix = workshopId ? '' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}document/${documentId}/${suffix}`,
      types: [
        'UPDATE_VEHICLE_DOCUMENT_REQUEST',
        'UPDATE_VEHICLE_DOCUMENT_SUCCESS',
        'UPDATE_VEHICLE_DOCUMENT_FAIL',
      ],
      data,
    },
  };
}

export function deleteVehicleDocument(documentId, workshopId = undefined) {
  const prefix = workshopId ? '' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/${prefix}document/${documentId}/${suffix}`,
      types: [
        'DELETE_VEHICLE_DOCUMENT_REQUEST',
        'DELETE_VEHICLE_DOCUMENT_SUCCESS',
        'DELETE_VEHICLE_DOCUMENT_FAIL',
      ],
    },
  };
}

export function setVehicleTaskStatus(taskId, status, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/${prefix}task/${taskId}/set_status/${suffix}`,
      types: [
        'UPDATE_VEHICLE_TASK_REQUEST',
        'UPDATE_VEHICLE_TASK_SUCCESS',
        'UPDATE_VEHICLE_TASK_FAIL',
      ],
      data: {
        status,
      },
    },
  };
}

export function setMassVehicleTaskStatus(data, workshopId = undefined) {
  const prefix = workshopId ? 'cs_' : '';
  const suffix = workshopId ? `?workshop_id=${workshopId}` : '';
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/${prefix}task/mass_set_status/${suffix}`,
      types: [
        'UPDATE_VEHICLE_TASK_REQUEST',
        'UPDATE_VEHICLE_TASKS_SUCCESS',
        'UPDATE_VEHICLE_TASK_FAIL',
      ],
      data
    },
  };
}

export function createAutofuturWorkOrder(data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/create_autofutur_work_order/`,
      types: [
        'UPDATE_VEHICLE_TASK_REQUEST',
        'UPDATE_VEHICLE_TASKS_SUCCESS',
        'UPDATE_VEHICLE_TASK_FAIL',
      ],
      data
    },
  };
}

export function createSaleReportSubscription(vehicleId, organizationId) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicleId}/create_sale_report_subscription/`,
      types: [
        'UPDATE_GARAGE_VEHICLE_REQUEST',
        'UPDATE_GARAGE_VEHICLE_SUCCESS',
        'UPDATE_GARAGE_VEHICLE_FAIL',
      ],
      data: {
        organization_id: organizationId,
      }
    },
  };
}

export function createPersonalSaleReport(vehicleId, data) {
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/uiapi/1/vehicle/${vehicleId}/personal_sale_report/`,
      types: [
        'UPDATE_PERSONAL_SALE_REPORT_REQUEST',
        'UPDATE_PERSONAL_SALE_REPORT_SUCCESS',
        'UPDATE_PERSONAL_SALE_REPORT_FAIL',
      ],
      data
    },
  };
}

export function deletePersonalSaleReport(vehicleId) {
  return {
    [API]: {
      method: DELETE,
      url: `${API_URL}/uiapi/1/vehicle/${vehicleId}/personal_sale_report/`,
      types: [
        'UPDATE_PERSONAL_SALE_REPORT_REQUEST',
        'UPDATE_PERSONAL_SALE_REPORT_SUCCESS',
        'UPDATE_PERSONAL_SALE_REPORT_FAIL',
      ]
    },
  };
}

export function setManualKm(vehicleId, data) {
  return {
    [API]: {
      method: PUT,
      url: `${API_URL}/uiapi/1/garage_vehicle/${vehicleId}/set_manual_km/`,
      types: [
        'SET_MANUAL_KM_REQUEST',
        'SET_MANUAL_KM_SUCCESS',
        'SET_MANUAL_KM_FAIL',
      ],
      data
    },
  };
}

export function clearWSVehicles() {
  return {
    type: 'CLEAR_WS_VEHICLES',
  };
}
