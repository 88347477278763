import { predictedMaintenanceState } from '../initialState';
import { storeInsertItem, storeRemoveItem, storeUpdateObjectInArray } from '../utils/utils';

export default function (state = predictedMaintenanceState, action) {
  switch (action.type) {
    case 'FETCH_PREDICTED_MAINTENANCES_REQUEST': {
      return {
        ...state,
        predictedMaintenanceStatus: Object.assign({}, state.predictedMaintenanceStatus, {
          loading: true,
          loadingFetch: true,
          error: null,
        }),
      };
    }
    case 'FETCH_PREDICTED_MAINTENANCES_SUCCESS': {
      return {
        ...state,
        predictedMaintenances: action.payload,
        predictedMaintenanceStatus: Object.assign({}, state.predictedMaintenanceStatus, {
          loading: false,
          error: null,
        }),
      };
    }
    case 'FETCH_PREDICTED_MAINTENANCES_FAIL': {
      return {
        ...state,
        predictedMaintenances: [],
        predictedMaintenanceStatus: Object.assign({}, state.predictedMaintenanceStatus, {
          loading: false,
          error: action.payload,
        }),
      };
    }
    default:
      break;
  }
  return state;
}
