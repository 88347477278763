import React, { useRef } from 'react';
import { translate } from 'react-i18next';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { formatDate, formatDateTime, getStyle, SERVICE_WORKSHOP, styleMerge } from '../../../utils/utils';
import OptionsButton from '../../GarageView/GarageViewComponents/OptionsComponents/OptionsButton';
import CBPromptModal from '../../shared/CBPromptModal';
import { withRouter } from 'react-router';
import CBCheckbox from '../../shared/form_components/CBCheckbox';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import VehicleListTd from '../../OrganizationGarageView/OrganizationGarageComponents/VehicleListTd';
import AddTaskModal from './AddTaskModal';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';


const STATUS_CREATED = 'created';
const STATUS_DONE = 'done';

class TaskListItem extends React.Component {
  state = {
    deleteModalIsOpen: false,
    statusModalIsOpen: false,
  }

  constructor(props) {
    super(props);
    this.settingsMenu = React.createRef();
  }

  componentDidMount() {
  }

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  openDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  openStatusModal = () => {
    this.setState({
      statusModalIsOpen: true,
    });
  };

  closeStatusModal = () => {
    this.setState({
      statusModalIsOpen: false,
    });
  };

  deleteTask = () => {
    const { task, deleteTask } = this.props;

    deleteTask(task.id);

    this.closeDeleteModal();
  };

  setTaskStatus = () => {
    const {
      task,
      setTaskStatus,
      service,
      selectedWorkshopId,
    } = this.props;

    const status = task.status === STATUS_DONE ? STATUS_CREATED : STATUS_DONE;

    setTaskStatus(task.id, status, service === SERVICE_WORKSHOP && selectedWorkshopId);

    this.closeStatusModal();
  };

  checkBoxChanged = () => {
    const {
      selected,
      select,
      unselect,
      task,
    } = this.props;

    if (selected) {
      unselect(task.id);
    } else {
      select(task.id);
    }
  };

  getTaskOptions = (task) => {
    const { garage } = this.props;
    const options = [
      {
        label: 'Avaa',
        icon: 'pi pi-info',
        url: `/vehicle/${task.vehicle}/tasks/${task.id}`,
      },
      {
        label: 'Merkitse tehdyksi',
        icon: 'pi pi-pencil',
        command: () => {
          this.openStatusModal();
        },
      },
    ];

    if (task.can_edit) {
      options.push({
        label: 'Poista',
        icon: 'pi pi-trash',
        command: () => {
          this.openDeleteModal();
        },
      });
    }

    return options;
  };

  navigateToTask = (taskId) => {
    const { history, task } = this.props;
    history.push(`/vehicle/${task.vehicle}/tasks/${task.id}`);
  };

  getPriorityIcon = (priority) => {
    return `/img/icon_priority_${priority}.svg`;
  };

  render() {
    const {
      task,
      t,
      selectedFields,
      selected,
      useVehicleIdentifier
    } = this.props;
    const { deleteModalIsOpen, statusModalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'taskRow')}>

              <td style={getStyle(screenSize, styles, 'tableRegNumTd')}>
                <CBLabelAndCheckbox
                  additionalStyles={{ justifyContent: 'center' }}
                  checked={selected}
                  onChange={this.checkBoxChanged}
                />
              </td>

              {
                selectedFields.includes('vehicle_identifier') && useVehicleIdentifier && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={task.vehicle_identifier}
                    additionalStylesPrimaryText={{ fontSize: 24 }}
                  />
                )
              }

              <VehicleListTd
                onClick={this.navigateToTask}
                textPrimary={task.vehicle_registration_number}
                textSecondary={task.vehicle_model}
                additionalStylesPrimaryText={{ fontSize: 24 }}
              />

              {
                selectedFields.includes('priority') && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    icon={this.getPriorityIcon(task.priority)}
                  />
                )
              }
              {
                selectedFields.includes('title') && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={task.title}
                  />
                )
              }
              {
                selectedFields.includes('visibility_display') && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={task.visibility_display}
                  />
                )
              }
              {
                selectedFields.includes('status') && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={t(task.status)}
                  />
                )
              }
              {
                selectedFields.includes('organization') && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={task.organization_name}
                    textSecondary={task.garage_name}
                  />
                )
              }
              {
                selectedFields.includes('employee') && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={task.vehicle_status && task.vehicle_status.employee}
                    textSecondary={task.vehicle_status && task.vehicle_status.business_unit}
                  />
                )
              }
              {
                selectedFields.includes('origin') && (
                  <VehicleListTd
                    textPrimary={t(task.origin)}
                  />
                )
              }
              {
                selectedFields.includes('due_at') && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={formatDate(task.due_at)}
                    additionalStyles={getStyle(screenSize, styles, 'alignCenter')}
                  />
                )
              }

              <td style={getStyle(screenSize, styles, 'tableValueTd')}>
                <CBButtonGarageAction
                  mainIcon={task.status === STATUS_CREATED ? '/img/icon_task_ready.svg' : '/img/icon_circle_white.svg'}
                  text={task.status === STATUS_CREATED ? t('setTaskDone') : t('setTaskUndone')}
                  onClick={this.openStatusModal}
                  additionalStylesMainIcon={{ height: 25, width: 25 }}
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableOptionsTd')}>
                <Menu model={this.getTaskOptions(task)} popup ref={this.settingsMenu} />
                <div
                  style={getStyle(screenSize, styles, 'container')}
                  onClick={(e) => { this.settingsMenu.current.toggle(e); }}
                >
                  <img
                    alt='Options'
                    className='opacity-and-border-on-hover'
                    style={getStyle(screenSize, styles, 'optionsIcon')}
                    src='/img/icon_options.svg'
                  />
                </div>
              </td>
            </tr>
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={this.closeDeleteModal}
              afterOpenModal={this.afterOpenDeleteModal}
              title={t('deleteTaskPromptTitle')}
              text={t('deleteTaskPromptText')}
              buttonText={t('deleteTaskPromptButton')}
              performAction={this.deleteTask}
            />
            <CBPromptModal
              modalIsOpen={statusModalIsOpen}
              closeModal={this.closeStatusModal}
              afterOpenModal={undefined}
              title={task.status === STATUS_CREATED ? t('taskStatusDoneTitle') : t('taskUnmarkDoneStatusPromptTitle')}
              text={task.status === STATUS_CREATED ? t('taskStatusDoneText') : t('taskUnmarkDoneStatusPromptText')}
              buttonText={t('taskPromptButton')}
              performAction={this.setTaskStatus}
            />

          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    infoIcon: {
      height: 32,
      width: 32,
    },
    alignCenter: {
      textAlign: 'center',
    },
    taskRow: {
      height: '60px',
      width: '100%',
      marginBottom: 2,

    },
    imgPlaceholder: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: 100,
      height: 60,
    },
    taskImageColumn: {
      width: 100,
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableValueTd: {
      fontFamily: 'TitilliumWeb-Light',
      fontSize: 18,
      color: '#FFFFFF',
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    optionsIcon: {
      cursor: 'pointer',
    },
    tableRegNumTd: {
      fontFamily: 'TitilliumWeb-Bold',
      fontSize: 18,
      color: '#FFFFFF',
      //position: 'sticky',
      left: 0,
      padding: '8px 16px 8px 16px',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableOptionsTd: {
      //position: 'sticky',
      right: 0,
      padding: '0 16px 0 16px',
      zIndex: 0,
    },
  },
  medium: {
  },
  small: {
  },
};

export default translate('TaskListView')(withRouter(TaskListItem));
