import { combineReducers } from 'redux';
import vehicleReducer from './vehicleReducer';
import vehiclePermissionReducer from './vehiclePermissionReducer';
import authUserReducer from './authUserReducer';
import workshopReducer from './workshopReducer';
import vehicleCategoryReducer from './vehicleCategoryReducer';
import advertisementReducer from './advertisementReducer';
import searchReducer from './searchReducer';
import announcementReducer from './announcementReducer';
import invisibilityPeriodReducer from './invisibilityPeriodReducer';
import globalStatsReducer from './globalStatsReducer';
import garageReducer from './garageReducer';
import userReducer from './userReducer';
import organizationReducer from './organizationReducer';
import messageReducer from './messageReducer';
import taskReducer from './taskReducer';
import appReducer from './appReducer';
import careServiceReducer from './careServiceReducer';
import checkListReducer from './checkListReducer';
import organizationVehicleReminderReducer from './organizationVehicleReminderReducer';
import priceRuleReducer from './priceRuleReducer';
import invoiceReportReducer from './invoiceReportReducer';
import predictedMaintenanceReducer from './predictedMaintenanceReducer';

const rootReducer = combineReducers({
  vehicle: vehicleReducer,
  vehiclePermission: vehiclePermissionReducer,
  vehicleCategory: vehicleCategoryReducer,
  authUser: authUserReducer,
  workshop: workshopReducer,
  advertisement: advertisementReducer,
  search: searchReducer,
  announcement: announcementReducer,
  invisibilityPeriod: invisibilityPeriodReducer,
  globalStats: globalStatsReducer,
  garage: garageReducer,
  user: userReducer,
  organization: organizationReducer,
  message: messageReducer,
  task: taskReducer,
  app: appReducer,
  careService: careServiceReducer,
  checkList: checkListReducer,
  organizationVehicleReminder: organizationVehicleReminderReducer,
  priceRule: priceRuleReducer,
  invoiceReport: invoiceReportReducer,
  predictedMaintenance: predictedMaintenanceReducer,
});

export default rootReducer;
