import React, { useState } from 'react';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import ChangeTaskStatusesModal from './ChangeTaskStatusesModal';
import CreateAutofuturWorkOrderModal from './CreateAutofuturWorkOrderModal';

export default function TaskMultiSelectActionBar({ selectedTasks, selectedWorkshop, vehicle }) {
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
  const [autofuturModalIsOpen, setAutofuturModalIsOpen] = useState(false);

  return (
    <CBMediaQuery>
      {screenSize => (
        <div style={getStyle(screenSize, styles, 'container')}>
          <div style={getStyle(screenSize, styles, 'filterContainer')}>
            <React.Fragment>
              <p style={styles.default.text}>{selectedTasks.length} valittu</p>
              <CBButtonGarageAction
                mainIcon='/img/icon_edit.svg'
                additionalStylesMainIcon={{ height: 25, width: 25 }}
                text="Muuta tilaa..."
                onClick={() => setStatusModalIsOpen(true)}
                disabled={selectedTasks.length === 0}
                containerStyle={getStyle(screenSize, styles, 'buttonContainerStyle')}
                />
              {
                selectedWorkshop && selectedWorkshop.feature_autofutur_api && (
                  <CBButtonGarageAction
                    mainIcon='/img/icon_export.svg'
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                    text="Luo AutoFutur työmääräin"
                    onClick={() => setAutofuturModalIsOpen(true)}
                    disabled={selectedTasks.length === 0}
                  />
                )
              }
            </React.Fragment>
          </div>
          {
            statusModalIsOpen && (
              <ChangeTaskStatusesModal
                modalIsOpen={statusModalIsOpen}
                closeModal={() => setStatusModalIsOpen(false)}
                selectedTaskIds={selectedTasks.map(task => task.id)}
              />
            )
          }
          {
            autofuturModalIsOpen && (
              <CreateAutofuturWorkOrderModal
                modalIsOpen={autofuturModalIsOpen}
                closeModal={() => setAutofuturModalIsOpen(false)}
                selectedTasks={selectedTasks}
                selectedWorkshop={selectedWorkshop}
                vehicle={vehicle}
              />
            )
          }
        </div>
      )}
    </CBMediaQuery>
  )
}


const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      padding: 8,
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      alignItems: 'center',
    },
    text: {
      margin: 0,
    },
    buttonContainerStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid rgba(255,255,255,.3)',
      borderRadius: 50,
      paddingLeft: 10,
      paddingRight: 10,
      height: 40,
      cursor: 'pointer',
    }
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      padding: 8,
    },
  }
}