import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { translate } from 'react-i18next';

import { MAX_FILE_SIZE } from '../../config';
import CBPromptModal from './CBPromptModal';


class CBDropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectModalOpen: false,
    };
  }

  closeModal = (e) => {
    e.stopPropagation();
    this.setState({ rejectModalOpen: false });
  }

  render() {
    const { rejectModalOpen } = this.state;
    const {
      children,
      style,
      onDropAccepted,
      accept = ['image/jpeg', 'image/png', 'text/csv'],
      maxSize = MAX_FILE_SIZE,
      t,
      className,
      disabled = false,
    } = this.props;
    return (
      <React.Fragment>
        <Dropzone
          style={style || styles.dropZone}
          className={className}
          onDropAccepted={onDropAccepted}
          accept={accept}
          maxSize={maxSize}
          multiple={false}
          onDropRejected={() => this.setState({ rejectModalOpen: true })}
          disabled={disabled}
        >
          {children}
        </Dropzone>
        <CBPromptModal
          modalIsOpen={rejectModalOpen}
          closeModal={this.closeModal}
          cancelable={false}
          title={t('imageRejectedTitle')}
          text={t('imageRejectedDescription', { maxSize: (maxSize * 1e-6).toLocaleString('fi-FI') })}
          buttonText='OK'
          performAction={this.closeModal}
        />
      </React.Fragment>
    );
  }
}

const styles = {
  dropZone: {
    position: 'relative',
    width: '100%',
    height: '150px',
    borderWidth: '2px',
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: '16px',
    opacity: '0.8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default translate()(CBDropzone);
