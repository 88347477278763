import React from 'react';
import { translate } from 'react-i18next';
import { Menu } from 'primereact/menu';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { formatDate, getStyle } from '../../../utils/utils';
import { withRouter } from 'react-router';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import VehicleListTd from '../../OrganizationGarageView/OrganizationGarageComponents/VehicleListTd';

class PredictedMaintenanceListItem extends React.Component {
  state = {

  }

  constructor(props) {
    super(props);
    this.settingsMenu = React.createRef();
  }

  componentDidMount() {
  }


  checkBoxChanged = () => {
    const {
      selected,
      select,
      unselect,
      predictedMaintenance,
    } = this.props;

    if (selected) {
      unselect(predictedMaintenance.id);
    } else {
      select(predictedMaintenance.id);
    }
  };

  getPredictedMaintenanceOptions = (predictedMaintenance) => {
    const options = [
      {
        label: 'Avaa',
        icon: 'pi pi-info',
        url: `/vehicle/${predictedMaintenance.vehicle}/`,
      },
    ];
    return options;
  };

  navigateToVehicle = () => {
    const { history, predictedMaintenance } = this.props;
    history.push(`/vehicle/${predictedMaintenance.vehicle}/`);
  };

  getPriorityIcon = (priority) => {
    return `/img/icon_priority_${priority}.svg`;
  };

  render() {
    const {
      predictedMaintenance,
      t,
      selectedFields,
      selected,
      useVehicleIdentifier
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'predictedMaintenanceRow')}>

              <td style={getStyle(screenSize, styles, 'tableRegNumTd')}>
                <CBLabelAndCheckbox
                  additionalStyles={{ justifyContent: 'center' }}
                  checked={selected}
                  onChange={this.checkBoxChanged}
                />
              </td>

              {
                selectedFields.includes('vehicle_identifier') && useVehicleIdentifier && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={predictedMaintenance.vehicle_identifier}
                    additionalStylesPrimaryText={{ fontSize: 24 }}
                  />
                )
              }

              <VehicleListTd
                onClick={this.navigateToVehicle}
                textPrimary={predictedMaintenance.vehicle_registration_number}
                textSecondary={predictedMaintenance.vehicle_model}
                additionalStylesPrimaryText={{ fontSize: 24 }}
              />

              {
                selectedFields.includes('organization') && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={predictedMaintenance.organization_name}
                    textSecondary={predictedMaintenance.garage_name}
                  />
                )
              }
              {
                selectedFields.includes('due_at') && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={formatDate(predictedMaintenance.due_at)}
                    additionalStyles={getStyle(screenSize, styles, 'alignCenter')}
                  />
                )
              }

              {
                selectedFields.includes('prediction_type') && (
                  <VehicleListTd
                    onClick={this.navigateToVehicle}
                    textPrimary={t('predictedType-' + predictedMaintenance.prediction_type, { ns: 'VehicleDetailsView'})}
                  />
                )
              }

              <td style={getStyle(screenSize, styles, 'tableOptionsTd')}>
                <Menu model={this.getPredictedMaintenanceOptions(predictedMaintenance)} popup ref={this.settingsMenu} />
                <div
                  style={getStyle(screenSize, styles, 'container')}
                  onClick={(e) => { this.settingsMenu.current.toggle(e); }}
                >
                  <img
                    alt='Options'
                    className='opacity-and-border-on-hover'
                    style={getStyle(screenSize, styles, 'optionsIcon')}
                    src='/img/icon_options.svg'
                  />
                </div>
              </td>
            </tr>
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    infoIcon: {
      height: 32,
      width: 32,
    },
    alignCenter: {
      textAlign: 'center',
    },
    predictedMaintenanceRow: {
      height: '60px',
      width: '100%',
      marginBottom: 2,

    },
    imgPlaceholder: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: 100,
      height: 60,
    },
    predictedMaintenanceImageColumn: {
      width: 100,
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableValueTd: {
      fontFamily: 'TitilliumWeb-Light',
      fontSize: 18,
      color: '#FFFFFF',
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    optionsIcon: {
      cursor: 'pointer',
    },
    tableRegNumTd: {
      fontFamily: 'TitilliumWeb-Bold',
      fontSize: 18,
      color: '#FFFFFF',
      //position: 'sticky',
      left: 0,
      padding: '8px 16px 8px 16px',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableOptionsTd: {
      //position: 'sticky',
      right: 0,
      padding: '0 16px 0 16px',
      zIndex: 0,
    },
  },
  medium: {
  },
  small: {
  },
};

export default translate('PredictedMaintenanceListView')(withRouter(PredictedMaintenanceListItem));
