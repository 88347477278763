import React from 'react';
import { Route } from 'react-router';
import { translate } from 'react-i18next';
import axios from 'axios';
import isEqual from "lodash/isEqual";
import VehicleDetailsDocumentContent from './VehicleDetailsDocumentContent';
import VehicleDetailsDocumentList from './VehicleDetailsDocumentList';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import CBMediaQuery from '../../shared/CBMediaQuery';

class VehicleDetailsMainDocument extends React.Component {
  state = {
    selectedDocument: [],
    filteredDocumentIds: null,
    filters: {
      search: '',
    }
  }

  componentDidMount() {
    this.filterDocumentLocal(this.state.filters);
  }

  componentDidUpdate(prevProps) {
    const { vehicle } = this.props;
    const { vehicle: prevVehicle } = prevProps;
    if (!isEqual(vehicle.documents, prevVehicle.documents)) {
      this.filterDocumentLocal(this.state.filters);
    }
  };

  getFilteredDocument = () => {
    const { documents } = this.props.vehicle;
    const { filteredDocumentIds } = this.state;

    if (filteredDocumentIds === null) {
      return documents;
    }

    return documents.filter(document =>
      filteredDocumentIds.includes(parseInt(document.id)),
    );
  };

  filterDocumentLocal = (filters) => {
    return;
    const { vehicle } = this.props;

    const documentIds = vehicle.documents.filter(document => {
      return true
    }).map((t) => t.id);

    const newSelectedDocument = this.state.selectedDocument.filter(id => documentIds.includes(id));
    this.setState({
      selectedDocument: newSelectedDocument,
      filteredDocumentIds: documentIds,
    });
  };

  selectDocument = (document) => {
    const { selectedDocument } = this.state;
    let newSelectedDocument = [...selectedDocument];

    if (newSelectedDocument.includes(document.id)) {
      newSelectedDocument = newSelectedDocument.filter(id => id !== document.id);
    } else {
      newSelectedDocument.push(document.id);
    }

    this.setState({
      selectedDocument: newSelectedDocument
    });
  };

  selectAllDocument = (document) => {
    const { selectedDocument } = this.state;
    const { documents } = this.props.vehicle;
    const filteredDocument = this.getFilteredDocument(documents);
    let newSelectedDocument = [];

    if (filteredDocument.length !== selectedDocument.length) {
      newSelectedDocument = this.getFilteredDocument(documents).map(document => document.id);
    }

    this.setState({
      selectedDocument: newSelectedDocument
    });
  };

  setFilters = (filters) => {
    this.filterDocumentLocal(filters);
    this.setState({ filters });
  };

  render() {
    const { t, vehicle } = this.props;
    const { filters, selectedDocument } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader
              type='document'
              text={t('documentsHeading')}
              filters={this.state.filters}
              setFilters={this.setFilters}
            />
            {screenSize === 'small' && <VehicleDetailsDocumentList selectedDocument={selectedDocument} selectAllDocument={this.selectAllDocument} selectDocument={this.selectDocument} filters={filters} vehicle={vehicle} documents={this.getFilteredDocument()} />}
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <VehicleDetailsDocumentList selectedDocument={selectedDocument} selectAllDocument={this.selectAllDocument} selectDocument={this.selectDocument} filters={filters} vehicle={vehicle} documents={this.getFilteredDocument()} />}
              <Route
                exact
                path='/vehicle/:vehicleId/documents/'
                render={() => <VehicleDetailsDocumentContent documents={this.getFilteredDocument()} vehicle={vehicle} />}
              />
              <Route
                path='/vehicle/:vehicleId/documents/:documentId'
                render={props => <VehicleDetailsDocumentContent documents={this.getFilteredDocument()} {...props} vehicle={vehicle} />}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainDocument);
