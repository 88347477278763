import React from 'react';
import { translate } from 'react-i18next';
import { getStyle, styleMerge } from '../../../utils/utils';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import CBDropzone from '../../shared/CBDropzone';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { withRouter } from 'react-router-dom';


class DocumentEditRow extends React.Component {
  state = {
  }

  render() {
    const {
      t,
      openDeleteDocumentModal,
      editDocument,
      openStatusModal,
      document,
      isDone,
      history,
      downloadDocument
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxContainer')}>
            <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxLeftContainer')}>
            </div>
              <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxRightContainer')}>
                {document.can_edit && (
                  <React.Fragment>
                    <CBButtonGarageAction
                      mainIcon='/img/icon_trashcan.svg'
                      text='Poista'
                      onClick={openDeleteDocumentModal}
                      additionalStylesMainIcon={{ height: 25, width: 25 }}
                    />
                    <CBButtonGarageAction
                      mainIcon='/img/icon_edit.svg'
                      text='Muokkaa'
                      onClick={editDocument}
                      additionalStylesMainIcon={{ height: 25, width: 25 }}
                    />
                  </React.Fragment>
                )}

                <CBButtonGarageAction
                  mainIcon='/img/icon_download.svg'
                  text='Lataa tiedosto'
                  onClick={downloadDocument}
                  additionalStylesMainIcon={{ height: 25, width: 25 }}
                />
              </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    boxLeftContainer: {
      display: 'flex',
    },
    boxRightContainer: {
      display: 'flex',
    },
  },
};

export default translate('VehicleDetailsView')(withRouter(DocumentEditRow));
