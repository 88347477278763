import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
// import Routes from './routes';
import './App.css';
import { fetchAuthUser as _fetchAuthUser } from './actions/authUserActions';
import { fetchAdvertisements as _fetchAdvertisements } from './actions/advertisementActions';
import { setAppService as _setAppService } from './actions/appActions';
import CBSpinner from './components/shared/CBSpinner';
import {
  Route,
  Switch
} from 'react-router';
import HomeEndUser from './components/HomeView/HomeEndUser';
import HomeWSUser from './components/HomeView/HomeWSUser';
import GarageLayoutView from './components/GarageView/GarageLayoutView';
import MainLayoutView from './components/MainLayoutView';
import MainHeaderBar from './components/Header/MainHeaderBar';
import Footer from './components/Footer/Footer';
import VehicleDetailsView from './components/VehicleDetailsView/VehicleDetailsView';
import WSVehicleDetailsView from './components/WSVehicleDetailsView/WSVehicleDetailsView';
import UserSettingsView from './components/SettingsView/UserSettingsView';
import StaffStatsView from './components/StaffStatsView/StaffStatsView';
import AdvertisementsView from './components/AdvertisementsView/AdvertisementsView';
import i18n from './i18n';
import AnnouncementView from './components/AnnouncementView/AnnouncementView';
import WSSettingsView from './components/WSSettingsView/WSSettingsView';
import OrganizationGarageLayoutView from './components/OrganizationGarageView/OrganizationGarageLayoutView';
import PaymentResultView from './components/PaymentsView/PaymentResultView';
import AdvertisementEditorView from './components/AdvertisementsView/AdvertisementEditorView';
import HomeWorkshopUser from './components/HomeView/HomeWorkshopUser';
import { SERVICE_ADMIN, SERVICE_FLEET, SERVICE_PERSONAL, SERVICE_WORKSHOP } from './utils/utils';
import HomeAdminUser from './components/HomeView/HomeAdminUser';
import SaleReportView from './components/SaleReportView/SaleReportView';


class App extends React.Component {
  componentWillMount = () => {
    const { fetchAuthUser, fetchAdvertisements } = this.props;
    fetchAuthUser();
    fetchAdvertisements();
    this.setAppService(true);
  };

  setAppService = () => {
    const { setAppService } = this.props;
    const url = window.location.href;
    let service = SERVICE_PERSONAL;

    if (url.includes('service.')) {
      service = SERVICE_WORKSHOP;
    } else if (url.includes('fleet.')) {
      service = SERVICE_FLEET;
    } else if (url.includes('admin.')) {
      service = SERVICE_ADMIN;
    }

    setAppService(service);

    return service;
  };

  render() {
    console.log('Ovio Carbook Oy, 2025/1');
    const { authUserRequest, history, service } = this.props;
    const { user } = authUserRequest;

    if (authUserRequest.userStatus.error) {
      return <CBSpinner />;
    } else if (authUserRequest.userStatus.loading || !user) {
      return <CBSpinner />;
    } else {
      const isStaff = user.is_staff;
      return (
        <I18nextProvider i18n={i18n}>
          <ConnectedRouter history={history}>
            <div>
              <MainHeaderBar isStaff={isStaff} />
              <ToastContainer />
              <MainLayoutView>
                <Switch>
                  <Route
                    exact
                    path='/'
                    component={(routeProps) => {
                      if (service === SERVICE_WORKSHOP) {
                        return <HomeWorkshopUser {...routeProps} {...this.props} />;
                      } else if (isStaff && service === SERVICE_ADMIN) {
                        return <HomeAdminUser {...routeProps} {...this.props} />;
                      } else if (service === SERVICE_PERSONAL) {
                        return <HomeEndUser {...routeProps} {...this.props} />;
                      } else if (service === SERVICE_FLEET) {
                        return <OrganizationGarageLayoutView {...routeProps} {...this.props} />;
                      }
                      return <div />;
                    }}
                  />
                  {isStaff && <Route path='/staff_settings' render={props => <UserSettingsView {...props} staff />} />}
                  {
                    service === SERVICE_WORKSHOP && (
                      <Fragment>
                        <Route path='/workshop/:workshopId/' component={HomeWorkshopUser} />
                        <Route path='/workshop-settings' component={WSSettingsView} />
                        <Route path='/workshop-vehicle/:vehicleId' component={WSVehicleDetailsView} />
                        <Route path='/announcements' component={AnnouncementView} />
                        <Route path='/vehicle/:vehicleId' component={VehicleDetailsView} />
                        <Route path='/payment_result/:orderId?' component={PaymentResultView} />
                      </Fragment>
                    )
                  }

                  {
                    isStaff && service === SERVICE_ADMIN && (
                      <Fragment>
                        <Route path='/workshop-vehicle/:vehicleId' component={WSVehicleDetailsView} />
                        <Route path='/announcements' component={AnnouncementView} />
                        <Route path='/workshop-settings' component={WSSettingsView} />
                        <Route path='/sale-report' component={SaleReportView} />
                      </Fragment>
                    )
                  }
                  {
                    (service === SERVICE_PERSONAL || service === SERVICE_FLEET) && (
                      <Fragment>
                        <Route path='/garage' component={GarageLayoutView} />
                        <Route path='/vehicle/:vehicleId' component={VehicleDetailsView} />
                        <Route path='/settings' component={UserSettingsView} />
                        <Route path='/organization_garage/:garageId' component={OrganizationGarageLayoutView} />
                        <Route path='/payment_result/:orderId?' component={PaymentResultView} />
                      </Fragment>
                    )
                  }

                </Switch>
                <Route exact path='/' component={Footer} />
                <Route path='/workshop' component={Footer} />
                <Route path='/garage' component={Footer} />
                <Route path='/vehicle' component={Footer} />
                <Route path='/workshop-vehicle' component={Footer} />
                <Route path='/announcements' component={Footer} />
                <Route path='/organization_garage' component={Footer} />
                <Route path='/payment_result' component={Footer} />
              </MainLayoutView>
            </div>
          </ConnectedRouter>
        </I18nextProvider>
      );
    }
  }
}

App.propTypes = {
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    authUserRequest: state.authUser,
    service: state.app.service,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchAuthUser: _fetchAuthUser,
    fetchAdvertisements: _fetchAdvertisements,
    setAppService: _setAppService,
  },
)(App);
