import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { finnishDateFormat, getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSearchInput from '../../shared/CBSearchInput';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import moment from 'moment';


class PredictedMaintenanceFilterBar extends Component {
  render() {
    const {
      changeSearchTerms,
      changeFilterTerms,
      refetchPredictedMaintenances,
      t,
      filters,
      useIdentifiers
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <div>
              <div style={getStyle(screenSize, styles, 'container')}>
                <div style={getStyle(screenSize, styles, 'containerFilters')}>
                  <div style={getStyle(screenSize, styles, 'containerSearch')}>
                    <CBSearchInput
                      containerStyle={{ height: 40, width: 240, marginLeft: 0 }}
                      changeSearchTerms={changeSearchTerms}
                      onBlur={refetchPredictedMaintenances}
                    />

                  </div>
                  <div className='datepicker-container-white-bg'>
                    <p>Arvioitu huoltoaika viimeistään</p>
                    <CBDatePickerInput
                      displayedDate={filters.due_at__lte ? moment(filters.due_at__lte) : null}
                      type='date'
                      dateFormat={finnishDateFormat}
                      changeDate={(value) => {
                        const newFilters = {...filters};
                        changeFilterTerms({ ...newFilters, due_at__lte: moment(value).format('YYYY-MM-DD') });
                      }}
                    />
                  </div>
                </div>
                <div style={getStyle(screenSize, styles, 'containerActions')}>

                </div>
              </div>
            </div>
            <div style={getStyle(screenSize, styles, 'checkBoxContainer')}>
              {
                useIdentifiers && (
                  <CBLabelAndCheckbox
                    labelText={'Hae vain tunnisteista'}
                    checked={filters.search_identifiers}
                    onChange={
                      () => {
                        const newFilters = {...filters};
                        if (!filters.search_identifiers) {
                          return changeFilterTerms({...newFilters, search_identifiers: true })
                        } else {
                          delete newFilters.search_identifiers;
                          return changeFilterTerms({...newFilters})
                        }
                      }
                    }
                  />
                )
              }
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    checkBoxContainer: {
      marginTop: 16,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    containerSearch: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      gap: 16,
    },
    containerFilters: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: 16,
    },
    containerActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('PredictedMaintenanceListView')(PredictedMaintenanceFilterBar));
