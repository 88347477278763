import React from 'react';
import { translate } from 'react-i18next';
import NavbarButton from '../../shared/NavbarButton';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { BLEEDING_EDGE_USERS, SERVICE_PERSONAL, getStyle } from '../../../utils/utils';

class VehicleDetailsNavbar extends React.Component {
  // Determine which tab is active by URL
  getActiveTab = () => {
    const { isUnpaidFleetUser } = this.props;
    const url = window.location.pathname;

    if (url.includes('/messages')) return 'Message';
    if (url.includes('/tasks')) return 'Task';
    if (url.includes('/treatments')) return 'Treatment';
    if (url.includes('/maintenances')) return 'Maintenance';
    if (url.includes('/sale_report')) return 'Sale report';
    if (url.includes('/mileage')) return 'Mileage';
    if (url.includes('/document')) return 'Document';

    // Fallback
    return isUnpaidFleetUser ? 'Maintenance' : 'Basic';
  };

  buildButtonConfig = ({ vehicle, t, isUnpaidFleetUser, hasFeatureTasksAndMessages, service }) => {
    // Common checks
    const isNotPersonal = !vehicle.is_personal_vehicle;
    const showSaleReport = vehicle.is_personal_vehicle && service === SERVICE_PERSONAL;

    return [
      {
        id: 'Basic',
        to: `/vehicle/${vehicle.id}/basic`,
        iconSrc: '/img/icon_car_basic_info.svg',
        text: t('basicInfoHeading'),
        visible: true,
        disabled: isUnpaidFleetUser,
      },
      {
        id: 'Maintenance',
        to: `/vehicle/${vehicle.id}/maintenances/`,
        iconSrc: '/img/icon_car_service.svg',
        text: t('maintenanceHistoryHeading'),
        visible: !!vehicle.maintenances,
      },
      {
        id: 'Treatment',
        to: `/vehicle/${vehicle.id}/treatments`,
        iconSrc: '/img/icon_car_maintenance.svg',
        text: t('treatmentHistoryHeading'),
        visible: !!vehicle.treatments,
      },
      {
        id: 'Sale report',
        to: `/vehicle/${vehicle.id}/sale_report`,
        iconSrc: '/img/icon_vehicle_report.svg',
        text: t('saleReportHeading'),
        visible: showSaleReport,
      },
      {
        id: 'Message',
        to: `/vehicle/${vehicle.id}/messages`,
        iconSrc: '/img/icon_announcements.svg',
        text: t('messagesHeading'),
        visible: isNotPersonal && hasFeatureTasksAndMessages,
      },
      {
        id: 'Mileage', // For small: used if not personal & !hasFeature => group1, or if hasFeature => group2
        to: `/vehicle/${vehicle.id}/mileages`,
        iconSrc: '/img/icon-wheel.svg',
        text: t('tasksMileage'),
        visible: isNotPersonal,
      },
      {
        id: 'Task',
        to: `/vehicle/${vehicle.id}/tasks`,
        iconSrc: '/img/icon_task_list.svg',
        text: t('tasksHeading'),
        visible: isNotPersonal && hasFeatureTasksAndMessages,
      },
      {
        id: 'Document',
        to: `/vehicle/${vehicle.id}/documents`,
        iconSrc: '/img/icon_document.svg',
        text: t('documentsHeading'),
        visible: isNotPersonal,
      },
    ];
  };

  // For small screens, replicate the exact grouping from your code:
  // group1 = [Basic, Maintenance, Treatment, Sale, {Message OR Mileage}]
  // group2 = [Task, {Mileage if hasFeature}, Document]
  getSmallGroups = (buttons, hasFeatureTasksAndMessages) => {
    const group1 = [];
    const group2 = [];

    // We push them in the same order we want them to appear
    // 1) Basic
    // 2) Maintenance
    // 3) Treatment
    // 4) Sale report
    // 5) if hasFeature -> Message ELSE -> Mileage
    const basicBtn     = buttons.find(b => b.id === 'Basic' && b.visible);
    const maintBtn     = buttons.find(b => b.id === 'Maintenance' && b.visible);
    const treatBtn     = buttons.find(b => b.id === 'Treatment' && b.visible);
    const saleBtn      = buttons.find(b => b.id === 'Sale report' && b.visible);
    const messageBtn   = buttons.find(b => b.id === 'Message' && b.visible);
    const mileageBtn   = buttons.find(b => b.id === 'Mileage' && b.visible);

    // group1 items
    if (basicBtn)   group1.push(basicBtn);
    if (maintBtn)   group1.push(maintBtn);
    if (treatBtn)   group1.push(treatBtn);
    if (saleBtn)    group1.push(saleBtn);
    if (hasFeatureTasksAndMessages && messageBtn) {
      group1.push(messageBtn);
    } else if (!hasFeatureTasksAndMessages && mileageBtn) {
      // if no feature => put "Mileage" in group1
      group1.push(mileageBtn);
    }

    // group2 items:
    //  - Task
    //  - if hasFeature => "Mileage"
    //  - Document
    const taskBtn      = buttons.find(b => b.id === 'Task' && b.visible);
    const docBtn       = buttons.find(b => b.id === 'Document' && b.visible);

    if (hasFeatureTasksAndMessages && mileageBtn) {
      // if hasFeature => put "Mileage" in group2
      group2.push(mileageBtn);
    }
    if (taskBtn) {
      // note your code in small *actually* put tasks first or second?
      // original code put tasks first in the second group, then mileage, then doc
      // so let's preserve that order:
      // tasks => mileage => doc
      group2.unshift(taskBtn);
    }
    if (docBtn) group2.push(docBtn);

    // Filter out anything that ended up undefined or duplicates
    return [group1.filter(Boolean), group2.filter(Boolean)];
  };

  renderNavbarButtons = (buttonList, activeTab) => {
    // Render each visible button using NavbarButton
    return buttonList.map(btn => (
      <NavbarButton
        key={btn.id}
        to={btn.to}
        iconSrc={btn.iconSrc}
        alt={btn.alt || btn.id}
        buttonText={btn.text}
        setActiveTab={tabName => this.setState({ activeTab: tabName })}
        activeTab={activeTab}
        disabled={btn.disabled}
        column
      />
    ));
  };

  render() {
    const { t, vehicle, isUnpaidFleetUser, hasFeatureTasksAndMessages, service, user } = this.props;
    const activeTab = this.getActiveTab();

    // This var was in original code but never used for anything visible. Keeping if needed.
    const isReportDemoUser =
      user.is_staff || BLEEDING_EDGE_USERS.includes(user.email) || user.email.includes('ovio.fi');

    // 1) Build the main button array
    const allButtons = this.buildButtonConfig({
      vehicle,
      t,
      isUnpaidFleetUser,
      hasFeatureTasksAndMessages,
      service,
    });

    return (
      <CBMediaQuery>
        {screenSize => {
          const isSmall = screenSize === 'small';

          // 2) Determine how to display them
          let group1 = allButtons.filter(b => b.visible); // default to all
          let group2 = []; // by default, no second row

          // For small, replicate old grouping logic
          if (isSmall) {
            const [smallGroup1, smallGroup2] = this.getSmallGroups(allButtons, hasFeatureTasksAndMessages);
            group1 = smallGroup1;
            group2 = smallGroup2;
          }

          return (
            <div style={getStyle(screenSize, styles, 'container')}>
              <div
                style={{
                  ...getStyle(screenSize, styles, 'navbarContainer'),
                  flexDirection: isSmall ? 'column' : 'row',
                }}
              >
                {/* Group 1 */}
                <div style={getStyle(screenSize, styles, 'buttonGroup')}>
                  {this.renderNavbarButtons(group1, activeTab)}
                </div>

                {/* Group 2 (only in small view, second row) */}
                {group2.length > 0 && (
                  <div
                    style={{
                      ...getStyle(screenSize, styles, 'buttonGroup'),
                      borderTop: isSmall ? '1px solid rgba(255,255,255,.3)' : 0,
                    }}
                  >
                    {this.renderNavbarButtons(group2, activeTab)}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      width: '100%',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
    navbarContainer: {
      display: 'flex',
      width: '70%',
    },
    buttonGroup: {
      width: '100%',
      display: 'flex',
    },
  },
  medium: {
    navbarContainer: {
      width: '100%',
    },
  },
  // optional extra style overrides
};

export default translate('VehicleDetailsView')(VehicleDetailsNavbar);
