import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, styleMerge, TYPE_CARE_SERVICE, TYPE_ORGANIZATION_GARAGE, TYPE_WORKSHOP_ALL } from '../../../utils/utils';
import VehicleListItem from './VehicleListItem';
import {
  fetchVehiclesByOrganization as _fetchVehiclesByOrganization,
  removeVehicleFromCareServiceFleet as _removeVehicleFromCareServiceFleet,
  removeVehicleFromCareServiceWorkshop as _removeVehicleFromCareServiceWorkshop,
  removeVehicleFromGarage as _removeVehicleFromGarage,
} from '../../../actions/vehicleActions';

import { fetchGarages as _fetchGarages } from '../../../actions/garageActions';
import AddCarModal from '../../GarageView/GarageViewComponents/AddCarModal';
import UpdateVehiclesModal from '../../GarageView/GarageViewComponents/UpdateVehiclesModal';
import VehicleListTh from './VehicleListTh';
import VehicleFilterBar from '../../shared/VehicleFilterBar';
import VehicleActionBar from '../../shared/VehicleActionBar';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import AddMessageModal from '../../VehicleDetailsView/VehicleDetailsComponents/AddMessageModal';
import CBPromptModal from '../../shared/CBPromptModal';
import { Paginator } from 'primereact/paginator';
import AddEditVehicleModal from '../../Workshop/AddEditVehicleModal';


const SELECTED_FIELDS_PREFIX = 'selectedVehicleFields-';

class VehicleListView extends React.Component {
  state = {
    modalIsOpen: false,
    messageModalIsOpen: false,
    deleteModalIsOpen: false,
    addWSVehicleModalIsOpen: false,
    search: '',
    ordering: 'registration_number',
    fields: [],
    filters: {},
    selectedFields: [],
    selectedVehicles: [],
    checkListItemChoices: []
  };

  componentWillMount() {
    const {
      fetchGarages,
      ordering,
    } = this.props;

    this.resetFields();

    if (ordering) {
      this.setState({
        ordering
      });
    }

    fetchGarages();
    this.fetchCheckListItemChoices();

  }

  fetchCheckListItemChoices = async () => {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    const options = { params: {} };

    if (selectedWorkshopId) {
      options.params.workshop_id = selectedWorkshopId;
    } else if (selectedOrganizationId) {
      options.params.organization_id = selectedOrganizationId;
    }

    const response = await axios.get('/uiapi/1/check_list_item_choices/', options);
    if (response && response.data) {
      this.setState({
        checkListItemChoices: response.data
      });
    }
  }

  resetFields = () => {
    const {
      t,
      listType,
    } = this.props;
    const selectedFields = JSON.parse(localStorage.getItem(SELECTED_FIELDS_PREFIX + listType));

    let allFields = [
      {
        label: t('headingImage'),
        value: 'image',
      },
      {
        label: t('headingVehicleClass'),
        value: 'vehicle_class',
      },
      {
        label: t('headingCompanyAndGarage'),
        value: 'organization',
      },
      {
        label: t('headingWorkshopName'),
        value: 'care_service_workshop_name'
      },
      {
        label: t('headingEmployee'),
        value: 'employee',
      },
      {
        label: t('headingUnreadMessageCount'),
        value: 'unread_message_count',
      },
      {
        label: t('headingAttachedVehicles'),
        value: 'attached_vehicles',
      },
      {
        label: t('headingLastMaintenanceDate'),
        value: 'last_maintenance_date',
      },
      {
        label: t('headingMaintenanceCount'),
        value: 'maintenance_count',
      },
      {
        label: t('headingNextInspectionDate'),
        value: 'inspection_interval_end',
      },
      {
        label: t('headingNextCheckDate'),
        value: 'next_check_date',
      },
      {
        label: t('headingSaleReport'),
        value: 'get_sale_report',
      },
      {
        label: t('headingTraficomSyncDate'),
        value: 'traficom_sync_date',
      },
    ];

    if (listType === TYPE_WORKSHOP_ALL) {
      allFields = allFields.filter((item) => this.getDefaultFields().includes(item.value));
    }

    if (this.getCurrentCompany().use_vehicle_identifier) {
      allFields.splice(1, 0, {
        label: t('headingVehicleIdentifier'),
        value: 'identifier',
      })
    }

    this.setState({
      fields: allFields,
    });

    this.setState({
      selectedFields: selectedFields || this.getDefaultFields(),
    });
  }

  getCurrentCompany = () => {
    const {
      listType,
      user,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    let currentCompany = {};
    if (listType == TYPE_CARE_SERVICE || listType == TYPE_WORKSHOP_ALL) {
      currentCompany = user.workshops.find((ws) => selectedWorkshopId == ws.id);
    } else {
      currentCompany = user.organizations.find((org) => selectedOrganizationId == org.id);
    }

    return currentCompany;
  }

  commitSearch = () => {
    const { search } = this.state;
    this.refetchVehicles(undefined, search);
  }

  setSelectedFields = (newSelectedFields) => {
    const { listType } = this.props;
    this.setState({
      selectedFields: newSelectedFields,
    });
    JSON.stringify(newSelectedFields);
    localStorage.setItem(SELECTED_FIELDS_PREFIX + listType, JSON.stringify(newSelectedFields));
  }

  getDefaultFields = () => {
    const { listType } = this.props;

    let defaultFields =  [
      'care_service_workshop_name',
      'unread_message_count',
      'last_maintenance_date',
      'inspection_interval_end',
      'next_check_date'
    ];

    if (listType === TYPE_CARE_SERVICE) {
      defaultFields = [
        'organization',
        'unread_message_count',
        'last_maintenance_date',
        'inspection_interval_end',
        'traficom_sync_date'
      ];
    } else if (listType === TYPE_WORKSHOP_ALL ) {
      defaultFields =  [
        'last_maintenance_date',
        'inspection_interval_end',
        'traficom_sync_date',
        'maintenance_count',
        'vehicle_class',
      ];
    }

    if (this.getCurrentCompany().use_vehicle_identifier) {
      defaultFields.push('identifier');
    }

    return defaultFields;
  }

  getGarage = () => {
    const { garages, garageId } = this.props;
    return garages.find(garage => garage.id == garageId);
  }

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  changeFilterTerms = value => {
    this.setState({
      filters: value
    });
    this.refetchVehicles(null, null, value);
  }

  getSelectedVehicles = () => {
    const { selectedVehicles } = this.state;
    const { vehicles } = this.props;
    if(!vehicles || vehicles.length == 0) {
      return [];
    }
    return vehicles.filter((veh) => selectedVehicles.includes(veh.id));
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  closeUpdateModal = () => {
    this.setState({
      updateModalIsOpen: false,
    });
  };

  closeMessageModal = () => {
    this.setState({
      messageModalIsOpen: false,
    });
  };

  closeDeletePromptModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      this.refetchVehicles();
      this.resetFields();
      this.fetchCheckListItemChoices();
    }
  };

  afterOpenModal = () => {
  };

  selectVehicle = (vehicleId) => {
    const { selectedVehicles } = this.state;

    const newSelectedVehicles = selectedVehicles.slice();

    newSelectedVehicles.push(vehicleId);
    this.setState({ selectedVehicles: newSelectedVehicles });
  };

  unselectVehicle = (vehicleId) => {
    const { selectedVehicles } = this.state;

    const newSelectedVehicles = selectedVehicles.filter((item) => item !== vehicleId);

    this.setState({ selectedVehicles: newSelectedVehicles });
  };

  addCar = () => {
    this.setState({ modalIsOpen: true });
  };

  updateVehicles = () => {
    this.setState({ updateModalIsOpen: true });
  };

  sendMessages = () => {
    this.setState({ messageModalIsOpen: true });
  };

  deleteVehicles = () => {
    this.setState({ deleteModalIsOpen: true });
  };

  refetchVehicles = (newOrdering, newSearch, newFilters, newPage) => {
    const { fetchVehicles, usePagination } = this.props;
    const { search, ordering, filters, page } = this.state;

    let params = {
      search: newSearch || search,
      ordering: newOrdering || ordering,
    }

    if (usePagination) {
      if (newSearch || newFilters) {
        params.page = 1
      } else {
        params.page = newPage || page;
      }
    }

    params = newFilters ? { ...newFilters, ...params } : { ...filters, ...params }

    fetchVehicles(params);

    this.setState({
      selectedVehicles: []
    });
  };

  changePage = (e) => {
    this.refetchVehicles(null, null, null, e.page + 1);
    this.setState({
      page: e.page + 1,
    });
  };

  changeOrdering = (clickedOrdering) => {
    const { ordering } = this.state;
    let newOrdering;
    if (ordering === clickedOrdering) {
      newOrdering = `-${ordering}`;
    } else if (`-${ordering}` === clickedOrdering) {
      newOrdering = 'id';
    } else {
      newOrdering = clickedOrdering;
    }
    this.setState({
      ordering: newOrdering,
    });
    this.refetchVehicles(newOrdering);
  };

  getOrderingSymbol = (columnName) => {
    const { ordering } = this.state;
    if (ordering === columnName) {
      return '/img/down_arrow_icon.svg';
    } else if (ordering === `-${columnName}`) {
      return '/img/up_arrow_icon.svg';
    }
    return undefined;
  };

  selectAllVehicles = () => {
    const { vehicles } = this.props;

    const newSelectedVehicles = vehicles.map((vehicle) => vehicle.id);
    this.setState({ selectedVehicles: newSelectedVehicles });
  };

  unselectAllVehicles = () => {
    this.setState({ selectedVehicles: [] });
  };

  selectAllChanged = () => {
    const { vehicles } = this.props;
    const { selectedVehicles } = this.state;
    const allChecked = selectedVehicles.length === vehicles.length;

    if (allChecked) {
      this.unselectAllVehicles();
    } else {
      this.selectAllVehicles();
    }
  }

  endCareService = (vehicle) => {
    const {
      service,
      selectedWorkshopId,
      selectedOrganizationId,
      removeVehicleFromCareServiceFleet,
      removeVehicleFromCareServiceWorkshop
    } = this.props;

    if (service === SERVICE_WORKSHOP) {
      removeVehicleFromCareServiceWorkshop(vehicle.id, selectedWorkshopId)
    } else if (service === SERVICE_FLEET) {
      removeVehicleFromCareServiceFleet(vehicle.id, selectedOrganizationId)
    }
  };

  render() {
    const {
      vehicles,
      t,
      removeVehicleFromGarage,
      user,
      inviteToCareService,
      garageVehiclesLoading,
      careServiceVehiclesLoading,
      listType,
      garages,
      selectedOrganizationId,
      service,
      usePagination,
      pagination,
      selectedWorkshopId,
      hasFeatureVehicleReports,
      hasFeatureTasksAndMessages,
      hasFeatureVehicleReportsPdf
    } = this.props;

    const {
      selectedVehicles,
      messageModalIsOpen,
      deleteModalIsOpen,
      addWSVehicleModalIsOpen
    } = this.state;

    const {
      modalIsOpen,
      updateModalIsOpen,
      fields,
      selectedFields,
      filters,
      checkListItemChoices
    } = this.state;
    const garage = this.getGarage();
    const selectedRegistrationNumbers = this.getSelectedVehicles().map((veh) => veh.registration_number).join(', ');

    const currentOrganization = user.organizations.find((org) => selectedOrganizationId == org.id);

    /*
    const vehiclesLoading = garageVehiclesLoading || careServiceVehiclesLoading;

    if (vehiclesLoading) {
      return <CBSpinner />;
    }
    */


    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'topBarsContainer')}>
              <VehicleFilterBar
                useIdentifiers={this.getCurrentCompany().use_vehicle_identifier}
                changeSearchTerms={this.changeSearchTerms}
                changeFilterTerms={this.changeFilterTerms}
                refetchVehicles={this.refetchVehicles}
                addCar={this.addCar}
                inviteToCareService={inviteToCareService}
                filters={filters}
                listType={listType}
                addWSVehicle={() => { this.setState({addWSVehicleModalIsOpen: true})}}
                commitSearch={this.commitSearch}
                checkListItemChoices={checkListItemChoices}
              />
              <hr style={{ opacity: '30%' }} />
              <VehicleActionBar
                selectedVehicles={selectedVehicles}
                updateVehicles={this.updateVehicles}
                sendMessages={this.sendMessages}
                deleteVehicles={this.deleteVehicles}
                listType={listType}
                hasFeatureVehicleReports={hasFeatureVehicleReports}
                hasFeatureTasksAndMessages={hasFeatureTasksAndMessages}
              />
            </div>

            <div style={getStyle(screenSize, styles, 'tableContainer')}>
              <table style={getStyle(screenSize, styles, 'table')}>
                <thead>
                  <tr>
                    <th style={styleMerge(getStyle(screenSize, styles, 'tableHeaderFirst'), getStyle(screenSize, styles, 'alignCenter'))}>
                      <CBLabelAndCheckbox
                        checked={selectedVehicles.length === vehicles.length}
                        additionalStyles={{ justifyContent: 'center' }}
                        onChange={this.selectAllChanged}
                      />
                    </th>

                    {
                      selectedFields.includes('image') && listType != TYPE_WORKSHOP_ALL && (
                        <th style={getStyle(screenSize, styles, 'tableHeader')} />
                      )
                    }

                    {
                      selectedFields.includes('identifier') && this.getCurrentCompany().use_vehicle_identifier && (
                        <VehicleListTh
                          text={t('headingVehicleIdentifier')}
                          fieldName='identifier'
                          orderingSymbol={this.getOrderingSymbol('identifier')}
                          changeOrdering={() => {
                            this.changeOrdering('identifier');
                          }}
                        />
                      )
                    }

                    <VehicleListTh
                      text={t('headingRegistrationNumber')}
                      fieldName='registration_number'
                      orderingSymbol={this.getOrderingSymbol('registration_number')}
                      changeOrdering={() => {
                        this.changeOrdering('registration_number');
                      }}
                    />

                    {
                      selectedFields.includes('vehicle_class') && (
                        <VehicleListTh
                          text={t('headingVehicleClass')}
                          fieldName='vehicle_class'
                          orderingSymbol={this.getOrderingSymbol('vehicle_class')}
                          changeOrdering={() => {
                            this.changeOrdering('vehicle_class');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('organization') && listType != TYPE_WORKSHOP_ALL && (
                        <VehicleListTh
                          text={t('headingCompanyAndGarage')}
                          fieldName='organization'
                          orderingSymbol={this.getOrderingSymbol('organization')}
                          changeOrdering={() => {
                            this.changeOrdering('organization');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('care_service_workshop_name') && listType != TYPE_WORKSHOP_ALL && (
                        <VehicleListTh
                          text={t('headingWorkshopName')}
                          fieldName='care_service_workshop_name'
                          orderingSymbol={this.getOrderingSymbol('care_service_workshop_name')}
                          changeOrdering={() => {
                            this.changeOrdering('care_service_workshop_name');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('employee') && listType != TYPE_WORKSHOP_ALL && (
                        <VehicleListTh
                          text={t('headingEmployee')}
                          fieldName='employee'
                          orderingSymbol={this.getOrderingSymbol('employee')}
                          changeOrdering={() => {
                            this.changeOrdering('employee');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('unread_message_count') && listType != TYPE_WORKSHOP_ALL && (hasFeatureTasksAndMessages || listType == TYPE_CARE_SERVICE) && (
                        <VehicleListTh
                          text={t('headingUnreadMessageCount')}
                          fieldName='unread_message_count'
                          orderingSymbol={this.getOrderingSymbol('unread_message_count')}
                          changeOrdering={() => {
                            this.changeOrdering('unread_message_count');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('attached_vehicles') && listType != TYPE_WORKSHOP_ALL && (
                        <VehicleListTh
                          text={t('headingAttachedVehicles')}
                          fieldName='attached_vehicles'
                          orderingSymbol={this.getOrderingSymbol('attached_vehicles')}
                          changeOrdering={() => {
                            this.changeOrdering('attached_vehicles');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('last_maintenance_date') && (
                        <VehicleListTh
                          text={t('headingLastMaintenanceDate')}
                          fieldName='last_maintenance_date'
                          orderingSymbol={this.getOrderingSymbol('last_maintenance_date')}
                          changeOrdering={() => {
                            this.changeOrdering('last_maintenance_date');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('maintenance_count') && (
                        <VehicleListTh
                          text={t('headingMaintenanceCount')}
                          fieldName='maintenance_count'
                          orderingSymbol={this.getOrderingSymbol('maintenance_count')}
                          changeOrdering={() => {
                            this.changeOrdering('maintenance_count');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('inspection_interval_end') && (
                        <VehicleListTh
                          text={t('headingNextInspectionDate')}
                          fieldName='inspection_interval_end'
                          orderingSymbol={this.getOrderingSymbol('inspection_interval_end')}
                          changeOrdering={() => {
                            this.changeOrdering('inspection_interval_end');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('next_check_date') && listType != TYPE_WORKSHOP_ALL && (
                        <VehicleListTh
                          text={t('headingNextCheckDate')}
                          fieldName='next_check_date'
                          orderingSymbol={this.getOrderingSymbol('next_check_date')}
                          changeOrdering={() => {
                            this.changeOrdering('next_check_date');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('get_sale_report') && listType == TYPE_ORGANIZATION_GARAGE && hasFeatureVehicleReports && (
                        <VehicleListTh
                          text={t('headingSaleReport')}
                          fieldName='get_sale_report'
                          orderingSymbol={this.getOrderingSymbol('get_sale_report')}
                          changeOrdering={() => {
                            this.changeOrdering('get_sale_report');
                          }}
                          notSortable
                        />
                      )
                    }

                    {
                      selectedFields.includes('traficom_sync_date') && (
                        <VehicleListTh
                          text={t('headingTraficomSyncDate')}
                          fieldName='traficom_sync_date'
                          orderingSymbol={this.getOrderingSymbol('traficom_sync_date')}
                          changeOrdering={() => {
                            this.changeOrdering('traficom_sync_date');
                          }}
                        />
                      )
                    }

                    <th style={getStyle(screenSize, styles, 'tableHeaderSettings')}>
                      <MultiSelect
                        value={selectedFields}
                        onChange={(e) => this.setSelectedFields(e.value)}
                        options={fields}
                        className="bars"
                        dropdownIcon={undefined}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vehicles.map(vehicle => (
                    <VehicleListItem
                      selectedFields={selectedFields}
                      vehicle={vehicle}
                      key={vehicle.id}
                      garage={garage}
                      selected={selectedVehicles.includes(vehicle.id)}
                      removeVehicleFromGarage={removeVehicleFromGarage}
                      select={this.selectVehicle}
                      unselect={this.unselectVehicle}
                      endCareService={this.endCareService}
                      service={service}
                      refetchVehicles={this.refetchVehicles}
                      listType={listType}
                      hasFeatureVehicleReports={hasFeatureVehicleReports}
                      hasFeatureVehicleReportsPdf={hasFeatureVehicleReportsPdf}
                      hasFeatureTasksAndMessages={hasFeatureTasksAndMessages}
                      useVehicleIdentifier={this.getCurrentCompany().use_vehicle_identifier}
                    />
                  ))}
                </tbody>
              </table>
              {
                usePagination && pagination && (
                  <Paginator
                    first={(pagination.page_size * pagination.current_page - 1)}
                    rows={pagination.page_size}
                    totalRecords={pagination.count}
                    onPageChange={this.changePage}
                  />
                )
              }
            </div>
            {modalIsOpen && (
              <AddCarModal
                modalIsOpen={modalIsOpen}
                closeModal={this.closeModal}
                currentOrganization={currentOrganization}
                afterOpenModal={this.afterOpenModal}
                garages={listType != TYPE_CARE_SERVICE && garages.filter((gar) => gar.organization_id == selectedOrganizationId)}
                isOrganization
                organization={currentOrganization}
                user={user}
                listType={listType}
              />
            )}
            {addWSVehicleModalIsOpen && (
              <AddEditVehicleModal
                modalIsOpen={addWSVehicleModalIsOpen}
                closeModal={() => {
                  this.setState({addWSVehicleModalIsOpen: false});
                  this.refetchVehicles();
                }}
                afterOpenModal={this.afterOpenModal}
                workshopID={selectedWorkshopId}
              />
            )}
            {updateModalIsOpen && (
              <UpdateVehiclesModal
                modalIsOpen={updateModalIsOpen}
                closeModal={this.closeUpdateModal}
                afterOpenModal={this.afterOpenModal}
                garage={garage}
                selectedVehicles={this.getSelectedVehicles()}
                listType={listType}
              />
            )}
            {messageModalIsOpen && (
              <AddMessageModal
                modalIsOpen={messageModalIsOpen}
                closeModal={this.closeMessageModal}
                selectedVehicles={this.getSelectedVehicles()}
                vehicles={vehicles}
              />
            )}
            {deleteModalIsOpen && (
              <CBPromptModal
                modalIsOpen={deleteModalIsOpen}
                closeModal={this.closeDeletePromptModal}
                afterOpenModal={() => {}}
                title={t('deleteManyFromGaragePromptTitle')}
                text={`${t('deleteManyFromGaragePromptText')} ${selectedRegistrationNumbers}`}
                buttonText={t('deleteButton')}
                performAction={() => {
                  removeVehicleFromGarage(undefined, garage.id, selectedVehicles);
                  this.closeDeletePromptModal();
                  this.unselectAllVehicles();
                }}
              />
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      width: '100%',
      padding: '0px 16px 0px 0px',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    searchContainer: {
      maxWidth: 600,
    },
    tableContaienr: {
      width: '100%',
    },
    table: {
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      tableLayout: 'fixed',
      marginBottom: '24px',
      paddingBottom: 16,
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Bold',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      padding: '8px 8px 8px 8px',
    },
    tableHeaderSettings: {
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      //position: 'sticky',
      padding: '8px 8px 8px 8px',
      right: 0,
    },
    tableHeaderFirst: {
      //position: 'sticky',
      left: 0,
    },
    alignCenter: {
      textAlign: 'center',
    },
    topBarsContainer: {
      marginTop: 32,
      paddingLeft: 16,
    },
  },
  medium: {
    table: {
    },
  },
  small: {
    table: {
    },
  },
};

function mapStateToProps(state) {
  return {
    service: state.app.service,
    user: state.authUser.user,
    garages: state.garage.garages,
    garageVehiclesLoading: state.vehicle.garageVehicleStatus.loading,
    careServiceVehiclesLoading: state.vehicle.careServiceVehicleStatus.loading,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    checkLists: state.checkList.checkLists,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehiclesByOrganization: _fetchVehiclesByOrganization,
    fetchGarages: _fetchGarages,
    removeVehicleFromGarage: _removeVehicleFromGarage,
    removeVehicleFromCareServiceFleet: _removeVehicleFromCareServiceFleet,
    removeVehicleFromCareServiceWorkshop: _removeVehicleFromCareServiceWorkshop,
  },
)(translate('VehicleListView')(VehicleListView));
