import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSearchInput from '../../shared/CBSearchInput';
import CBSelectFilters from '../../shared/CBSelectFilters';
import AddMessageButton from '../../VehicleDetailsView/VehicleDetailsComponents/ListItems/AddMessageButton';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';


class MessageFilterBar extends Component {
  render() {
    const {
      t,
      changeSearchTerms,
      changeFilterTerms,
      refetchMessages,
      sendMessages,
      useIdentifiers,
      filters
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'containerFilters')}>
              <div style={getStyle(screenSize, styles, 'containerSearch')}>
                <CBSearchInput
                  containerStyle={{ height: 40, width: 240, marginLeft: 0 }}
                  changeSearchTerms={changeSearchTerms}
                  onBlur={refetchMessages}
                />
                {
                  useIdentifiers && (
                    <CBLabelAndCheckbox
                      labelText={'Hae vain tunnisteista'}
                      checked={filters.search_identifiers}
                      onChange={
                        () => {
                          const newFilters = {...filters};
                          if (!filters.search_identifiers) {
                            return changeFilterTerms({...newFilters, search_identifiers: true })
                          } else {
                            delete newFilters.search_identifiers;
                            return changeFilterTerms({...newFilters})
                          }
                        }
                      }
                    />
                  )
                }
              </div>
            </div>
            <div style={getStyle(screenSize, styles, 'containerActions')}>
              <CBButtonGarageAction
                  onClick={sendMessages}
                  mainIcon='/img/icon_message_solid.svg'
                  additionalIcon='/img/icon_plus.svg'
                  text={t('headingNewMessage')}
                  additionalStylesMainIcon={{ height: 25, width: 25 }}
                />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    containerSearch: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    containerFilters: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    containerActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('MessageListView')(MessageFilterBar));
