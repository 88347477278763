import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import { withRouter } from 'react-router';
import NavbarButton from '../../shared/NavbarButton';
import { Route } from 'react-router-dom';
import DataImportMileage from './DataImportMileage';

class DataImport extends React.Component {
  state = {
    activeTab: 'Mileage',
  };

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      console.log('org changed')
    }
  }

  render() {
    const {
      t,
      service,
      selectedWorkshopId,
      user,
      selectedOrganizationId,
    } = this.props;

    const { activeTab } = this.state;

    const isStaff = user.is_staff;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            {
              false && (
                <div>
                  <div style={getStyle(screenSize, styles, 'sectionContainer')}>
                    <CBSubHeading>
                      {t('dataImportHeading')}
                    </CBSubHeading>
                  </div>
                  <div style={getStyle(screenSize, styles, 'navContainer')}>
                    <NavbarButton
                        to={`/settings/data-import/mileage/`}
                        iconSrc='/img/icon_vehicle_report.svg'
                        alt='Mileage'
                        buttonText={'Ajokilometrit'}
                        setActiveTab={tabName => this.setState({ activeTab: tabName })}
                        activeTab={'Mileage'} />
                  </div>
                </div>
              )
            }

            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
                <Route exact path={['/settings/data-import/mileage/', '/settings/data-import/', '/settings/data-import/mileage/:importRunId/']} component={DataImportMileage} />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    sectionContainer: {
      marginBottom: 16,
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 16,
        padding: 8,
        backgroundColor: 'transparent',
        border: '2px solid #e0e0e050',
        borderRadius: 8,
    }
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {

  },
)(withRouter(translate('SettingsView')(DataImport)));






