import moment from 'moment';

export const getStyle = (screenSize, styles, name) => {
  switch (screenSize) {
    case 'large':
      return styles.default[name];
    case 'medium':
      return {
        ...styles.default[name],
        ...(styles.medium && styles.medium[name]),
      };
    case 'small':
      return {
        ...styles.default[name],
        ...(styles.medium && styles.medium[name]),
        ...(styles.small && styles.small[name]),
      };
    default:
      return styles.default[name];
  }
};

export const getModalStyle = (screenSize, styles) => {
  switch (screenSize) {
    case 'large':
      return styles.default;
    case 'medium':
      return {
        content: {
          ...styles.default.content,
          ...(styles.medium && styles.medium.content),
        },
        overlay: {
          ...styles.default.overlay,
          ...(styles.medium && styles.medium.overlay),
        },
      };
    case 'small':
      return {
        content: {
          ...styles.default.content,
          ...(styles.small && styles.small.content),
        },
        overlay: {
          ...styles.default.overlay,
          ...(styles.small && styles.small.overlay),
        },
      };
    default:
      return styles.default;
  }
};

export const getUrlId = url => {
  const indexOfSlash = url.lastIndexOf('/');
  const lastSection = url.substring(indexOfSlash + 1);
  if (isNaN(lastSection) || !lastSection) {
    return undefined;
  }
  return Number(lastSection);
};

export const styleMerge = (paramObj1, paramObj2) => ({ ...paramObj1, ...paramObj2 });

export const storeInsertItem = (array, item) => {
  const newArray = array.slice();
  newArray.splice(0, 0, item);
  return newArray;
};

export const storeInsertItems = (array, itemArray) => {
  const newArray = array.slice();
  itemArray.forEach((item) => {
    newArray.splice(0, 0, item);
  });
  return newArray;
};

export const storeRemoveItem = (array, id) => {
  if (!id) {
    console.error('storeRemove didn\'t receive the id of the object to remove');
    return array;
  }
  const newArray = array.slice();
  const index = array.findIndex(x => x.id == id);
  newArray.splice(index, 1);
  return newArray;
};

export const storeRemoveItems = (array, idList) => {
  if (!idList) {
    console.error('storeRemove didn\'t receive the id of the object to remove');
    return array;
  }
  const newArray = array.slice();

  for (const id in idList) {
    const index = array.findIndex(x => x.id == id);
    newArray.splice(index, 1);
  }

  return newArray;
};

export const storeUpdateObjectInArray = (array, responseItem) =>
  array.map(item => {
    if (item.id !== responseItem.id) {
      return item;
    }
    return {
      ...item,
      ...responseItem,
    };
  });

export const storeUpdateObjectsInArray = (array, responseItems) => {
  const updatesMap = new Map(responseItems.map(item => [item.id, item]));

  return array.map(item =>
    updatesMap.has(item.id) ? { ...item, ...updatesMap.get(item.id) } : item
  );
};



export const mergeObjectList = (oldItems, responseItems) => {
  const ids = new Set(oldItems.map(d => d.id));
  return [...oldItems, ...responseItems.filter(d => !ids.has(d.id))];
};

export const dateCompareFunction = (a, b) => (moment(a).isBefore(b) ? 1 : -1);

export const getTreatmentTypeTranslationKey = treatmentType => {
  let translationKey;
  if (treatmentType === 'workshop_maintenance') {
    translationKey = 'treatmentTypeWorkshopMaintenance';
  } else if (treatmentType === 'maintenance') {
    translationKey = 'treatmentTypeMaintenance';
  } else if (treatmentType === 'treatment') {
    translationKey = 'treatmentTypeTreatment';
  } else if (treatmentType === 'repair') {
    translationKey = 'treatmentTypeRepair';
  } else if (treatmentType === 'wash') {
    translationKey = 'treatmentTypeWash';
  }
  return translationKey;
};

export const toIsoDateString = (input) => {
  let m;

  // If it's already a Moment instance:
  if (moment.isMoment(input)) {
    m = input;

  // If it's a native Date object:
  } else if (input instanceof Date) {
    m = moment(input);

  // If it's a string (e.g. "2023-03-07T14:30:00Z"):
  } else if (typeof input === "string") {
    // Use moment.utc(...) if you prefer to interpret the string in UTC:
    // m = moment.utc(input);
    m = moment(input);

  } else {
    throw new Error("Invalid date input");
  }

  // Optionally handle invalid moments (invalid date strings, etc.)
  if (!m.isValid()) {
    throw new Error("Could not parse date input");
  }

  // Return just the date portion in ISO format
  return m.format("YYYY-MM-DD");
}

export const formatDate = (dateString) => {
  if (dateString === null || dateString === '') return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('fi-FI');
};

export const formatDateTime = (dateTimeString) => {
  if (dateTimeString === null || dateTimeString === '') return '';
  return moment(dateTimeString).format('D.M.YYYY HH:mm');
};

export const toDbDateTime = (dateTime) => {
  return dateTime ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') : null;
};

export const formatKm = (km) => {
  let kmFormatted = Math.round(km);
  kmFormatted = kmFormatted.toLocaleString('fi-FI');
  kmFormatted = kmFormatted + ' km';
  return kmFormatted;
};

export const getCsrfToken = () => {
  const name = 'csrftoken=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const formatPrice = (price) => {
  return price.toLocaleString('fi-FI').replace('.', ',') + ' €';
};

export const dbDateTimeFormat = 'YYYY-MM-DD HH:mm';
export const dbDateFormat = 'YYYY-MM-DD';

export const finnishDatetimeFormat = 'dd.MM.yyyy HH:mm';
export const finnishDateFormat = 'dd.MM.yyyy';


export const SERVICE_WORKSHOP = 'workshop';
export const SERVICE_PERSONAL = 'my';
export const SERVICE_FLEET = 'fleet';
export const SERVICE_ADMIN = 'admin';

export const TYPE_CARE_SERVICE = 'care_service';
export const TYPE_ORGANIZATION_GARAGE = 'organization_garage';
export const TYPE_WORKSHOP_ALL = 'workshop_all';

export const INVITATION_WORKSHOP = 'workshop'
export const INVITATION_ORGANIZATION = 'organization'
export const INVITATION_GARAGE = 'garage'
export const INVITATION_VEHICLE = 'vehicle'

export const FLEET_INVITATION_TYPES = [INVITATION_ORGANIZATION, INVITATION_GARAGE, INVITATION_VEHICLE]

export const BLEEDING_EDGE_USERS = [
  'i.petajajarvi@gmail.com',
  'jari.puranen@icloud.com',
  'jari@kasvupartneri.fi',
  'jari.puranen@ovio.fi',
  'jari.puranen@tavata.events',
  'marko.msc@gmail.com',
  'info@asnoy.fi',
  'marko.paananen@ovio.fi',
  'tommi.mantila@gmail.com',
  'ismo.petajajarvi@ovio.fi',
  'tommi@manbytes.fi',
  'cale82@gmail.com',
  'joonas.soilu@biltec.fi'
];