import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SubHeaderBar from '../shared/SubHeaderBar';
import UserSettingsProfile from './SettingsViewComponents/UserSettingsProfile';
import UserSettingsMenu from './SettingsViewComponents/UserSettingsMenu';
import UserSettingsCars from './SettingsViewComponents/UserSettingsCars';
import UserSettingsWorkshops from './SettingsViewComponents/UserSettingsWorkshops';
import StaffSettingsWorkshops from './SettingsViewComponents/StaffSettingsWorkshops';
import UserSettingsReminders from './SettingsViewComponents/UserSettingsReminders';
import CBMediaQuery from '../shared/CBMediaQuery';
import { getStyle } from '../../utils/utils';
import TermsOfServiceView from './SettingsViewComponents/TermsOfServiceView';
import StaffSettingsUsers from './SettingsViewComponents/StaffSettingsUsers';
import StaffSettingsOrganizations from './SettingsViewComponents/StaffSettingsOrganizations';
import StaffSettingsGarages from './SettingsViewComponents/StaffSettingsGarages';
import SettingsGaragePermissions from './SettingsViewComponents/SettingsGaragePermissions';
import SettingsOrganizationVehiclePermissions from './SettingsViewComponents/SettingsOrganizationVehiclePermissions';
import StaffStatsView from '../StaffStatsView/StaffStatsView';
import AdvertisementEditorView from '../AdvertisementsView/AdvertisementEditorView';
import AdvertisementsView from '../AdvertisementsView/AdvertisementsView';
import SettingsCareServices from './SettingsViewComponents/SettingsCareServices';
import SettingsWorkshopPermissions from './SettingsViewComponents/SettingsWorkshopPermissions';
import SettingsOrganizationPermissions from './SettingsViewComponents/SettingsOrganizationPermissions';
import SettingsOrganization from './SettingsViewComponents/SettingsOrganization';
import SettingsCheckLists from './SettingsViewComponents/SettingsCheckLists';
import SettingsOrganizationVehicleReminders from './SettingsViewComponents/SettingsOrganizationVehicleReminders';
import StaffSettingsPricing from './SettingsViewComponents/StaffSettingsPricing';
import StaffSettingsInvoiceReports from './SettingsViewComponents/StaffSettingsInvoiceReports';
import DataImport from './SettingsViewComponents/DataImport';
import PredictedMaintenanceSettings from './SettingsViewComponents/PredictedMaintenanceSettings';
import TaskSettings from './SettingsViewComponents/TaskSettings';

class UserSettingsView extends React.Component {
  componentWillMount() {
    document.documentElement.classList.add('settings-bg');
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('settings-bg');
  }

  render() {
    const { history, t, user, staff, service, selectedOrganizationId } = this.props;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'settingsViewContainer')}>
            <div style={getStyle(screenSize, styles, 'settingsViewInnerContainer')}>
              <div style={getStyle(screenSize, styles, 'headerContainer')}>
                <SubHeaderBar
                  goBack={history.goBack}
                  label={t('settingsHeading')}
                  rightBlock={<React.Fragment />}
                  height='unset'
                />
                {
                  screenSize !== 'large' && (
                    <React.Fragment>
                      <UserSettingsMenu user={user} staff={staff} service={service} selectedOrganizationId={selectedOrganizationId} />
                    </React.Fragment>
                  )
                }
              </div>

              <div style={getStyle(screenSize, styles, 'menuAndContentContainer')}>
                <div style={getStyle(screenSize, styles, 'menuContainer')}>
                  {screenSize === 'large' && <UserSettingsMenu user={user} staff={staff} service={service} selectedOrganizationId={selectedOrganizationId} />}
                </div>
                <div style={getStyle(screenSize, styles, 'contentContainer')}>
                  <Route exact path='/settings/' component={UserSettingsProfile} />
                  <Route exact path='/settings/profile' component={UserSettingsProfile} />
                  <Route exact path='/settings/cars' component={UserSettingsCars} />
                  <Route exact path='/settings/workshops' component={UserSettingsWorkshops} />
                  <Route exact path='/settings/reminders' component={UserSettingsReminders} />
                  <Route exact path='/settings/organizations' component={SettingsOrganization} />
                  <Route exact path='/settings/garage-permissions' component={SettingsGaragePermissions} />
                  <Route exact path='/settings/organization-permissions' component={SettingsOrganizationPermissions} />
                  <Route exact path='/settings/organization-vehicle-permissions' component={SettingsOrganizationVehiclePermissions} />
                  <Route exact path='/settings/care-services' component={SettingsCareServices} />
                  <Route exact path='/settings/check-lists' component={SettingsCheckLists} />
                  <Route exact path='/settings/org-vehicle-reminders' component={SettingsOrganizationVehicleReminders} />
                  <Route path='/settings/data-import' component={DataImport} />
                  <Route path='/settings/predicted-maintenance-settings' component={PredictedMaintenanceSettings} />
                  <Route exact path='/settings/task-settings' component={TaskSettings} />
                  {/* <Route exact path='/settings/instructions' component={InstructionsView} /> */}
                  <Route exact path='/staff_settings/staff_workshops' component={StaffSettingsWorkshops} />
                  <Route exact path='/staff_settings/users' component={StaffSettingsUsers} />
                  <Route exact path='/staff_settings/organizations' component={StaffSettingsOrganizations} />
                  <Route exact path='/staff_settings/garages' component={StaffSettingsGarages} />
                  <Route exact path='/staff_settings/garage-permissions' component={SettingsGaragePermissions} />
                  <Route exact path='/staff_settings/organization-permissions' component={SettingsOrganizationPermissions} />
                  <Route exact path='/staff_settings/organization-vehicle-permissions' component={SettingsOrganizationVehiclePermissions} />
                  <Route exact path='/staff_settings/workshop-permissions' component={SettingsWorkshopPermissions} />
                  <Route exact path='/staff_settings/care-services' component={SettingsCareServices} />
                  <Route exact path='/staff_settings/staff_stats' component={StaffStatsView} />
                  <Route exact path='/staff_settings/check-lists' component={SettingsCheckLists} />
                  <Route exact path='/staff_settings/org-vehicle-reminders' component={SettingsOrganizationVehicleReminders} />
                  <Route path='/staff_settings/advertisements/:advertId' component={AdvertisementEditorView} />
                  <Route exact path='/staff_settings/advertisements' component={AdvertisementsView} />
                  <Route exact path='/staff_settings/pricing' component={StaffSettingsPricing} />
                  <Route exact path='/staff_settings/invoice-reports' component={StaffSettingsInvoiceReports} />
                  <Route
                    exact
                    path='/settings/terms-and-conditions'
                    component={TermsOfServiceView}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    settingsViewContainer: {
      backgroundColor: '#0095da',
      position: 'absolute',
      left: 0,
      top: 50,
      bottom: 0,
      right: 0,
      paddingLeft: 175,
      paddingRight: 175,
      height: '100%',
      width: '100%',
    },
    settingsViewInnerContainer: {
      backgroundColor: '#0095da',
      position: 'relative',
      height: '100%',
      width: '100%',
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    },
    menuAndContentContainer: {
      backgroundColor: '#0095da',
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid rgba(255, 255, 255, 0.4)',
    },
    menuContainer: {
      backgroundColor: '#0095da',
      borderRight: '1px solid rgba(255, 255, 255, 0.4)',
      width: '20%',
    },
    contentContainer: {
      backgroundColor: '#0095da',
      width: '80%',
    },
  },
  medium: {
    settingsViewContainer: {
      backgroundColor: '#0095da',
      position: 'absolute',
      left: 0,
      top: 50,
      bottom: 0,
      right: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    menuAndContentContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    menuContainer: {
      borderRight: 0,
      width: '100%',
    },
    contentContainer: {
      width: '100%',
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(mapStateToProps)(translate('SettingsView')(UserSettingsView));
