import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle, finnishDateFormat, formatDate, toIsoDateString } from '../../../../utils/utils';
import {
  updateVehicle as _updateVehicle,
  updateOrganizationVehicle as _updateOrganizationVehicle,
} from '../../../../actions/vehicleActions';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import CBButtonSubmit from '../../../shared/CBButtonSubmit';
import CBInputField from '../../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../../utils/validators';
import CBModal from '../../../shared/CBModal';
import CBDatePickerInput from '../../../shared/CBDatePickerInput';
import moment from 'moment';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';

Modal.setAppElement('#root');

class SetLastPeriodicMaintenanceModal extends Component {
  state = {
    formData: {
      last_periodic_maintenance: {}
    },
  };

  componentDidMount = () => {
    const { vehicle, maintenance } = this.props;
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);

    newFormData.last_periodic_maintenance.date = toIsoDateString(maintenance.pickup_date);
    newFormData.last_periodic_maintenance.km = parseInt(maintenance.km);
    newFormData.last_periodic_maintenance.maintenance = maintenance.id;

    this.setState({
      formData: newFormData
    });
  };


  onSubmit = () => {
    const {
      vehicle,
      updateOrganizationVehicle,
      selectedOrganizationId,
      selectedWorkshopId,
      closeModal
    } = this.props;
    const { formData } = this.state;

    const copyFormData = Object.assign({}, formData);

    updateOrganizationVehicle(vehicle.id, copyFormData, vehicle.organization_id, selectedWorkshopId || null);
    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t, isOrganizationVehicle
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={t('setLastPeriodicMaintenanceTitle')}
            cancelText={t('cancel')}
            submitText={t('save')}
            submitForm={this.onSubmit}
            isValid={true}
            >
              <React.Fragment>
                <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                  <p style={getStyle(screenSize, styles, 'infoText')}>
                    {t('setLastPeriodicMaintenanceInfo')}
                  </p>
                  <VehicleDetailsLabelAndValue black label={t('addMaintenanceDateLabel')} value={formatDate(formData.last_periodic_maintenance.date)} />
                  <VehicleDetailsLabelAndValue black label={t('addMaintenanceKmLabel')} value={formData.last_periodic_maintenance.km} />
                </div>
              </React.Fragment>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    dateInputLabel: {
      color: 'black',
      textAlign: 'center',
      paddingBottom: 6,
    },
    infoText: {
      color: 'black',
      fontSize: 14,
      marginBottom: 16,
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    subHeading: {
      color: 'black',
      textAlign: 'center',
      fontSize: 18,
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    selectedOrganizationId: state.organization.selectedOrganization,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    updateOrganizationVehicle: _updateOrganizationVehicle,
  },
)(translate('VehicleDetailsView')(SetLastPeriodicMaintenanceModal));
