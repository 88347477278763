import React, { createRef } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  deleteVehicleDocument as _deleteVehicleDocument,
} from '../../../actions/vehicleActions';
import { formatDate, getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSpinner from '../../shared/CBSpinner';
import TextWithLinebreaks from '../../shared/TextWithLinebreaks';
import DocumentEditRow from './DocumentEditRow';

import CBPromptModal from '../../shared/CBPromptModal';
import AddDocumentModal from './Modal/AddDocumentModal';
import InfoboxWithIcon from './ListItems/InfoboxWithIcon';


class VehicleDetailsDocumentContent extends React.Component {
  state = {
    deleteModalIsOpen: false,
    modalIsOpen: false,
  }

  constructor(props) {
    super(props);
  }

  deleteVehicleDocument = () => {
    const { deleteVehicleDocument, match, vehicle, service, selectedWorkshopId } = this.props;
    this.setState({
      deleteModalIsOpen: false,
    });
    let documentId = null;
    if (match) {
      documentId = match.params.documentId;
    } else if (vehicle && vehicle.documents.length) {
      documentId = vehicle.documents[0].id
    }
    if (service === SERVICE_WORKSHOP) {
      deleteVehicleDocument(documentId, selectedWorkshopId);
    } else {
      deleteVehicleDocument(documentId);
    }
  };


  getDocument = () => {
    const { vehicle, match } = this.props;
    const documents = vehicle ? vehicle.documents : [];
    let document = match
      ? documents.find(x => x.id == match.params.documentId)
      : undefined;
    if (!document && vehicle && documents.length) {
      document = documents[0];
    }
    return document;
  }

  openDeleteDocumentModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenDeleteModal = () => {
  };

  editDocument = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  downloadDocument = () => {
    const document = this.getDocument();
    window.open(document.file, '_blank');
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  getPriorityText = (priority) => {
    const {
      t,
    } = this.props;

    switch(priority) {
      case 'high':
        return t('documentPriorityHigh');
      case 'medium':
        return t('documentPriorityMedium');
      case 'low':
        return t('documentPriorityLow');
      default:
        return '';
    }
  };

  render() {
    const {
      vehicleRequest, t, match, vehicle, service
    } = this.props;

    const {
      modalIsOpen,
      deleteModalIsOpen,
      imageIndex,
      imageModalIsOpen,
      statusModalIsOpen
    } = this.state;

    const document = this.getDocument();

    if (vehicleRequest.documentStatus.loading) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'documentContentContainer')}>
              <div>
                <CBSpinner />
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else if (vehicleRequest.documentStatus.error) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'documentContentContainer')}>
              <div
                className='font-titillium-web-extra-light'
                style={getStyle(screenSize, styles, 'sectionHeading')}
              >
                <div style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                  Ajokilometrikirjausta ei löydy.
                </div>
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'documentContentContainer')}>
              {document && (
                <React.Fragment>
                  <div>
                    <div style={getStyle(screenSize, styles, 'topInfoContainer')}>
                      <InfoboxWithIcon
                        icon='/img/icon_file.svg'
                        text={document.file_name}
                        labelText={t('documentFileName')}
                        href={document.file}
                      />
                      <InfoboxWithIcon
                        icon='/img/icon_calendar.svg'
                        text={formatDate(document.created_at)}
                        labelText={t('documentCreatedAt')}
                        hasBorders
                      />
                      <InfoboxWithIcon
                        icon='/img/icon_permission.svg'
                        text={t(document.minimum_role, { ns: 'Permissions'})}
                        labelText={t('documentPermission')}
                        hasBorders
                      />
                      <InfoboxWithIcon
                        icon='/img/icon_care_service_white.svg'
                        text={document.allow_care_service_access ? t('yes', { ns: 'SettingsView' }) : t('no', { ns: 'SettingsView' })}
                        labelText={service === SERVICE_FLEET ? t('workshopHasPermission') : t('fleetHasPermission')}
                      />
                    </div>
                    <div style={getStyle(screenSize, styles, 'mainContentContainer')}>
                      <div
                        className='font-titillium-web-extra-light'
                        style={getStyle(screenSize, styles, 'sectionHeading')}
                      >
                        <div style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                          {document.title}
                        </div>

                        <div style={getStyle(screenSize, styles, 'additionalDocumentInfo')}>
                          <div style={getStyle(screenSize, styles, 'creatorContainer')}>
                            {`${t('taskUserLabel')}: ${document.user_display}`}
                          </div>
                          <div>
                            {`${t('taskCreatedLabel')}: ${moment(document.created_at).format('D.M.YYYY HH:mm')}`}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={getStyle(screenSize, styles, 'documentTextContainer')}>
                        {
                          document.description && (
                            <div>
                              <p style={getStyle(screenSize, styles, 'documentDescriptionText')}>
                                {document.description}
                              </p>
                            </div>
                          )
                        }
                        </div>
                        <DocumentEditRow
                          document={document}
                          openDeleteDocumentModal={this.openDeleteDocumentModal}
                          editDocument={this.editDocument}
                          downloadDocument={this.downloadDocument}
                          openStatusModal={this.openStatusModal}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    modalIsOpen && (
                      <AddDocumentModal
                        modalIsOpen={modalIsOpen}
                        closeModal={this.closeModal}
                        afterOpenModal={this.afterOpenModal}
                        document={document}
                        vehicle={vehicle}
                      />
                    )
                  }
                  <CBPromptModal
                    modalIsOpen={deleteModalIsOpen}
                    closeModal={this.closeDeleteModal}
                    afterOpenModal={this.afterOpenDeleteModal}
                    title={t('deleteDocumentPromptTitle')}
                    text={t('deleteDocumentPromptText')}
                    buttonText={t('deleteDocumentPromptButton')}
                    performAction={this.deleteVehicleDocument}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    documentNameText: {
      fontSize: 32,
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 32,
    },
    squareImage: {
      width: 100,
      height: 100,
      margin: '0 10px 10px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    galleryContainer: {
      marginBottom: 16,
    },
    responseButtonContainer: {
      width: 200,
      marginBottom: 32,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    documentHeadingText: {
      fontSize: 18,
    },
    documentDescriptionText: {
      fontSize: 14,
    },
    galleryThumbnailsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    thumbnail: {
      maxWidth: 120,
      cursor: 'pointer',
    },
    thumbnailContainer: {
      marginRight: 8,
    },
    responseContainer: {
      marginTop: 32,
    },
    sectionHeadingText: {
      fontSize: 24,
    },
    responseHeading: {
      fontSize: 20,
      color: 'white',
    },
    documentTextContainer: {
      marginBottom: 16,
      minHeight: 100,
    },
    sectionHeading: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'white',
      flexWrap: 'wrap',
      marginBottom: 16,
      marginTop: 16,
    },
    additionalDocumentInfo: {
      display: 'flex',
      fontSize: 12,
      color: 'white',
    },
    treatmentDescriptionText: {
      fontSize: 14,
      minWidth: '100%',
      width: 0,
      overflowWrap: 'break-word',
    },
    creatorContainer: {
      marginRight: 16,
    },
    documentContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginLeft: -16,
      marginRight: -16,
    },
  },
  small: {
    documentContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'auto',
      minHeight: 500,
      width: '100%',
      padding: 16,
    },
    mainContentContainer: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
      margin: -16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    searchRequest: state.search,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    deleteVehicleDocument: _deleteVehicleDocument,
  },
)(translate('VehicleDetailsView')(VehicleDetailsDocumentContent));
