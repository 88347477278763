import React, { Component } from 'react';

class CBSelectField extends Component {
  constructor(props) {
    super(props);
    this.state = { isValid: true, value: props.value, errors: '' };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  parseServerErrorMessages = () => {
    const { name, serverErrors } = this.props;
    if (serverErrors !== null && typeof serverErrors === 'object' && serverErrors[name]) {
      let concattedServerErrors = '';
      serverErrors[name].forEach(msgPart => {
        concattedServerErrors += `${msgPart} `;
      });
      return concattedServerErrors;
    }
    return undefined;
  };

  handleChange = elem => {
    const value = elem.target.value;
    const { isValid } = this.state;
    const { name, type, onChange } = this.props;
    this.setState({ value });
    if (isValid) {
      onChange({
        target: name,
        value,
      });
    }
  };

  render() {
    const {
      labelStyle, labelTextStyle, labelText, inputStyle, optionStyle, options, containerStyle, disabled = false
    } = this.props;
    const { value, errors, placeholder } = this.state;
    return (
      <div style={containerStyle || styles.fieldContainer}>
        <div style={labelStyle || styles.label}>
          <p className='font-titillium-web-semi-bold' style={labelTextStyle || styles.labelText}>
            {labelText}
          </p>
        </div>
        <select
          value={value}
          onChange={this.handleChange}
          style={inputStyle || styles.input}
          placeholder={placeholder}
          disabled={disabled}
        >
          {options.map(option => (
            <option className='font-titillium-web' style={optionStyle || {}} key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

const styles = {
  fieldContainer: {
    display: 'flex',
    flexGrow: 'grow',
    width: '100%',
    // marginBottom: 16,
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    border: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    fontSize: 14,
    color: '#ffffff',
    backgroundColor: 'transparent',
  },
  labelStyle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20,
  },
  labelTextStyle: {},
  errorMessage: {
    fontSize: 10,
    color: 'red',
  },
};

export default CBSelectField;
