import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';
import { translate } from 'react-i18next';
import { formatDate, formatDateTime, styleMerge } from '../../../../utils/utils';
import CBLabelAndCheckbox from '../../../shared/CBLabelAndCheckbox';

class DetailsDocumentListItem extends React.Component {
  getPriorityStyle = (document) => {
    let color = '';
    switch (document.priority) {
      case 'low':
        color = '#4EBFEF';
        break;
      case 'medium':
        color = '#E6CA38';
        break;
      case 'high':
        color = '#FF3B3B';
        break;
      default:
        return {};
    }

    return {
      borderRight: `8px solid ${color}`,
    };
  };

  getContainerStyle = () => {
    const { document, isSelected } = this.props;

    let style = styleMerge(styles.rootContainer, this.getPriorityStyle(document))

    if (isSelected) {
      style = styleMerge(style, styles.selected);
    }

    return style;

  };

  render() {
    const { document, vehicle, t, isMultiSelected, selectDocument, isSelected } = this.props;

    return (
      <div style={this.getContainerStyle()}>
        {
          false && (// Multiselect disabled for now
            <CBLabelAndCheckbox additionalStyles={{ padding: 8 }} checked={isMultiSelected} onChange={() => selectDocument(document)} />
          )
        }
        <Link style={{width: '100%'}} to={`/vehicle/${vehicle.id}/documents/${document.id}`}>
          <div style={styles.listItemContainer}>
            <div style={styles.innerContainer}>

              <VehicleDetailsLabelAndValue
                label={formatDate(document.created_at)}
                value={document.title}
              />
              <VehicleDetailsLabelAndValue
                alignRight
                label={'‎'}
                value={document.file_name}
              />
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

const styles = {
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255,255,255,.3)',
  },
  listItemContainer: {
    padding: 10,
    width: '100%'
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selected: {
    borderLeft: '4px solid #27bbff',
  },
  grey: {
    opacity: '60%',
  },
};

export default translate('VehicleDetailsView')(DetailsDocumentListItem);
