import React, { useRef } from 'react';
import { translate } from 'react-i18next';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { formatDate, formatDateTime, getStyle, styleMerge } from '../../../utils/utils';
import OptionsButton from '../../GarageView/GarageViewComponents/OptionsComponents/OptionsButton';
import CBPromptModal from '../../shared/CBPromptModal';
import { withRouter } from 'react-router';
import CBCheckbox from '../../shared/form_components/CBCheckbox';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import VehicleListTd from '../../OrganizationGarageView/OrganizationGarageComponents/VehicleListTd';
import AddMessageModal from '../../VehicleDetailsView/VehicleDetailsComponents/AddMessageModal';
import AddMessageResponseModal from '../../VehicleDetailsView/VehicleDetailsComponents/AddMessageResponseModal';

class MessageListItem extends React.Component {
  state = {
    newMessageModalIsOpen: false,
    deleteModalIsOpen: false,
    responseModalIsOpen: false,
  }

  constructor(props) {
    super(props);
    this.settingsMenu = React.createRef();
  }

  componentDidMount() {
  }

  openNewMessageModal = () => {
    this.setState({
      newMessageModalIsOpen: true,
    });
  };

  closeNewMessageModal = () => {
    this.setState({
      newMessageModalIsOpen: false,
    });
  };

  closeDeletePromptModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  openResponseModal = () => {
    this.setState({
      responseModalIsOpen: true,
    });
  };

  closeResponseModal = () => {
    this.setState({
      responseModalIsOpen: false,
    });
  };

  deleteMessage = () => {
    const { message, deleteMessage } = this.props;

    deleteMessage(message.id);
  };

  checkBoxChanged = () => {
    const {
      selected,
      select,
      unselect,
      message,
    } = this.props;

    if (selected) {
      unselect(message.id);
    } else {
      select(message.id);
    }
  };

  getMessageOptions = (message) => {
    const { garage } = this.props;
    const options = [
      {
        label: 'Avaa',
        icon: 'pi pi-info',
        url: `/vehicle/${message.vehicle}/messages/${message.id}`,
      },
      {
        label: 'Vastaa',
        icon: 'pi pi-pencil',
        command: () => {
          this.openResponseModal();
        },
      },
    ];

    if (message.can_edit) {
      options.push({
        label: 'Poista',
        icon: 'pi pi-trash',
        command: () => {
          this.setState({
            deleteModalIsOpen: true,
          });
        },
      });
    }

    return options;
  };

  navigateToMessage = (messageId) => {
    const { history, message } = this.props;
    history.push(`/vehicle/${message.vehicle}/messages/${message.id}`);
  };

  render() {
    const {
      message,
      t,
      selectedFields,
      garage,
      removeMessageFromGarage,
      selected,
      vehicles,
      useVehicleIdentifier
    } = this.props;
    const { deleteModalIsOpen, newMessageModalIsOpen, responseModalIsOpen } = this.state;

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'messageRow')}>
              {
                /*
                <td style={getStyle(screenSize, styles, 'tableRegNumTd')}>
                  <CBLabelAndCheckbox
                    additionalStyles={{ justifyContent: 'center' }}
                    checked={selected}
                    onChange={this.checkBoxChanged}
                  />
                </td>
                */
              }

              {
                selectedFields.includes('vehicle_identifier') && useVehicleIdentifier && (
                  <VehicleListTd
                    onClick={this.navigateToTask}
                    textPrimary={message.vehicle_identifier}
                    additionalStylesPrimaryText={{ fontSize: 24 }}
                  />
                )
              }

              <VehicleListTd
                onClick={this.navigateToMessage}
                textPrimary={message.vehicle_registration_number}
                additionalStylesPrimaryText={{ fontSize: 24 }}
              />

              {
                selectedFields.includes('vehicle_model') && (
                  <VehicleListTd
                    onClick={this.navigateToMessage}
                    textPrimary={message.vehicle_model}
                  />
                )
              }
              {
                selectedFields.includes('subject') && (
                  <VehicleListTd
                    onClick={this.navigateToMessage}
                    textPrimary={message.subject}
                  />
                )
              }
              {
                selectedFields.includes('user_display') && (
                  <VehicleListTd
                    onClick={this.navigateToMessage}
                    textPrimary={message.user_display}
                  />
                )
              }
              {
                selectedFields.includes('created_at') && (
                  <VehicleListTd
                    onClick={this.navigateToMessage}
                    textPrimary={formatDateTime(message.created_at)}
                  />
                )
              }
              {
                selectedFields.includes('task') && (
                  <VehicleListTd
                    additionalStyles={getStyle(screenSize, styles, 'alignCenter')}
                  />
                )
              }
              {
                selectedFields.includes('has_response') && (
                  <VehicleListTd
                    onClick={this.openResponseModal}
                    icon={message.responses.length ? '/img/icon_reply.svg' : '/img/icon_no_reply.svg'}
                    additionalStyles={getStyle(screenSize, styles, 'alignCenter')}
                  />
                )
              }
              {
                selectedFields.includes('is_read') && (
                  <VehicleListTd
                    onClick={this.navigateToMessage}
                    icon={message.is_read ? '/img/icon_is_read.svg' : undefined}
                    additionalStyles={getStyle(screenSize, styles, 'alignCenter')}
                  />
                )
              }
              {
                selectedFields.includes('new_message') && (
                  <VehicleListTd
                    onClick={this.openNewMessageModal}
                    icon='/img/icon_new_message.svg'
                    additionalStyles={getStyle(screenSize, styles, 'alignCenter')}
                  />
                )
              }
              <td style={getStyle(screenSize, styles, 'tableOptionsTd')}>
                <Menu model={this.getMessageOptions(message)} popup ref={this.settingsMenu} />
                <div
                  style={getStyle(screenSize, styles, 'container')}
                  onClick={(e) => {this.settingsMenu.current.toggle(e)}}
                >
                  <img
                    alt='Options'
                    className='opacity-and-border-on-hover'
                    style={getStyle(screenSize, styles, 'optionsIcon')}
                    src='/img/icon_options.svg'
                  />
                </div>
              </td>
            </tr>
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={this.closeDeleteModal}
              afterOpenModal={this.afterOpenDeleteModal}
              title={t('deleteMessagePromptTitle')}
              text={t('deleteMessagePromptText')}
              buttonText={t('deleteMessagePromptButton')}
              performAction={this.deleteMessage}
            />
            {newMessageModalIsOpen && (
              <AddMessageModal
                modalIsOpen={newMessageModalIsOpen}
                closeModal={this.closeNewMessageModal}
                vehicle={vehicles.find((veh) => veh.id == message.vehicle)}
                isListRequest
              />
            )}
            {responseModalIsOpen && (
              <AddMessageResponseModal
                modalIsOpen={responseModalIsOpen}
                closeModal={this.closeResponseModal}
                message={message}
                isListRequest
              />
            )}

          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    infoIcon: {
      height: 32,
      width: 32,
    },
    alignCenter: {
      textAlign: 'center',
    },
    messageRow: {
      height: '60px',
      width: '100%',
      marginBottom: 2,

    },
    imgPlaceholder: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: 100,
      height: 60,
    },
    messageImageColumn: {
      width: 100,
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableValueTd: {
      fontFamily: 'TitilliumWeb-Light',
      fontSize: 18,
      color: '#FFFFFF',
      padding: 8,
      cursor: 'pointer',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    optionsIcon: {
      cursor: 'pointer',
    },
    tableRegNumTd: {
      fontFamily: 'TitilliumWeb-Bold',
      fontSize: 18,
      color: '#FFFFFF',
      //position: 'sticky',
      left: 0,
      padding: '8px 16px 8px 16px',
      borderBottom: '1px solid rgba(255, 255, 255, .3)',
    },
    tableOptionsTd: {
      //position: 'sticky',
      right: 0,
      padding: '0 16px 0 16px',
      zIndex: 0,
    },
  },
  medium: {
  },
  small: {
  },
};

export default translate('MessageListView')(withRouter(MessageListItem));
