import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, styleMerge, TYPE_CARE_SERVICE, TYPE_WORKSHOP_ALL } from '../../../utils/utils';
import {
  fetchPredictedMaintenances as _fetchPredictedMaintenances,
} from '../../../actions/predictedMaintenanceActions';
import CBPromptModal from '../../shared/CBPromptModal';
import PredictedMaintenanceListItem from './PredictedMaintenanceListItem';
import VehicleListTh from '../../OrganizationGarageView/OrganizationGarageComponents/VehicleListTh';
import PredictedMaintenanceFilterBar from './PredictedMaintenanceFilterBar';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import moment from 'moment';

class PredictedMaintenancesListView extends React.Component {
  state = {
    modalIsOpen: false,
    search: '',
    ordering: 'due_at',
    filters: {
        due_at__lte: moment().add(45, 'days').format('YYYY-MM-DD'),
    },
    fields: [],
    selectedFields: [],
    selectedPredictedMaintenances: [],
  };

  componentWillMount() {
    const {
      t,
      fetchPredictedMaintenances,
      service,
      selectedWorkshopId,
      selectedOrganizationId,
    } = this.props;

    const selectedFields = JSON.parse(localStorage.getItem('selectedPredictedMaintenanceFields'));

    const allFields = [
      {
        label: t('headingRegistrationNumber'),
        value: 'vehicle_registration_number',
      },
      {
        label: t('headingCompanyAndGarage'),
        value: 'organization',
      },
      {
        label: t('headingDueAt'),
        value: 'due_at',
      },
      {
        label: t('nextPredictedMaintenanceType', {ns: 'VehicleDetailsView'}),
        value: 'prediction_type',
      },
    ];

    if (this.getCurrentCompany().use_vehicle_identifier) {
      allFields.splice(1, 0, {
        label: t('headingVehicleIdentifier'),
        value: 'vehicle_identifier',
      })
    }

    this.setState({
      fields: allFields,
    });

    this.setState({
      selectedFields: selectedFields || this.getDefaultFields(),
    });

    this.refetchPredictedMaintenances(null, null);
  }

  getDefaultFields = () => {
    let defaultFields =  [
      'vehicle_registration_number',
      'status',
      'organization',
      'employee',
      'origin',
      'due_at',
      'prediction_type'
    ];

    if (this.getCurrentCompany().use_vehicle_identifier) {
      defaultFields.push('vehicle_identifier');
    }

    return defaultFields;
  }

  getCurrentCompany = () => {
    const {
      user,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    let currentCompany = {};
    if (selectedWorkshopId) {
      currentCompany = user.workshops.find((ws) => selectedWorkshopId == ws.id);
    } else {
      currentCompany = user.organizations.find((org) => selectedOrganizationId == org.id);
    }

    return currentCompany;
  }

  setSelectedFields = (newSelectedFields) => {
    this.setState({
      selectedFields: newSelectedFields,
    });
    JSON.stringify(newSelectedFields);
    localStorage.setItem('selectedPredictedMaintenanceFields', JSON.stringify(newSelectedFields));
  }

  getGarage = () => {
    const { garages, garageId } = this.props;
    return garages.find(garage => garage.id == garageId);
  }

  getSelectedPredictedMaintenances = () => {
    const { selectedPredictedMaintenances } = this.state;
    const { predictedMaintenances } = this.props;

    return predictedMaintenances.filter((msg) => selectedPredictedMaintenances.includes(msg.id));
  };

  selectVehicle = (predictedMaintenanceId) => {
    const { selectedPredictedMaintenances } = this.state;

    const newSelectedPredictedMaintenances = selectedPredictedMaintenances.slice();

    newSelectedPredictedMaintenances.push(predictedMaintenanceId);
    this.setState({ selectedPredictedMaintenances: newSelectedPredictedMaintenances });
  };

  unselectVehicle = (predictedMaintenanceId) => {
    const { selectedPredictedMaintenances } = this.state;

    const newSelectedPredictedMaintenances = selectedPredictedMaintenances.filter((item) => item !== predictedMaintenanceId);

    this.setState({ selectedPredictedMaintenances: newSelectedPredictedMaintenances });
  };

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  changeFilterTerms = value => {
    this.setState({
      filters: value
    });
    this.refetchPredictedMaintenances(null, value);
  }

  refetchPredictedMaintenances = (newOrdering = null, newFilters = null) => {
    const { fetchPredictedMaintenances, garageId, service, selectedWorkshopId, selectedOrganizationId } = this.props;
    const { search, ordering, filters } = this.state;

    const params = {
      search,
      ordering: newOrdering || ordering,
      garage_id: garageId
    };

    fetchPredictedMaintenances(
      newFilters ? { ...newFilters, ...params } : { ...filters, ...params },
      service === SERVICE_WORKSHOP && selectedWorkshopId,
      service === SERVICE_FLEET && selectedOrganizationId,
    );
  };

  changeOrdering = (clickedOrdering) => {
    const { ordering } = this.state;
    let newOrdering;
    if (ordering === clickedOrdering) {
      newOrdering = `-${ordering}`;
    } else if (`-${ordering}` === clickedOrdering) {
      newOrdering = 'id';
    } else {
      newOrdering = clickedOrdering;
    }
    this.setState({
      ordering: newOrdering,
    });
    this.refetchPredictedMaintenances(newOrdering);
  };

  getOrderingSymbol = (columnName) => {
    const { ordering } = this.state;
    if (ordering === columnName) {
      return '/img/down_arrow_icon.svg';
    } else if (ordering === `-${columnName}`) {
      return '/img/up_arrow_icon.svg';
    }
    return undefined;
  };

  selectAllPredictedMaintenances = () => {
    const { predictedMaintenances } = this.props;

    const newSelectedPredictedMaintenances = predictedMaintenances.map((predictedMaintenance) => predictedMaintenance.id);
    this.setState({ selectedPredictedMaintenances: newSelectedPredictedMaintenances });
  };

  unselectAllPredictedMaintenances = () => {
    this.setState({ selectedPredictedMaintenances: [] });
  };

  selectAllChanged = () => {
    const { predictedMaintenances } = this.props;
    const { selectedPredictedMaintenances } = this.state;
    const allChecked = selectedPredictedMaintenances.length === predictedMaintenances.length;

    if (allChecked) {
      this.unselectAllPredictedMaintenances();
    } else {
      this.selectAllPredictedMaintenances();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      this.refetchPredictedMaintenances();
    }
  }

  render() {
    const {
      predictedMaintenances,
      t,
      removeVehicleFromGarage,
      user,
      service,
      selectedWorkshopId,
      careServiceVehicles,
      garageVehicles
    } = this.props;

    const {
      selectedPredictedMaintenances,
      filters
    } = this.state;

    const { fields, selectedFields } = this.state;
    const garage = this.getGarage();

    const vehicles = service === SERVICE_WORKSHOP ? careServiceVehicles : garageVehicles;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'topBarsContainer')}>
              <PredictedMaintenanceFilterBar
                useIdentifiers={this.getCurrentCompany().use_vehicle_identifier}
                changeSearchTerms={this.changeSearchTerms}
                changeFilterTerms={this.changeFilterTerms}
                refetchPredictedMaintenances={this.refetchPredictedMaintenances}
                filters={filters}
              />
              <hr style={{ opacity: '30%' }} />
            </div>

            <div style={getStyle(screenSize, styles, 'tableContainer')}>
              <table style={getStyle(screenSize, styles, 'table')}>
                <thead>
                  <tr>
                    <th style={styleMerge(getStyle(screenSize, styles, 'tableHeaderFirst'), getStyle(screenSize, styles, 'alignCenter'))}>
                      <CBLabelAndCheckbox
                        checked={selectedPredictedMaintenances.length === predictedMaintenances.length}
                        additionalStyles={{ justifyContent: 'center' }}
                        onChange={this.selectAllChanged}
                      />
                    </th>

                    {
                      selectedFields.includes('vehicle_identifier') && this.getCurrentCompany().use_vehicle_identifier && (
                        <VehicleListTh
                          text={t('headingVehicleIdentifier')}
                          fieldName='vehicle_identifier'
                          orderingSymbol={this.getOrderingSymbol('vehicle_identifier')}
                          changeOrdering={() => {
                            this.changeOrdering('vehicle_identifier');
                          }}
                        />
                      )
                    }

                    <VehicleListTh
                      text={t('headingRegistrationNumber')}
                      fieldName='vehicle_registration_number'
                      orderingSymbol={this.getOrderingSymbol('vehicle_registration_number')}
                      changeOrdering={() => {
                        this.changeOrdering('vehicle_registration_number');
                      }}
                    />

                    {
                      selectedFields.includes('organization') && (
                        <VehicleListTh
                          text={t('headingCompanyAndGarage')}
                          fieldName='organization'
                          orderingSymbol={this.getOrderingSymbol('organization__name')}
                          changeOrdering={() => {
                            this.changeOrdering('organization__name');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('due_at') && (
                        <VehicleListTh
                          text={t('headingDueAt')}
                          fieldName='due_at'
                          orderingSymbol={this.getOrderingSymbol('due_at')}
                          changeOrdering={() => {
                            this.changeOrdering('due_at');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('prediction_type') && (
                        <VehicleListTh
                          text={t('nextPredictedMaintenanceType', { ns: 'VehicleDetailsView'})}
                          fieldName='prediction_type'
                          orderingSymbol={this.getOrderingSymbol('prediction_type')}
                          changeOrdering={() => {
                            this.changeOrdering('prediction_type');
                          }}
                        />
                      )
                    }

                    <th></th>


                    <th style={getStyle(screenSize, styles, 'tableHeaderSettings')}>
                      <MultiSelect
                        value={selectedFields}
                        onChange={(e) => this.setSelectedFields(e.value)}
                        options={fields}
                        className="bars"
                        dropdownIcon={undefined}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {predictedMaintenances.map(predictedMaintenance => (
                    <PredictedMaintenanceListItem
                      selectedFields={selectedFields}
                      predictedMaintenance={predictedMaintenance}
                      key={predictedMaintenance.id}
                      garage={garage}
                      selected={selectedPredictedMaintenances.includes(predictedMaintenance.id)}
                      removeVehicleFromGarage={removeVehicleFromGarage}
                      select={this.selectVehicle}
                      unselect={this.unselectVehicle}
                      service={service}
                      selectedWorkshopId={selectedWorkshopId}
                      useVehicleIdentifier={this.getCurrentCompany().use_vehicle_identifier}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      width: '100%',
      padding: '0px 16px 0px 0px',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    searchContainer: {
      maxWidth: 600,
    },
    table: {
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      tableLayout: 'fixed',
      marginBottom: '24px',
      paddingBottom: 16,
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Bold',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      padding: '8px 8px 8px 8px',
    },
    tableHeaderSettings: {
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      //position: 'sticky',
      padding: '8px 8px 8px 8px',
      right: 0,
    },
    tableHeaderFirst: {
      //position: 'sticky',
      left: 0,
    },
    alignCenter: {
      textAlign: 'center',
    },
    topBarsContainer: {
      paddingLeft: 16,
    },
  },
  medium: {
    table: {
    },
  },
  small: {
    table: {
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    garages: state.garage.garages,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    careServiceVehicles: state.vehicle.careServiceVehicles,
    garageVehicles: state.vehicle.garageVehicles,
    predictedMaintenances: state.predictedMaintenance.predictedMaintenances,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchPredictedMaintenances: _fetchPredictedMaintenances,
  },
)(translate('PredictedMaintenancesListView')(PredictedMaintenancesListView));
