import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP, styleMerge, TYPE_CARE_SERVICE, TYPE_WORKSHOP_ALL } from '../../../utils/utils';
import { fetchGarages as _fetchGarages } from '../../../actions/garageActions';
import { fetchMessages as _fetchMessages, deleteMessage as _deleteMessage } from '../../../actions/messageActions';
import AddMessageModal from '../../VehicleDetailsView/VehicleDetailsComponents/AddMessageModal';
import CBPromptModal from '../../shared/CBPromptModal';
import MessageListItem from './MessageListItem';
import VehicleListTh from '../../OrganizationGarageView/OrganizationGarageComponents/VehicleListTh';
import MessageFilterBar from './MessageFilterBar';

class MessageListView extends React.Component {
  state = {
    modalIsOpen: false,
    messageModalIsOpen: false,
    deleteModalIsOpen: false,
    search: '',
    ordering: '-created_at',
    fields: [],
    selectedFields: [],
    selectedMessages: [],
    filters: {
    },
  };

  componentWillMount() {
    const {
      fetchGarages,
      t,
      fetchMessages,
      service,
      selectedWorkshopId,
      selectedOrganizationId,
    } = this.props;

    const selectedFields = JSON.parse(localStorage.getItem('selectedMessageFields'));

    const allFields = [
      {
        label: t('headingManufacturer'),
        value: 'vehicle_model',
      },
      {
        label: t('headingMessageTitle'),
        value: 'subject',
      },
      {
        label: t('headingMessageSender'),
        value: 'user_display',
      },
      {
        label: t('headingMessageSentAt'),
        value: 'created_at',
      },
      {
        label: t('headingTask'),
        value: 'task',
      },
      {
        label: t('headingReply'),
        value: 'has_response',
      },
      {
        label: t('headingRead'),
        value: 'is_read',
      },
      {
        label: t('headingNewMessage'),
        value: 'new_message',
      },
    ];

    if (this.getCurrentCompany().use_vehicle_identifier) {
      allFields.splice(1, 0, {
        label: t('headingVehicleIdentifier'),
        value: 'vehicle_identifier',
      })
    }

    this.setState({
      fields: allFields,
    });

    this.setState({
      selectedFields: selectedFields || allFields.map((field) => field.value),
    });

    fetchGarages();
    this.refetchMessages();
  }

  getCurrentCompany = () => {
    const {
      listType,
      user,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    let currentCompany = {};
    if (selectedWorkshopId) {
      currentCompany = user.workshops.find((ws) => selectedWorkshopId == ws.id);
    } else {
      currentCompany = user.organizations.find((org) => selectedOrganizationId == org.id);
    }

    return currentCompany;
  }

  setSelectedFields = (newSelectedFields) => {
    this.setState({
      selectedFields: newSelectedFields,
    });
    JSON.stringify(newSelectedFields);
    localStorage.setItem('selectedMessageFields', JSON.stringify(newSelectedFields));
  }

  getGarage = () => {
    const { garages, garageId } = this.props;
    return garages.find(garage => garage.id == garageId);
  }

  changeSearchTerms = value => {
    this.setState({
      search: value,
    });
  }

  getSelectedMessages = () => {
    const { selectedMessages } = this.state;
    const { messages } = this.props;

    return messages.filter((msg) => selectedMessages.includes(msg.id));
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
    this.refetchMessages();
  };

  closeUpdateModal = () => {
    this.setState({
      updateModalIsOpen: false,
    });
  };

  closeMessageModal = () => {
    this.setState({
      messageModalIsOpen: false,
    });
  };

  closeDeletePromptModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenModal = () => {
  };

  selectVehicle = (messageId) => {
    const { selectedMessages } = this.state;

    const newSelectedMessages = selectedMessages.slice();

    newSelectedMessages.push(messageId);
    this.setState({ selectedMessages: newSelectedMessages });
  };

  unselectVehicle = (messageId) => {
    const { selectedMessages } = this.state;

    const newSelectedMessages = selectedMessages.filter((item) => item !== messageId);

    this.setState({ selectedMessages: newSelectedMessages });
  };

  addCar = () => {
    this.setState({ modalIsOpen: true });
  };

  updateMessages = () => {
    this.setState({ updateModalIsOpen: true });
  };

  sendMessages = () => {
    this.setState({ messageModalIsOpen: true });
  };

  deleteMessages = () => {
    this.setState({ deleteModalIsOpen: true });
  };

  refetchMessages = (newOrdering, newFilters) => {
    const {
      fetchMessages,
      garageId,
      service,
      selectedWorkshopId,
      selectedOrganizationId,
    } = this.props;
    const { search, ordering, filters } = this.state;

    const params = {
      search,
      ordering: newOrdering || ordering,
      garage_id: garageId
    };

    fetchMessages(
      newFilters ? { ...newFilters, ...params } : { ...filters, ...params },
      service === SERVICE_WORKSHOP && selectedWorkshopId,
      service === SERVICE_FLEET && selectedOrganizationId,
    );
  };

  changeOrdering = (clickedOrdering) => {
    const { ordering } = this.state;
    let newOrdering;
    if (ordering === clickedOrdering) {
      newOrdering = `-${ordering}`;
    } else if (`-${ordering}` === clickedOrdering) {
      newOrdering = 'id';
    } else {
      newOrdering = clickedOrdering;
    }
    this.setState({
      ordering: newOrdering,
    });
    this.refetchMessages(newOrdering);
  };

  getOrderingSymbol = (columnName) => {
    const { ordering } = this.state;
    if (ordering === columnName) {
      return '/img/down_arrow_icon.svg';
    } else if (ordering === `-${columnName}`) {
      return '/img/up_arrow_icon.svg';
    }
    return undefined;
  };

  selectAllMessages = () => {
    const { messages } = this.props;

    const newSelectedMessages = messages.map((message) => message.id);
    this.setState({ selectedMessages: newSelectedMessages });
  };

  unselectAllMessages = () => {
    this.setState({ selectedMessages: [] });
  };

  selectAllChanged = () => {
    const { messages } = this.props;
    const { selectedMessages } = this.state;
    const allChecked = selectedMessages.length === messages.length;

    if (allChecked) {
      this.unselectAllMessages();
    } else {
      this.selectAllMessages();
    }
  }

  changeFilterTerms = value => {
    this.setState({
      filters: value
    });
    this.refetchMessages(null, value);
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      this.refetchMessages();
    }
  }

  render() {
    const {
      messages,
      t,
      removeVehicleFromGarage,
      user,
      deleteMessage,
      garageVehicles,
      careServiceVehicles,
      service
    } = this.props;

    const {
      selectedMessages,
      messageModalIsOpen,
      deleteModalIsOpen,
      filters
    } = this.state;

    const { modalIsOpen, fields, selectedFields } = this.state;
    const garage = this.getGarage();

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'topBarsContainer')}>
              <MessageFilterBar
                useIdentifiers={this.getCurrentCompany().use_vehicle_identifier}
                changeSearchTerms={this.changeSearchTerms}
                refetchMessages={this.refetchMessages}
                sendMessages={this.sendMessages}
                filters={filters}
              />
              <hr style={{ opacity: '30%' }} />
            </div>

            <div style={getStyle(screenSize, styles, 'tableContainer')}>
              <table style={getStyle(screenSize, styles, 'table')}>
                <thead>
                  <tr>
                    {
                    /*
                    <th style={styleMerge(getStyle(screenSize, styles, 'tableHeaderFirst'), getStyle(screenSize, styles, 'alignCenter'))}>
                      <CBLabelAndCheckbox
                        checked={selectedMessages.length === messages.length}
                        additionalStyles={{ justifyContent: 'center' }}
                        onChange={this.selectAllChanged}
                      />
                    </th>
                    */
                    }

                    {
                      selectedFields.includes('image') && (
                        <th style={getStyle(screenSize, styles, 'tableHeader')} />
                      )
                    }

                    {
                      selectedFields.includes('vehicle_identifier') && this.getCurrentCompany().use_vehicle_identifier && (
                        <VehicleListTh
                          text={t('headingVehicleIdentifier')}
                          fieldName='vehicle_identifier'
                          orderingSymbol={this.getOrderingSymbol('vehicle_identifier')}
                          changeOrdering={() => {
                            this.changeOrdering('vehicle_identifier');
                          }}
                        />
                      )
                    }

                    <VehicleListTh
                      text={t('headingRegistrationNumber')}
                      fieldName='vehicle_registration_number'
                      orderingSymbol={this.getOrderingSymbol('vehicle_registration_number')}
                      changeOrdering={() => {
                        this.changeOrdering('vehicle_registration_number');
                      }}
                      notSortable
                    />

                    {
                      selectedFields.includes('vehicle_model') && (
                        <VehicleListTh
                          text={t('headingManufacturer')}
                          fieldName='vehicle_model'
                          orderingSymbol={this.getOrderingSymbol('vehicle__manufacturer')}
                          changeOrdering={() => {
                            this.changeOrdering('vehicle__manufacturer');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('subject') && (
                        <VehicleListTh
                          text={t('headingMessageTitle')}
                          fieldName='subject'
                          orderingSymbol={this.getOrderingSymbol('subject')}
                          changeOrdering={() => {
                            this.changeOrdering('subject');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('user_display') && (
                        <VehicleListTh
                          text={t('headingMessageSender')}
                          fieldName='user_display'
                          orderingSymbol={this.getOrderingSymbol('user__profile__first_name')}
                          changeOrdering={() => {
                            this.changeOrdering('user__profile__first_name');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('created_at') && (
                        <VehicleListTh
                          text={t('headingMessageSentAt')}
                          fieldName='created_at'
                          orderingSymbol={this.getOrderingSymbol('created_at')}
                          changeOrdering={() => {
                            this.changeOrdering('created_at');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('task') && (
                        <VehicleListTh
                          text={t('headingTask')}
                          fieldName='task'
                          orderingSymbol={this.getOrderingSymbol('task')}
                          changeOrdering={() => {
                            this.changeOrdering('task');
                          }}
                        />
                      )
                    }

                    {
                      selectedFields.includes('has_response') && (
                        <VehicleListTh
                          text={t('headingReply')}
                          fieldName='has_response'
                          orderingSymbol={this.getOrderingSymbol('has_response')}
                          changeOrdering={() => {
                            this.changeOrdering('has_response');
                          }}
                          notSortable
                        />
                      )
                    }

                    {
                      selectedFields.includes('is_read') && (
                        <VehicleListTh
                          text={t('headingRead')}
                          fieldName='is_read'
                          orderingSymbol={this.getOrderingSymbol('is_read')}
                          changeOrdering={() => {
                            this.changeOrdering('is_read');
                          }}
                          notSortable
                        />
                      )
                    }


                    {
                      selectedFields.includes('new_message') && (
                        <VehicleListTh
                          text={t('headingNewMessage')}
                          fieldName='new_message'
                          notSortable
                        />
                      )
                    }

                    <th style={getStyle(screenSize, styles, 'tableHeaderSettings')}>
                      <MultiSelect
                        value={selectedFields}
                        onChange={(e) => this.setSelectedFields(e.value)}
                        options={fields}
                        className="bars"
                        dropdownIcon={undefined}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {messages.map(message => (
                    <MessageListItem
                      selectedFields={selectedFields}
                      message={message}
                      key={message.id}
                      garage={garage}
                      selected={selectedMessages.includes(message.id)}
                      removeVehicleFromGarage={removeVehicleFromGarage}
                      select={this.selectVehicle}
                      unselect={this.unselectVehicle}
                      deleteMessage={deleteMessage}
                      vehicles={service === SERVICE_WORKSHOP ? careServiceVehicles : garageVehicles}
                      useVehicleIdentifier={this.getCurrentCompany().use_vehicle_identifier}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            {messageModalIsOpen && (
              <AddMessageModal
                modalIsOpen={messageModalIsOpen}
                closeModal={this.closeMessageModal}
                selectedMessages={this.getSelectedMessages()}
                vehicles={service === SERVICE_WORKSHOP ? careServiceVehicles : garageVehicles}
              />
            )}
            {deleteModalIsOpen && (
              <CBPromptModal
                modalIsOpen={deleteModalIsOpen}
                closeModal={this.closeDeletePromptModal}
                afterOpenModal={() => {}}
                title={t('deleteManyFromGaragePromptTitle')}
                text={`${t('deleteManyFromGaragePromptText')}`}
                buttonText={t('deleteButton')}
                performAction={() => {
                  removeVehicleFromGarage(undefined, garage.id, selectedMessages);
                  this.closeDeletePromptModal();
                  this.unselectAllMessages();
                }}
              />
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      width: '100%',
      padding: '0px 16px 0px 0px',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    searchContainer: {
      maxWidth: 600,
    },
    table: {
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      tableLayout: 'fixed',
      marginBottom: '24px',
      paddingBottom: 16,
    },
    tableHeader: {
      fontFamily: 'TitilliumWeb-Bold',
      color: '#FFFFFF',
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      padding: '8px 8px 8px 8px',
    },
    tableHeaderSettings: {
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none',
      UserSelect: 'none',
      //position: 'sticky',
      padding: '8px 8px 8px 8px',
      right: 0,
    },
    tableHeaderFirst: {
      //position: 'sticky',
      left: 0,
    },
    alignCenter: {
      textAlign: 'center',
    },
    topBarsContainer: {
      marginTop: 32,
      paddingLeft: 16,
    },
  },
  medium: {
    table: {
    },
  },
  small: {
    table: {
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    garages: state.garage.garages,
    messages: state.message.messages,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    careServiceVehicles: state.vehicle.careServiceVehicles,
    garageVehicles: state.vehicle.garageVehicles,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchGarages: _fetchGarages,
    fetchMessages: _fetchMessages,
    deleteMessage: _deleteMessage,
  },
)(translate('MessageListView')(MessageListView));
