import React from 'react';
import { translate } from 'react-i18next';
import LicensePlate from '../../shared/LicensePlate';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle } from '../../../utils/utils';
import OptionsButton from './OptionsComponents/OptionsButton';
import { history } from '../../../index';
import ChangeVehicleCategoryModal from './ChangeVehicleCategoryModal';
import NotificationModal from './NotificationModal';

class VehicleGridItem extends React.Component {
  state = {
    modalIsOpen: false,
  };

  componentDidMount() {
    const today = new Date();
    const lastTraficomSync = new Date(String(this.props.vehicle.traficom_sync_date));
    const notificationDate = new Date(lastTraficomSync);
    notificationDate.setDate(notificationDate.getDate() + 335);
    if (today > notificationDate) {
      this.setState({
        notificationModalisOpen: true,
      });
    }
  }

  changeCategory = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  closeNotificationModal = () => {
    this.setState({
      notificationModalisOpen: false,
    });
  };

  afterOpenModal = () => {};

  handleNavigation = e => {
    const { vehicle } = this.props;

    e.preventDefault();
    e.nativeEvent.preventDefault();
    history.push(`/vehicle/${vehicle.id}`);
  };

  render() {
    const { vehicle, t } = this.props;
    const { modalIsOpen, notificationModalisOpen } = this.state;

    const vehicleOptions = [
      {
        title: t('optionsMoveButton'),
        action: () => {
          this.changeCategory();
        },
      },
      // { title: t('optionsUpdateInfoButton') },
      { title: t('optionsRemoveCarButton'), to: '/settings/cars' },
      { title: t('optionsPermissionsButton'), to: '/settings/cars' },
    ];

    return (
      <CBMediaQuery>
        {screenSize => (
        <React.Fragment>
          <div style={getStyle(screenSize, styles, 'container')} onClick={this.handleNavigation}>
            <div style={getStyle(screenSize, styles, 'vehicleContainer')}>
              <div style={getStyle(screenSize, styles, 'vehicleImageContainer')}>
                <img
                  style={getStyle(screenSize, styles, 'vehicleImage')}
                  alt='Vehicle'
                  src={vehicle.images[0] ? vehicle.images[0].image : '/img/placeholder.png'}
                />
              </div>
              <div style={getStyle(screenSize, styles, 'bottomBlock')}>
                <div style={getStyle(screenSize, styles, 'vehicleNameContainer')}>
                  {`${vehicle.manufacturer} ${vehicle.model_name}`}
                </div>
                <div style={getStyle(screenSize, styles, 'infoContainer')}>
                  <div style={getStyle(screenSize, styles, 'licencePlateContainer')}>
                    <LicensePlate plateNumber={vehicle.registration_number} />
                  </div>
                </div>
                <div style={getStyle(screenSize, styles, 'infoPlaceholder')}>
                  <OptionsButton icon='/img/icon_options.svg' options={vehicleOptions} />
                </div>
              </div>
            </div>
            <ChangeVehicleCategoryModal
              modalIsOpen={modalIsOpen}
              closeModal={this.closeModal}
              afterOpenModal={this.afterOpenModal}
              vehicle={vehicle}
            />
          </div>
          {notificationModalisOpen && (
            <NotificationModal
              vehicle={vehicle}
              modalIsOpen={notificationModalisOpen}
              closeModal={this.closeNotificationModal}
              afterOpenModal={this.afterOpenModal}
            />
            )}
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bottomBlock: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    infoPlaceholder: {
      alignSelf: 'flex-end',
      position: 'relative',
      bottom: 32,
      right: 16,
    },
    vehicleNameContainer: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 22,
      fontFamily: 'TitilliumWeb-Light',
      color: '#FFFFFF',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      minWidth: '100%',
      width: 0,
    },
    licensePlateContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    vehicleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
      backgroundImage: 'linear-gradient(0deg, #0095da 3%, #0069ab 100% )',
      backgroundPosition: 'center center',
      boxShadow: '1px 1px 2px rgba(0,0,0,0.5)',
    },
    vehicleImageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    vehicleImage: {
      width: '100%',
      height: 255,
      objectFit: 'cover',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 15,
      width: '100%',
    },
    container: {
      width: '100%',
      cursor: 'pointer',
      marginBottom: 32,
    },
  },
  medium: {
    vehicleName: {
      fontSize: 18,
      fontWeight: 300,
      color: '#FFFFFF',
      textAlign: 'center',
    },
    container: {
      marginBottom: 32,
    },
  },
  small: {
    bottomBlock: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
    },
    vehicleNameContainer: {
      height: 'unset',
    },
    infoPlaceholder: {
      alignSelf: 'flex-end',
      bottom: 32,
      right: 16,
    },
    vehicleContainer: {
      width: '100%',
      marginLeft: 0,
      marginBottom: 20,
    },
    vehicleInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 15,
    },
    vehicleImage: {
      width: '100%',
      height: 255,
      objectFit: 'cover',
    },
    container: {
      marginBottom: 0,
    },
  },
};

export default translate('GarageView')(VehicleGridItem);
