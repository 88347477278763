import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DetailsTaskListItem from './ListItems/DetailsTaskListItem';
import CBSelect from '../../shared/CBSelect';
import CBMediaQuery from '../../shared/CBMediaQuery';
import { getStyle, getUrlId } from '../../../utils/utils';
import AddTaskButton from './ListItems/AddTaskButton';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';
import { set } from 'lodash';

class VehicleDetailsTaskList extends React.Component {
  state = {
  };

  isSelected = (task, idx) => {
    const urlParts = window.location.pathname.split('/');
    const index = urlParts.indexOf('tasks') + 1;

    if (index && urlParts[index]) {
      return task.id == urlParts[index];
    }

    return idx == 0;
  };

  taskSelected = e => {
    const { history, vehicle } = this.props;
    history.push(`/vehicle/${vehicle.id}/tasks/${e.target.value}`);
  };

  //Do not remove. For unknown reasons the view won't re-render without this.
  static getDerivedStateFromProps(nextProps, prevState) {}

  render() {
    const { vehicle, location, history, selectedTasks, selectAllTasks, selectTask, tasks } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div
            style={getStyle(
              screenSize,
              styles,
              screenSize !== 'small' ? 'listContainer' : 'container',
            )}
          >
            {screenSize !== 'small' && (
              <div>
                <div style={getStyle(screenSize, styles, 'addButtonContainer')}>
                  <CBLabelAndCheckbox
                    additionalStyles={{ padding: 8 }}
                    checked={tasks.length === selectedTasks.length}
                    onChange={() => selectAllTasks()}/>
                  <AddTaskButton />
                </div>

                {tasks.map((task, idx) => (
                  <DetailsTaskListItem
                    selectTask={selectTask}
                    isMultiSelected={selectedTasks.includes(task.id)}
                    key={task.id}
                    vehicle={vehicle}
                    task={task}
                    history={history}
                    isSelected={this.isSelected(task, idx)}
                  />
                ))}
              </div>
            )}
            {screenSize === 'small' && (
              <Fragment>
                <div>
                  <AddTaskButton />
                </div>
                <div style={getStyle(screenSize, styles, 'selectContainer')}>
                  <CBSelect
                    value={getUrlId(location.pathname)}
                    onChange={this.taskSelected}
                    >
                    {tasks.map(task => (
                      <option key={task.id} value={task.id}>
                        {`${moment(task.created_at).format('D.M.Y')} | ${
                          task.title
                        }`}
                      </option>
                    ))}
                  </CBSelect>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '25%',
    },
    addButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      marginRight: 15,
      marginLeft: 15,
      marginTop: 40,
    },
  },
  small: {
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
      width: '100%',
      overflow: 'auto',
    },
  },
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(withRouter(VehicleDetailsTaskList));
