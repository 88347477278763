import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import { withRouter } from 'react-router';
import {
  fetchOrganizations as _fetchOrganizations,
  updateOrganization as _updateOrganization,
} from '../../../actions/organizationActions';
import CBSubmitButton from '../../shared/form_components/CBSubmitButton';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';
import { getLengthValidator } from '../../../utils/validators';

class TaskSettings extends React.Component {
  state = {
    // If initialData is a list of strings, set formData directly to that array
    formData: {
      help_text: null,
    },
    edited: false
  };

  componentDidMount = () => {
    const { fetchOrganizations, organizations, selectedOrganizationId } = this.props;
    fetchOrganizations();
  };

  handleInputChange = (changedObj) => {
    this.setState(prevState => {
      const updatedFormData = {...prevState.formData};
      updatedFormData.help_text = changedObj.value;
      return { formData: updatedFormData, edited: true };
    });
  };

  handleSubmit = () => {
    const { onSubmit, updateOrganization, selectedOrganizationId, organizations } = this.props;
    // Simply pass back the list of strings
    const selectedOrganization = organizations.find((org) => org.id == selectedOrganizationId);
    const newSettings = { ...selectedOrganization.settings };
    const newHelpText = this.state.formData.help_text;

    if (!newSettings.tasks) {
      newSettings.tasks = {
        help_text: newHelpText
      };
    } else {
      newSettings.tasks.help_text = newHelpText;
    }

    const newOrganization = {
      ...selectedOrganization,
      settings: newSettings
    }

    updateOrganization(selectedOrganizationId, newOrganization);
    this.setState({ edited: false })
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId, organizations } = this.props;
    const { formData, edited } = this.state;

    let selectedOrganization = null;

    if (organizations && organizations.length > 0) {
      selectedOrganization = organizations.find((org) => org.id == selectedOrganizationId)
    }

    if (!selectedOrganization) {
      return;
    }

    let newHelpText = '';

    if (selectedOrganization.settings.tasks && selectedOrganization.settings.tasks.help_text) {
      newHelpText = selectedOrganization.settings.tasks.help_text;
    }

    if (((selectedOrganizationId !== prevProps.selectedOrganizationId) || (!prevProps.organizations || prevProps.organizations.length == 0)) || (!edited && formData.help_text !== newHelpText)) {
      this.setState({
        formData: {
          help_text: newHelpText
        }
      })
    }
  }

  render() {
    const {
      t,
      service,
      selectedWorkshopId,
      user,
      selectedOrganizationId,
    } = this.props;

    const { formData, edited } = this.state; // We'll render directly from formData

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <CBSubHeading>
                Tehtävän luonnin ohjeteksti
              </CBSubHeading>
              <p style={{ marginTop: 8 }}>Aseta halutessasi ohjeteksti, joka näytetään tehtävien luonnin yhteydessä.</p>
            </div>
            <div style={getStyle(screenSize, styles, 'formContainer')}>
              <div
                style={getStyle(screenSize, styles, 'fieldContainer')}
              >
                <CBTextAreaField
                  name='help_text'
                  value={formData.help_text}
                  onChange={this.handleInputChange}
                  labelText={''}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  validators={[getLengthValidator(1, 5000)]}
                  isValidCallback={() => {}}
                  validateOnInput
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CBSubmitButton
                  disabled={!edited}
                  onClick={this.handleSubmit}
                  text="Tallenna" />
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    formContainer: {
      maxWidth: 800
    },
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    sectionContainer: {
      marginBottom: 16,
    },
    fieldContainer: {
      marginBottom: '12px',
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    buttonText: {
      fontSize: 14,
      fontWeight: 700,
      color: '#0095da',
      padding: 8,
      margin: 0,
    },
    button: {
      width: 200,
      border: '2px solid #0095da50',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    organizationRequest: state.organization,
    organizations: state.organization.organizations,
    loading: state.organization.organizationListStatus.loading,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOrganizations: _fetchOrganizations,
    updateOrganization: _updateOrganization,
  }
)(withRouter(translate('SettingsView')(TaskSettings)));
