import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';

import { getStyle } from '../../../utils/utils';
import CBInputField from '../../shared/form_components/CBInputField';
import CBTextAreaField from '../../shared/form_components/CBTextAreaField';

import {
  createCareService as _createCareService,
} from '../../../actions/careServiceActions';
import {
  getEmailValidator,
  getLengthValidator,
} from '../../../utils/validators';
import CBModal from '../../shared/CBModal';

const DEFAULT_TEXT = `Kutsumme teidät liittymään ajoneuvokalustonne huolenpitopalveluun. Liittymällä huolenpitopalveluun, me otamme vastuun ajoneuvokalustonne katsastuksista, määräaikaistarkastuksista ja huoltokutsuista.

Huolenpitopalveluun liittymällä saatte samalla myös oman näkymän ajoneuvokalustoonne ja sen huoltoihin. Palveluun sisältyy myös mahdollisuus viestiä meille ajoneuvoihin liittyvistä havainnoista ja korjaus-/huoltotarpeista.`;

class InviteToCareServiceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        email: '',
        business_name: '',
        business_id: '',
        title: 'Hei,',
        text: DEFAULT_TEXT,
        workshop: props.selectedWorkshopId,
      },
      formValid: {
        email: false
      },
    };
  }

  componentDidMount = () => {
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
      },
    };

    this.setState(newState);
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      closeModal,
      createCareService,
    } = this.props;

    createCareService(formData);

    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title='Kutsu asiakas'
            descriptionText='Kutsu asiakas huolenpitopalveluun'
            cancelText='Peruuta'
            submitText='Lähetä'
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              <CBInputField
                name='email'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.email}
                placeholder=''
                type='email'
                onChange={this.updateFormState}
                validators={[getLengthValidator(1, 40), getEmailValidator()]}
                isValidCallback={this.setIsValid}
                labelText={t('invitationEmailLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                noValidationOnChange
              />
              <CBInputField
                name='organization_name'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.organization_name}
                placeholder=''
                onChange={this.updateFormState}
                validators={[getLengthValidator(1, 40)]}
                isValidCallback={this.setIsValid}
                labelText={t('invitationOrganizationNameLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />
              <CBInputField
                name='business_id'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.business_id}
                placeholder=''
                onChange={this.updateFormState}
                validators={[getLengthValidator(9, 9)]}
                isValidCallback={this.setIsValid}
                labelText={t('invitationBusinessIdLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />
              <CBInputField
                name='title'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.title}
                placeholder=''
                onChange={this.updateFormState}
                validators={[getLengthValidator(1, 160)]}
                isValidCallback={this.setIsValid}
                labelText={t('invitationTitleLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />
              <CBTextAreaField
                name='text'
                value={formData.text}
                onChange={this.updateFormState}
                labelText={t('invitationTextLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                validators={[getLengthValidator(1, 5000)]}
                isValidCallback={this.setIsValid}
                validateOnInput
              />
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    }
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
  };
}

export default connect(
  mapStateToProps,
  {
    createCareService: _createCareService,
  },
)(translate('CareService')(InviteToCareServiceModal));
