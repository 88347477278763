import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSearchInput from '../../shared/CBSearchInput';
import CBButtonAddCar from '../../shared/CBButtonAddCar';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import CBSelectFilters from '../../shared/CBSelectFilters';
import CBSelectField from '../../shared/form_components/CBSelectField';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';


class TaskFilterBar extends Component {
  getStatusOptions = () => {
    return [
      {
        label: 'Näytä kaikki tilat',
        value: ''
      },
      {
        label: 'Näytä vain luodut',
        value: 'created'
      },
      {
        label: 'Näytä vain työn alla olevat',
        value: 'in_progress'
      },
      {
        label: 'Näytä vain valmiit',
        value: 'done'
      },
      {
        label: 'Näytä vain peruutetut',
        value: 'cancelled'
      },
    ]
  }

  render() {
    const {
      changeSearchTerms,
      changeFilterTerms,
      refetchTasks,
      t,
      addTask,
      filters,
      useIdentifiers
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'containerFilters')}>
              <div style={getStyle(screenSize, styles, 'containerSearch')}>
                <CBSearchInput
                  containerStyle={{ height: 40, width: 240, marginLeft: 0 }}
                  changeSearchTerms={changeSearchTerms}
                  onBlur={refetchTasks}
                />
                {
                  useIdentifiers && (
                    <CBLabelAndCheckbox
                      labelText={'Hae vain tunnisteista'}
                      checked={filters.search_identifiers}
                      onChange={
                        () => {
                          const newFilters = {...filters};
                          if (!filters.search_identifiers) {
                            return changeFilterTerms({...newFilters, search_identifiers: true })
                          } else {
                            delete newFilters.search_identifiers;
                            return changeFilterTerms({...newFilters})
                          }
                        }
                      }
                    />
                  )
                }
              </div>
              <CBSelectFilters
                name='status'
                options={this.getStatusOptions()}
                onChange={changeFilterTerms}
                value={filters.status}
              />
            </div>
            <div style={getStyle(screenSize, styles, 'containerActions')}>
              <CBButtonGarageAction
                onClick={addTask}
                text={t('headingAddTask')}
                additionalIcon='/img/icon_plus.svg'
                mainIcon='/img/icon_task_list.svg'
                additionalStylesMainIcon={{ height: 25, width: 25 }}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    containerSearch: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    containerFilters: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    containerActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
};

function mapStateToProps(state) {
  return {
    searchRequest: state.search,
  };
}

export default connect(
  mapStateToProps,
  {
  },
)(translate('TaskListView')(TaskFilterBar));
