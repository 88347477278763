import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBLabelAndValue from '../../shared/CBLabelAndValue';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import CBSelectField from '../../shared/form_components/CBSelectField';
import {
  fetchCheckLists as _fetchCheckLists,
  createCheckList as _createCheckList,
  updateCheckList as _updateCheckList,
  deleteCheckList as _deleteCheckList,
  createCheckListItem as _createCheckListItem,
  updateCheckListItem as _updateCheckListItem,
  deleteCheckListItem as _deleteCheckListItem,
} from '../../../actions/checkListActions';
import { withRouter } from 'react-router';
import CheckListItemRow from './CheckListItemRow';

class SettingsCheckLists extends React.Component {
  state = {
    selectedWorkshop: undefined,
  };

  componentDidMount() {
    this.fetchCheckLists();
  }

  fetchCheckLists = () => {
    const {
      fetchCheckLists,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    const params = {};

    if (selectedOrganizationId) {
      params.organization_id = selectedOrganizationId;
    } else if (selectedWorkshopId) {
      params.workshop_id = selectedWorkshopId;
    }

    fetchCheckLists(params);
  };

  getCheckList = (checkLists) => {
    const { service, selectedWorkshopId, selectedOrganizationId } = this.props;

    let checkList = null;

    if (service === SERVICE_WORKSHOP && selectedWorkshopId) {
      checkList = checkLists.find((cl) => cl.workshop == selectedWorkshopId);
    }
    else if (service === SERVICE_FLEET && selectedOrganizationId) {
      checkList = checkLists.find((cl) => cl.organization == selectedOrganizationId);
    }

    if (!checkList) {
      checkList = checkLists.find((cl) => cl.list_type == 'default');
    }

    return checkList;
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId
      || selectedOrganizationId != prevProps.selectedOrganizationId) {
      this.fetchCheckLists();
    }
  };

  render() {
    const {
      t,
      service,
      selectedWorkshopId,
      user,
      createCheckListItem,
      createCheckList,
      updateCheckListItem,
      deleteCheckListItem,
      selectedOrganizationId,
      checkLists
    } = this.props;

    const checkList = this.getCheckList(checkLists);

    const isStaff = user.is_staff;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <CBSubHeading>
                {t('checkListHeading')}
              </CBSubHeading>
            </div>

            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <p>
                {t('checkListDescription')}
              </p>
            </div>

            <React.Fragment>
              <table>
                <thead>
                  <tr>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('checkNameLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('checkDescriptionLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('checkIntervalLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('checkTaskCreationBeforeLabel')}
                    </th>
                    <th style={getStyle(screenSize, styles, 'tableHeading')}>
                      {t('checkNotifyBeforeLabel')}
                    </th>
                    {
                      checkList && checkList.list_type === 'workshop' && (
                        <th style={getStyle(screenSize, styles, 'tableHeading')}>
                          {t('checkNotifyBeforeFleetLabel')}
                        </th>
                      )
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    checkList && checkList.items && checkList.items.map((item) =>
                      (
                        <CheckListItemRow
                          key={item.id}
                          item={item}
                          isStaff
                          checkList={checkList}
                          createCheckListItem={createCheckListItem}
                          createCheckList={createCheckList}
                          updateCheckListItem={updateCheckListItem}
                          deleteCheckListItem={deleteCheckListItem}
                          service={service}
                          selectedWorkshopId={selectedWorkshopId}
                          selectedOrganizationId={selectedOrganizationId}
                        />
                      ))
                  }
                  {
                    (
                      <CheckListItemRow
                        isNew
                        item={{
                          'name': '',
                          'description': '',
                          'check_interval_months': null,
                          'days_before_task_creation': null,
                          'days_before_notification': null,
                        }}
                        createCheckListItem={createCheckListItem}
                        createCheckList={createCheckList}
                        updateCheckListItem={updateCheckListItem}
                        deleteCheckListItem={deleteCheckListItem}
                        canEdit
                        isStaff
                        checkList={checkList}
                        service={service}
                        selectedWorkshopId={selectedWorkshopId}
                        selectedOrganizationId={selectedOrganizationId}
                      />
                    )
                  }
                </tbody>
              </table>
            </React.Fragment>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}


const styles = {
  default: {
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    sectionContainer: {
      marginBottom: 16,
    },
    tableHeading: {
      fontFamily: 'TitilliumWeb-Regular',
      fontSize: 12,
      color: '#ffffff',
      paddingLeft: 8,
      paddingBottom: 8,
    },
    permissionTypeContainer: {
      marginTop: 32,
      borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
    },
    workshopSelectorContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    selectField: {
      minWidth: 300,
      height: 40,
      border: '1px solid rgb(255 255 255 / 55%)',
      borderRadius: 50,
      backgroundColor: 'transparent',
      color: '#FFF',
      padding: '0px 0px 0px 16px',
    },
    selectContainer: {
      marginTop: 16,
      marginRight: 16,
      minWidth: 300,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    checkLists: state.checkList.checkLists
  };
}

export default connect(
  mapStateToProps,
  {
    fetchCheckLists: _fetchCheckLists,
    createCheckList: _createCheckList,
    updateCheckList: _updateCheckList,
    deleteCheckList: _deleteCheckList,
    createCheckListItem: _createCheckListItem,
    updateCheckListItem: _updateCheckListItem,
    deleteCheckListItem: _deleteCheckListItem,
  },
)(withRouter(translate('CheckLists')(SettingsCheckLists)));






