import React from 'react';
import { Route } from 'react-router';
import { translate } from 'react-i18next';
import axios from 'axios';
import isEqual from "lodash/isEqual";
import VehicleDetailsTaskContent from './VehicleDetailsTaskContent';
import VehicleDetailsTaskList from './VehicleDetailsTaskList';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import CBMediaQuery from '../../shared/CBMediaQuery';
import TaskMultiSelectActionBar from './TaskMultiSelectActionBar';

class VehicleDetailsMainTask extends React.Component {
  state = {
    selectedTasks: [],
    filteredTaskIds: null,
    filters: {
      statuses: [
        'created',
        'in_progress',
      ],
      search: '',
    }
  }

  componentDidMount() {
    this.filterTasksLocal(this.state.filters);
  }

  componentDidUpdate(prevProps) {
    const { vehicle } = this.props;
    const { vehicle: prevVehicle } = prevProps;
    if (!isEqual(vehicle.tasks, prevVehicle.tasks)) {
      this.filterTasksLocal(this.state.filters);
    }
  };

  getFilteredTasks = () => {
    const { tasks } = this.props.vehicle;
    const { filteredTaskIds } = this.state;

    if (filteredTaskIds === null) {
      return tasks;
    }

    return tasks.filter(task =>
      filteredTaskIds.includes(parseInt(task.id)),
    );
  };

  filterTasksLocal = (filters) => {
    const { vehicle, setSelectedTasks } = this.props;

    const taskIds = vehicle.tasks.filter(task => {
      let searchCondition = true;
      if (filters.search) {
        searchCondition = task.title.toLowerCase().includes(filters.search.toLowerCase()) ||
          task.text.toLowerCase().includes(filters.search.toLowerCase());
      }
      const statusCondition = (filters.statuses.includes(task.status))
      return statusCondition && searchCondition;
    }).map((t) => t.id);

    const newSelectedTasks = this.state.selectedTasks.filter(id => taskIds.includes(id));
    this.setState({
      selectedTasks: newSelectedTasks,
      filteredTaskIds: taskIds,
    });
  };

  searchTasks = async () => {
    const { vehicle, filters } = this.props;
    const taskIds = await axios.get('/uiapi/1/search_task/', {
      params: {
        ...filters,
        vehicle_id: vehicle.id,
        organization_id: vehicle.organization_id
      },
    });

    this.setState({
      filteredTaskIds: taskIds.data
    });
  };

  selectTask = (task) => {
    const { selectedTasks } = this.state;
    let newSelectedTasks = [...selectedTasks];

    if (newSelectedTasks.includes(task.id)) {
      newSelectedTasks = newSelectedTasks.filter(id => id !== task.id);
    } else {
      newSelectedTasks.push(task.id);
    }

    this.setState({
      selectedTasks: newSelectedTasks
    });
  };

  selectAllTasks = (task) => {
    const { selectedTasks } = this.state;
    const { tasks } = this.props.vehicle;
    const filteredTasks = this.getFilteredTasks(tasks);
    let newSelectedTasks = [];

    if (filteredTasks.length !== selectedTasks.length) {
      newSelectedTasks = this.getFilteredTasks(tasks).map(task => task.id);
    }

    this.setState({
      selectedTasks: newSelectedTasks
    });
  };

  setFilters = (filters) => {
    this.filterTasksLocal(filters);
    this.setState({ filters });
  };

  render() {
    const { t, vehicle, selectedWorkshop } = this.props;
    const { filters, selectedTasks } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader
              type='task'
              text={t('tasksHeading')}
              filters={this.state.filters}
              setFilters={this.setFilters}
            />
            <TaskMultiSelectActionBar
              selectedWorkshop={selectedWorkshop}
              vehicle={vehicle}
              selectedTasks={this.getFilteredTasks().filter((task) => selectedTasks.includes(task.id))}
            />
            {screenSize === 'small' && <VehicleDetailsTaskList selectedTasks={selectedTasks} selectAllTasks={this.selectAllTasks} selectTask={this.selectTask} filters={filters} vehicle={vehicle} tasks={this.getFilteredTasks()} />}
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <VehicleDetailsTaskList selectedTasks={selectedTasks} selectAllTasks={this.selectAllTasks} selectTask={this.selectTask} filters={filters} vehicle={vehicle} tasks={this.getFilteredTasks()} />}
              <Route
                exact
                path='/vehicle/:vehicleId/tasks/'
                render={() => <VehicleDetailsTaskContent tasks={this.getFilteredTasks()} vehicle={vehicle} />}
              />
              <Route
                path='/vehicle/:vehicleId/tasks/:taskId'
                render={props => <VehicleDetailsTaskContent tasks={this.getFilteredTasks()} {...props} vehicle={vehicle} />}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainTask);
