import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { getStyle, SERVICE_FLEET, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSubHeading from '../../shared/CBSubHeading';
import { withRouter } from 'react-router';
import NavbarButton from '../../shared/NavbarButton';
import { Route } from 'react-router-dom';
import DataImportMileage from './DataImportMileage';
import {
  fetchOrganizations as _fetchOrganizations,
  updateOrganization as _updateOrganization,
} from '../../../actions/organizationActions';
import CBInputField from '../../shared/form_components/CBInputField';
import CBButtonSubmit from '../../shared/CBButtonSubmit';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBSubmitButton from '../../shared/form_components/CBSubmitButton';

class PredictedMaintenanceSettings extends React.Component {
  state = {
    // If initialData is a list of strings, set formData directly to that array
    formData: [],
    edited: false
  };

  componentDidMount = () => {
    const { fetchOrganizations, organizations, selectedOrganizationId } = this.props;
    fetchOrganizations();
  };

  handleInputChange = (index, newValue) => {
    this.setState(prevState => {
      const updatedFormData = [...prevState.formData];
      updatedFormData[index] = newValue;
      return { formData: updatedFormData, edited: true };
    });
  };

  handleAddField = () => {
    this.setState(prevState => ({
      formData: [...prevState.formData, ''],
      edited: true
    }));
  };

  handleRemoveField = (index) => {
    this.setState(prevState => {
      const updatedFormData = prevState.formData.filter((_, i) => i !== index);
      return { formData: updatedFormData, edited: true };
    });
  };

  handleSubmit = () => {
    const { onSubmit, updateOrganization, selectedOrganizationId, organizations } = this.props;
    // Simply pass back the list of strings
    const selectedOrganization = organizations.find((org) => org.id == selectedOrganizationId);
    const newSettings = {...selectedOrganization.settings};
    const newJobList = [...this.state.formData];

    if (!newSettings.predicted_maintenances) {
      newSettings.predicted_maintenances = {
        periodic_maintenance_tasks: newJobList
      };
    } else {
      newSettings.predicted_maintenances.periodic_maintenance_tasks = newJobList;
    }

    const newOrganization = {
      ...selectedOrganization,
      settings: newSettings
    }

    updateOrganization(selectedOrganizationId, newOrganization);
    this.setState({edited: false})
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, selectedOrganizationId, organizations } = this.props;
    const { formData, edited } = this.state;

    let selectedOrganization = null;

    if(organizations && organizations.length > 0) {
      selectedOrganization = organizations.find((org) => org.id == selectedOrganizationId)
    }

    if (!selectedOrganization) {
      return;
    }

    let newPeriodicMaintenanceTasks = [];

    if (selectedOrganization.settings.predicted_maintenances && selectedOrganization.settings.predicted_maintenances.periodic_maintenance_tasks) {
      newPeriodicMaintenanceTasks = selectedOrganization.settings.predicted_maintenances.periodic_maintenance_tasks;
    }

    if (((selectedOrganizationId !== prevProps.selectedOrganizationId) || (!prevProps.organizations || prevProps.organizations.length == 0)) || (!edited && formData.length != newPeriodicMaintenanceTasks.length) ) {
      this.setState({
        formData: newPeriodicMaintenanceTasks
      })
    }
  }

  render() {
    const {
      t,
      service,
      selectedWorkshopId,
      user,
      selectedOrganizationId,
    } = this.props;

    const { formData, edited } = this.state; // We'll render directly from formData

    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <div style={getStyle(screenSize, styles, 'sectionContainer')}>
              <CBSubHeading>
                Edellisen määräaikaishuollon työtehtäväkentät
              </CBSubHeading>
              <p style={{marginTop: 8}}>Aseta työtehtäväkentät, joita käytetään aina määräaikaishuolloissa. Jos mikä tahansa työtehtävistä löytyy huollosta, sitä pidetään määräaikaishuoltona. Edellisen määräaikaishuollon tietoja käytetään tulevan määräaikaishuollon ajankohdan laskemiseen.</p>
            </div>
            <div style={getStyle(screenSize, styles, 'formContainer')}>
              {formData.map((fieldValue, index) => (
                <div
                  key={index}
                  style={getStyle(screenSize, styles, 'fieldContainer')}
                >
                  <CBInputField
                    name={`field-${index}`}
                    value={fieldValue}
                    placeholder="Kentän arvo"
                    onChange={(e) => this.handleInputChange(index, e.value)}
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  />
                  <CBButtonWithIconSmall
                    style={getStyle(screenSize, styles, 'button')}
                    onClick={() => this.handleRemoveField(index)}
                    text="Poista kenttä"
                    textStyle={getStyle(screenSize, styles, 'buttonText')}
                  />
                </div>
              ))}
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <CBButtonWithIconSmall
                  onClick={this.handleAddField}
                  text="Lisää kenttä"
                  textStyle={getStyle(screenSize, styles, 'buttonText')}
                />
                <CBSubmitButton
                  disabled={!edited}
                  onClick={this.handleSubmit}
                  text="Tallenna" />
              </div>
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    formContainer: {
      maxWidth: 800
    },
    container: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
    },
    sectionContainer: {
      marginBottom: 16,
    },
    fieldContainer: {
      marginBottom: '12px',
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    buttonText: {
      fontSize: 14,
      fontWeight: 700,
      color: '#0095da',
      padding: 8,
      margin: 0,
    },
    button: {
      width: 200,
      border: '2px solid #0095da50',
      color: '#0095da',
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  medium: {
    container: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
    organizationRequest: state.organization,
    organizations: state.organization.organizations,
    loading: state.organization.organizationListStatus.loading,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchOrganizations: _fetchOrganizations,
    updateOrganization: _updateOrganization,
  }
)(withRouter(translate('SettingsView')(PredictedMaintenanceSettings)));
