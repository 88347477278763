import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import VehicleDetailsLabelAndValue from '../VehicleDetailsLabelAndValue';
import { translate } from 'react-i18next';
import { formatDateTime, styleMerge } from '../../../../utils/utils';
import CBLabelAndCheckbox from '../../../shared/CBLabelAndCheckbox';

class DetailsTaskListItem extends React.Component {
  getPriorityStyle = (task) => {
    let color = '';
    switch (task.priority) {
      case 'low':
        color = '#4EBFEF';
        break;
      case 'medium':
        color = '#E6CA38';
        break;
      case 'high':
        color = '#FF3B3B';
        break;
      default:
        return {};
    }

    return {
      borderRight: `8px solid ${color}`,
    };
  };

  getContainerStyle = () => {
    const { task, isSelected } = this.props;

    let style = styleMerge(styles.rootContainer, this.getPriorityStyle(task))

    if (isSelected) {
      style = styleMerge(style, styles.selected);
    }

    return style;

  };

  render() {
    const { task, vehicle, t, isMultiSelected, selectTask, isSelected } = this.props;

    return (
      <div style={this.getContainerStyle()}>
        <CBLabelAndCheckbox additionalStyles={{ padding: 8 }} checked={isMultiSelected} onChange={() => selectTask(task)} />
        <Link style={{width: '100%'}} to={`/vehicle/${vehicle.id}/tasks/${task.id}`}>
          <div style={styles.listItemContainer}>
            <div style={styleMerge(styles.innerContainer, ['done', 'cancelled'].includes(task.status) ? styles.grey : {})}>

              <VehicleDetailsLabelAndValue
                label={moment(task.created_at).format('D.M.Y')}
                value={task.title}
              />
              <VehicleDetailsLabelAndValue
                alignRight
                label={task.vehicle_registration_number}
                value={t(task.status, { ns: 'TaskListView' }) + (task.autofutur_work_order_task ? ' (Autofutur)' : '')}
              />
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

const styles = {
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255,255,255,.3)',
  },
  listItemContainer: {
    padding: 10,
    width: '100%'
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selected: {
    borderLeft: '4px solid #27bbff',
  },
  grey: {
    opacity: '60%',
  },
};

export default translate('TaskListView')(DetailsTaskListItem);
