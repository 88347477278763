import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import '../../../../App.css';

import { getStyle, SERVICE_WORKSHOP, SERVICE_FLEET } from '../../../../utils/utils';
import CBInputField from '../../../shared/form_components/CBInputField';
import CBTextAreaField from '../../../shared/form_components/CBTextAreaField';

import {
  createVehicleDocument as _createVehicleDocument,
  updateVehicleDocument as _updateVehicleDocument,
} from '../../../../actions/vehicleActions';
import {
  getLengthValidator,
} from '../../../../utils/validators';

import CBModal from '../../../shared/CBModal';

import CBDropzone from '../../../shared/CBDropzone';

import CBSelectField from '../../../shared/form_components/CBSelectField';
import CBLabelAndCheckbox from '../../../shared/CBLabelAndCheckbox';
import FileBox from '../FileBox';


class AddDocumentModal extends Component {
  constructor(props) {
    super(props);

    const { document, vehicle } = props;

    const selectedVehicle = vehicle ? vehicle.id : undefined;

    this.state = {
      formData: {
        title: document ? document.title : '',
        description: document ? document.description : '',
        vehicle: document ? document.vehicle : selectedVehicle,
        new_file: null,
        file_name: document ? document.file_name : null,
        mime_type: document ? document.mime_type : null,
        minimum_role: document ? document.minimum_role : 'employee',
        organization: document ? document.organization : null,
        workshop: document ? document.workshop : null,
        allow_care_service_access: document ? document.allow_care_service_access : false,
      }
    };
  }

  componentDidMount = () => {
    const { user, document } = this.props;
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
      },
    };

    this.setState(newState);
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formData } = this.state;
    return formData.title && formData.vehicle && formData.file_name;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      createDocument,
      createVehicleDocument,
      updateVehicleDocument,
      updateDocument,
      document,
      closeModal,
      vehicle,
      service,
      selectedWorkshopId,
      selectedOrganizationId,
    } = this.props;

    const newFormData = {...formData};

    if (selectedWorkshopId) {
      newFormData.workshop = selectedWorkshopId;
      newFormData.organization = vehicle.organization_id;
    } else if (selectedOrganizationId) {
      newFormData.organization = selectedOrganizationId;
    }

    if (document) {
      if (!newFormData.new_file) {
        delete newFormData.new_file;
      }

      updateVehicleDocument(document.id, newFormData, service === SERVICE_WORKSHOP && selectedWorkshopId);
    } else {
      createVehicleDocument(newFormData, service === SERVICE_WORKSHOP && selectedWorkshopId);
    }

    closeModal();
  };

  getRoleOptions = () => {
    const { t, vehicle } = this.props;

    const roleOptions = [
      {
        label: t('roleEmployee', {ns: 'Permissions'}),
        value: 'employee',
      },
    ];

    if (['admin', 'manager'].includes(vehicle.user_role)) {
      roleOptions.unshift({
        label: t('roleManager', {ns: 'Permissions'}),
        value: 'manager',
      });
    }

    if (['admin'].includes(vehicle.user_role)) {
      roleOptions.unshift({
        label: t('roleAdmin', {ns: 'Permissions'}),
        value: 'admin',
      });
    }

    return roleOptions;
  };

  onDropFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileChanged(reader.result, file);
      };
    });
  };

  fileChanged = (fileBase64, file) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData.new_file = fileBase64;
    newFormData.file_name = file.name;
    newFormData.mime_type = file.type;
    this.setState({ formData: newFormData });
  };

  deleteImage = (image) => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    // Is newly added image, not yet saved to backend
    if (image.preview) {
      newFormData.image_actions = newFormData.image_actions.filter(
        (img) => img.preview !== image.preview,
      );
    } else {
      newFormData.image_actions.push(
        {
          action: 'delete',
          id: image.id,
        },
      );
      newFormData.images = newFormData.images.filter(
        (img) => img.id !== image.id,
      );
    }
    this.setState({ formData: newFormData });
  };

  removeFile = () => {
    const { formData } = this.state;
    this.setState({formData: {...formData, file: null, new_file: null, file_name: null, mime_type: null}});
  };

  getAllowedFileTypes = () => ([
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/rtf",
    "application/vnd.oasis.opendocument.text",
    "text/csv",
    "text/plain",
    "text/markdown",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/tiff",
    "image/svg+xml",
    "image/webp"
  ]);

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      vehicle,
      service
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title='Uusi dokumentti'
            descriptionText='Uusi dokumentti ajoneuvolle'
            cancelText='Peruuta'
            submitText='Lähetä'
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
            vehicleText={vehicle && vehicle.registration_number}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>

              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <p
                  style={getStyle(screenSize, styles, 'inputLabelText')}
                  className='font-titillium-web-semi-bold'
                >
                  {t('addDocumentTitleLabel')}
                </p>
                <CBInputField
                  name='title'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.title}
                  placeholder=''
                  onChange={this.updateFormState}
                  validators={[getLengthValidator(1, 40)]}
                  isValidCallback={this.setIsValid}
                />
              </div>
              <div>
                <CBTextAreaField
                  name='description'
                  value={formData.description}
                  onChange={this.updateFormState}
                  labelText={t('addDocumentTextLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  validators={[getLengthValidator(1, 5000)]}
                  isValidCallback={this.setIsValid}
                  validateOnInput
                />
              </div>
              <div style={{marginBottom: 16}}>
                <CBSelectField
                  name='minimum_role'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.minimum_role}
                  options={this.getRoleOptions()}
                  placeholder='Valitse'
                  onChange={this.updateFormState}
                  labelText={t('addDocumentRoleLabel')}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                />
              </div>
              <div>
                <CBLabelAndCheckbox
                  color='blue'
                  checked={formData.allow_care_service_access}
                  labelText={service === SERVICE_FLEET ? t('addDocumentCareServiceAccessLabel') : t('addDocumentCareServiceFleetAccessLabel')}
                  onChange={() => this.setState({formData: {...formData, allow_care_service_access: !formData.allow_care_service_access}})}
                  additionalStyles={{ marginBottom: 16 }}
                />
              </div>
              <p
                  style={getStyle(screenSize, styles, 'inputLabelText')}
                  className='font-titillium-web-semi-bold'
                >
                  {t('addDocumentFileLabel')}
              </p>
              {
                !formData.file_name && (
                  <div style={getStyle(screenSize, styles, 'dropZoneContainer')}>
                    <CBDropzone
                      className='opacity-on-hover'
                      accept={this.getAllowedFileTypes()}
                      onDropAccepted={this.onDropFile}
                    >
                      <p style={getStyle(screenSize, styles, 'dropZoneText')}>
                        Lisää tiedosto...
                      </p>
                    </CBDropzone>
                  </div>
                )
              }

              {
                  formData.file_name && (
                      <FileBox fileName={formData.file_name} mimeType={formData.mime_type} onDelete={this.removeFile} />
                  )
              }
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    inputContainer: {
      marginBottom: 16,
    },
    imagesContainer: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    dropZoneContainer: {
      marginTop: 16,
    },
    dropZoneText: {
      color: 'rgb(102, 102, 102)',
      fontSize: 24,
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
      textAlign: 'center',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleDocument: _createVehicleDocument,
    updateVehicleDocument: _updateVehicleDocument,
  },
)(translate('VehicleDetailsView')(AddDocumentModal));
