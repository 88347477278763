import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import {
    changeVehicleGarage as _changeVehicleGarage,
} from '../../../actions/vehicleActions';


import { ListBox } from 'primereact/listbox';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBModal from '../../shared/CBModal';


class ChangeGarageModal extends Component {
  constructor(props) {
    super(props);
    const { vehicle } = props;
    this.state = {
      formData: {
        garage_id: (vehicle && vehicle.garage_id) || '',
      },
      formValid: {
      },
    };
  }

  componentDidMount = () => {
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
      },
    };

    this.setState(newState);
  };

  getIsValid = () => {
    const { formData } = this.state;
    const { value, read_at } = formData;

    return (value && read_at) || (!value && !read_at);
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      closeModal,
      vehicle,
      selectedOrganizationId,
      changeVehicleGarage
    } = this.props;

    const data = {
      ...formData,
    }

    changeVehicleGarage(vehicle.id, data, selectedOrganizationId);

    closeModal();
  };

  closeModal = () => {
    const {
      closeModal,
    } = this.props;

    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      afterOpenModal,
      t,
      vehicle,
      garages,
      selectedOrganizationId
    } = this.props;

    const currentOrganizationGarages = garages.filter(garage => garage.organization_id == selectedOrganizationId);

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={this.closeModal}
            title={t('changeGarageTitle')}
            descriptionText={t('changeGarageDescription')}
            cancelText={t('cancel')}
            submitText={t('save')}
            vehicleText={vehicle.registration_number}
            submitForm={this.submitForm}
            isValid={!!formData.garage_id}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              <div style={getStyle(screenSize, styles, 'garageListContainer')}>
                <p style={getStyle(screenSize, styles, 'subTitle')}>
                  {t('Autotalli')}
                </p>
                <ListBox
                  filter
                  value={currentOrganizationGarages.find((gar) => gar.id === formData.garage_id)}
                  onChange={(e) => { this.updateFormState({ target: 'garage_id', value: e.value ? e.value.id : undefined }); }}
                  options={currentOrganizationGarages}
                  optionLabel='name'
                  className='w-full'
                  listStyle={{width: '400px'}}
                />
              </div>
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px',
      gap: 16,
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center',
      paddingBottom: 6,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    selectedOrganizationId: state.organization.selectedOrganization,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    garages: state.garage.garages,
  };
}

export default connect(
  mapStateToProps,
  {
    changeVehicleGarage: _changeVehicleGarage,
  },
)(translate('VehicleDetailsView')(ChangeGarageModal));
