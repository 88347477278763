import React from 'react';
import { translate } from 'react-i18next';
import { Button } from 'primereact/button';
import { dbDateFormat, finnishDateFormat, getStyle, SERVICE_ADMIN, SERVICE_FLEET, SERVICE_WORKSHOP, storeUpdateObjectInArray, styleMerge } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBInputField from '../../shared/form_components/CBInputField';
import CBSelectField from '../../shared/form_components/CBSelectField';
import { getEmailValidator } from '../../../utils/validators';
import CBButtonWithIconSmall from '../../shared/CBButtonWithIconSmall';
import CBPromptModal from '../../shared/CBPromptModal';
import { Chips } from 'primereact/chips';
import CBDatePicker from '../../shared/CBDatePicker';
import CBDatePickerInput from '../../shared/CBDatePickerInput';
import moment from 'moment';
import CBSelect from '../../shared/CBSelect';

class ReportPriceRuleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: Object.assign({}, props.priceRule),
      deleteModalIsOpen: false,
    };
  }

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  resetRow = () => {
    this.setState({
      formData: {
        description: '',
        valid_from_date: null,
        valid_to_date: null,
        price: 0.00,
        product_type: null,
      },
    });
  };

  updateItem = () => {
    const {
      updatePriceRule,
    } = this.props;

    const { formData } = this.state;

    updatePriceRule(formData.id, formData);
  };

  createItem = () => {
    const {
      createPriceRule,
    } = this.props;

    const { formData } = this.state;

    createPriceRule(formData);

    this.resetRow();
  };

  deleteItem = () => {
    const { deletePriceRule } = this.props;
    const { formData } = this.state;

    deletePriceRule(formData.id);
  };

  isChanged = () => {
    const { priceRule } = this.props;
    const { formData } = this.state;
    return priceRule.valid_from_date != formData.valid_from_date
      || priceRule.valid_to_date != formData.valid_to_date
      || priceRule.price != formData.price
      || priceRule.product_type != formData.product_type;
  };

  isValid = () => {
    const { formData } = this.state;
    return !!formData.valid_from_date
      && !!formData.valid_to_date && !!formData.price && !!formData.product_type;
  };


  render() {
    const { formData, deleteModalIsOpen } = this.state;

    const { isNew, t } = this.props;

    const reportTypeOptions = [
      { value: 'sale_report_creation', label: t('saleReportCreation') },
      { value: 'pdf_sale_report_creation', label: t('pdfSaleReportCreation') },
      { value: 'sale_report_recurrence', label: t('saleReportRecurrence') },
      { value: 'purchase_report', label: t('purchaseReport') },
      { value: 'purchase_report_sms', label: t('purchaseReportSMS') },
    ]

    return (
      <CBMediaQuery>
        {screenSize => (
          <React.Fragment>
            <tr style={getStyle(screenSize, styles, 'row')}>
              <td style={styleMerge(getStyle(screenSize, styles, 'tableTd'), getStyle(screenSize, styles, 'tdDescription'))}>
                <CBSelect
                  value={formData.product_type}
                  onChange={(elem) => { this.updateFormState({ target: 'product_type', value: elem.target.value }); }}
                >
                  {
                    reportTypeOptions.map((opt) => <option key={opt.value} value={opt.value}>{opt.label}</option>)
                  }
                </CBSelect>
              </td>
              <td className='settings-datepicker-container' style={styleMerge(getStyle(screenSize, styles, 'tableTd'), getStyle(screenSize, styles, 'tdDate'))}>
                <CBDatePickerInput
                  displayedDate={formData.valid_from_date ? moment(formData.valid_from_date) : null}
                  type='date'
                  dateFormat={finnishDateFormat}
                  changeDate={(value) => {
                    this.updateFormState({ target: 'valid_from_date', value: moment(value).format(dbDateFormat) });
                  }}
                />
              </td>
              <td className='settings-datepicker-container' style={styleMerge(getStyle(screenSize, styles, 'tableTd'), getStyle(screenSize, styles, 'tdDate'))}>
                <CBDatePickerInput
                  displayedDate={formData.valid_to_date ? moment(formData.valid_to_date) : null}
                  type='date'
                  dateFormat={finnishDateFormat}
                  changeDate={(value) => {
                    this.updateFormState({ target: 'valid_to_date', value: moment(value).format(dbDateFormat) });
                  }}
                />
              </td>
              <td style={styleMerge(getStyle(screenSize, styles, 'tableTd'), getStyle(screenSize, styles, 'tdPrice'))}>
                <CBInputField
                  name='price'
                  value={formData.price}
                  onChange={this.updateFormState}
                  noValidationOnChange
                  blurOnEnter
                  type='number'
                />
              </td>
              <td style={getStyle(screenSize, styles, 'tableTdDelete')}>
                <div style={getStyle(screenSize, styles, 'actionsContainer')}>
                  {
                    !isNew && (
                      <CBButtonWithIconSmall
                        text={t('updatePriceRule')}
                        onClick={this.updateItem}
                        icon='/img/icon_save.svg'
                        disabled={!this.isValid() || !this.isChanged()}
                      />
                    )
                  }
                  {
                    isNew && (
                      <CBButtonWithIconSmall
                        text={t('addPriceRule')}
                        onClick={this.createItem}
                        icon='/img/icon_plus.svg'
                        disabled={!this.isValid()}
                      />
                    )
                  }
                  {
                    !isNew && (
                      <Button
                        icon='pi pi-times'
                        rounded
                        outlined
                        severity='danger'
                        aria-label='Cancel'
                        size='medium'
                        onClick={() => this.setState({ deleteModalIsOpen: true })}
                      />
                    )
                  }
                </div>
              </td>
            </tr>
            <CBPromptModal
              modalIsOpen={deleteModalIsOpen}
              closeModal={() => this.setState({deleteModalIsOpen: false})}
              afterOpenModal={() => {}}
              title={t('deletePriceRuleTitle')}
              text={t('deletePriceRuleText')}
              buttonText={t('deletePriceRuleButton')}
              performAction={() => this.deleteItem()}
            />
          </React.Fragment>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    tableTd: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 8,
      paddingRight: 8,
    },
    tdDescription: {
      minWidth: 275,
    },
    tdPrice: {
      maxWidth: 125,
    },
    tdDate: {
      maxWidth: 125,
    },
    tableTdSmall: {
      border: '1px solid rgba(255, 255, 255, .3)',
      color: '#FFFFFF',
      paddingLeft: 8,
      paddingRight: 8,
    },
    tableTdDelete: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    optionStyle: {
      backgroundColor: 'rgb(0, 149, 218)',
    },
    row: {
      marginBottom: 8,
    },
  },
};

export default translate('SettingsView')(ReportPriceRuleRow);
