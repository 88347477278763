import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchVehiclesByOrganization as _fetchVehiclesByOrganization } from '../../actions/vehicleActions';
import CBMediaQuery from '../shared/CBMediaQuery';
import { FLEET_INVITATION_TYPES, getStyle, TYPE_ORGANIZATION_GARAGE } from '../../utils/utils';
import VehicleListView from './OrganizationGarageComponents/VehicleListView';
import WorkshopNotificationsList from '../shared/WorkshopNotificationsList';
import SaleReportView from '../SaleReportView/SaleReportView';
import CBAdvertisementWrapper from '../shared/CBAdvertisementWrapper';
import { selectGarage as _selectGarage } from '../../actions/garageActions';
import FleetNavbar from './FleetNavbar';
import TasksLayoutView from '../TasksView/TasksLayoutView';
import { Route } from 'react-router';
import MessagesLayoutView from '../MessagesView/MessagesLayoutView';
import CBPromptModal from '../shared/CBPromptModal';
import {
  acceptCareServiceInvitation as _acceptCareServiceInvitation,
  declineCareServiceInvitation as _declineCareServiceInvitation,
  acceptInvitation as _acceptInvitation,
  declineInvitation as _declineInvitation,
} from '../../actions/authUserActions';
import { setLastListUrl as _setLastListUrl } from '../../actions/appActions';
import CareServiceResponseModal from '../CareServiceView/components/CareServiceResponseModal';
import NoOrganizationView from '../HomeView/NoOrganizationView';
import moment from 'moment';
import PredictedMaintenancesLayoutView from '../PredictedMaintenancesView/PredictedMaintenancesLayoutView';

class OrganizationGarageLayoutView extends React.Component {
  state = {
    hasFeatureVehicleReports: false,
    hasFeatureTasksAndMessages: false,
  };

  componentDidMount = () => {
    const { selectedOrganizationId, match, fetchVehiclesByOrganization, setLastListUrl, location } = this.props;
    const organizationId = selectedOrganizationId || match.params.organizationId;
    if (organizationId) {
      fetchVehiclesByOrganization(organizationId, { ordering: 'registration_number' });
      this.updateEnabledFeatures(organizationId);
    }

    setLastListUrl(location.pathname);
  };

  componentDidUpdate(prevProps) {
    const { selectedOrganizationId, fetchVehiclesByOrganization, setLastListUrl, location } = this.props;
    if (selectedOrganizationId != prevProps.selectedOrganizationId) {
      fetchVehiclesByOrganization(selectedOrganizationId, { ordering: 'registration_number' });
      this.updateEnabledFeatures(selectedOrganizationId);
    }

    if (location.pathname !== prevProps.location.pathname) {
      setLastListUrl(location.pathname);
    }
  };

  fetchVehicles = (params) => {
    const { selectedOrganizationId, fetchVehiclesByOrganization } = this.props;
    fetchVehiclesByOrganization(selectedOrganizationId, params);
  }

  acceptInvitation = (invitation) => {
    const { acceptInvitation } = this.props;
    acceptInvitation(invitation.id);
  };

  declineInvitation = (invitation) => {
    const { declineInvitation } = this.props;
    declineInvitation(invitation.id);
  };

  acceptCareServiceInvitation = (invitation) => {
    const { acceptCareServiceInvitation } = this.props;
    acceptCareServiceInvitation(invitation.id);
  };

  declineCareServiceInvitation = (invitation) => {
    const { declineCareServiceInvitation } = this.props;
    declineCareServiceInvitation(invitation.id);
  };

  getInvitationTitle = (invitation) => {
    const { t } = this.props;
    switch(invitation.permission_type) {
      case 'organization':
        return t('organizationInvitationPromptTitle');
      case 'garage':
        return t('garageInvitationPromptTitle');
      case 'vehicle':
        return t('garageInvitationPromptTitle');
      default:
        return '';
    }
  };

  getInvitationText = (invitation) => {
    const { t } = this.props;
    switch(invitation.permission_type) {
      case 'organization':
        return t('organizationInvitationPromptText', {
          inviterName: invitation.inviter_name,
          organizationName: invitation.organization_name
        });
      case 'garage':
        return t('garageInvitationPromptText', {
          inviterName: invitation.inviter_name,
          garageName: invitation.garage_name
        });
      case 'vehicle':
        return t('garageInvitationPromptText', {
          inviterName: invitation.inviter_name,
          garageName: invitation.garage_name
        });
      default:
        return '';
    }
  };

  updateEnabledFeatures = (organizationId) => {
    const { user } = this.props;

    const organization = user.organizations.find(org => org.id == organizationId);
    this.setState({
      hasFeatureVehicleReports: organization && organization.feature_vehicle_reports_enabled,
      hasFeatureVehicleReportsPdf: organization && organization.feature_vehicle_reports_pdf_enabled,
      hasFeatureTasksAndMessages: organization && organization.feature_tasks_and_messages_enabled,
      hasFeaturePurchaseReports: organization && organization.feature_purchase_reports_enabled,
    });
  }

  render() {
    const {
      history,
      t,
      garageVehicles,
      match,
      selectedOrganizationId,
      user,
      acceptCareServiceInvitation,
      declineCareServiceInvitation,
    } = this.props;

    const {
      hasFeatureVehicleReports,
      hasFeatureTasksAndMessages,
      hasFeatureVehicleReportsPdf,
      hasFeaturePurchaseReports
    } = this.state;

    const organizationId = selectedOrganizationId || match.params.organizationId;

    const currentOrganization = selectedOrganizationId && user.organizations.find((org) => selectedOrganizationId == org.id);
    const trialIsEnded = currentOrganization && currentOrganization.is_trialing && moment(currentOrganization.trial_end).isBefore(moment(), 'day');

    if (trialIsEnded) {
      return <NoOrganizationView trialIsEnded={trialIsEnded} organization={currentOrganization}  />
    }

    if (!organizationId) {
      return <NoOrganizationView />
    }

    const careServiceInvitation = user.care_service_invitations && user.care_service_invitations.length > 0
      ? user.care_service_invitations[0]
      : undefined;

    const invitations = user.invitations.filter((inv) => FLEET_INVITATION_TYPES.includes(inv.permission_type));
    const permissionInvitation = invitations && invitations.length > 0
      ? invitations[0]
      : undefined;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <FleetNavbar
              organizationId={organizationId}
              hasFeatureVehicleReports={hasFeatureVehicleReports}
              hasFeatureTasksAndMessages={hasFeatureTasksAndMessages}
              hasFeaturePurchaseReports={hasFeaturePurchaseReports}
            />
            <div style={getStyle(screenSize, styles, 'garageContainer')}>
              <Route
                exact
                path='/organization_garage/:organizationId/tasks/'
                render={() => <TasksLayoutView vehicles={garageVehicles} organizationId={organizationId} hasFeatureTasksAndMessages={hasFeatureTasksAndMessages} />}
              />
              <Route
                exact
                path='/organization_garage/:organizationId/predicted-maintenances/'
                render={() => <PredictedMaintenancesLayoutView vehicles={garageVehicles} organizationId={organizationId} />}
              />
              <Route
                exact
                path={[
                  '/organization_garage/:organizationId/vehicles/',
                  '/organization_garage/:organizationId',
                  '/',
                ]}
                render={() => (
                  <VehicleListView
                    vehicles={garageVehicles}
                    fetchVehicles={this.fetchVehicles}
                    goBack={history.goBack}
                    organizationId={organizationId}
                    listType={TYPE_ORGANIZATION_GARAGE}
                    hasFeatureVehicleReports={hasFeatureVehicleReports}
                    hasFeatureVehicleReportsPdf={hasFeatureVehicleReportsPdf}
                    hasFeatureTasksAndMessages={hasFeatureTasksAndMessages}
                  />
                )
                }
              />
              <Route
                path='/organization_garage/:organizationId/messages/'
                render={() => <MessagesLayoutView organizationId={organizationId} hasFeatureTasksAndMessages={hasFeatureTasksAndMessages} />}
              />
              <Route
                path='/organization_garage/:organizationId/vehicle-report/'
                render={() => <SaleReportView hasFeatureVehicleReports={hasFeatureVehicleReports} />}
              />
              {false && (
                <React.Fragment>
                  <div style={getStyle(screenSize, styles, 'workshopNotificationsContainer')}>
                    <WorkshopNotificationsList />
                  </div>
                </React.Fragment>
              )}

            </div>
            {
              permissionInvitation && (
                <CBPromptModal
                  modalIsOpen={!!permissionInvitation}
                  closeModal={() => this.declineInvitation(permissionInvitation)}
                  afterOpenModal={() => { }}
                  title={this.getInvitationTitle(permissionInvitation)}
                  text={this.getInvitationText(permissionInvitation)}
                  buttonText={t('invitationPromptAccept')}
                  cancelText={t('invitationPromptDecline')}
                  performAction={() => this.acceptInvitation(permissionInvitation)}
                />
              )
            }
            {
              careServiceInvitation && (
                <CareServiceResponseModal
                  modalIsOpen={!!careServiceInvitation}
                  closeModal={() => {}}
                  acceptCareServiceInvitation={acceptCareServiceInvitation}
                  declineCareServiceInvitation={declineCareServiceInvitation}
                  afterOpenModal={() => { }}
                  invitation={careServiceInvitation}
                />
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    garageContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    workshopNotificationsContainer: {
      paddingTop: 110,
      width: '30%',
      borderLeft: '1px solid rgba(255, 255, 255, 0.3)',
    },
    advertisementSection: {
      marginLeft: 16,
      marginTop: 16,
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    adsText: {
      fontSize: 12,
      color: '#ffffff',
      fontWeight: 300,
      textDecoration: 'none solid rgb(255, 255, 255)',
      opacity: 0.6,
    },
  },
  medium: {
    garageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    workshopNotificationsContainer: {
      marginTop: 110,
      width: '100%',
      marginLeft: 0,
    },
  },
  small: {
    advertisementContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      height: 'auto',
      borderTop: '1px solid rgba(255, 255, 255, .3)',
      width: 'auto',
      paddingTop: 20,
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    garageVehicles: state.vehicle.garageVehicles,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchVehiclesByOrganization: _fetchVehiclesByOrganization,
    acceptInvitation: _acceptInvitation,
    declineInvitation: _declineInvitation,
    setLastListUrl: _setLastListUrl,
  },
)(translate('HomeView')(OrganizationGarageLayoutView));
