import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBMediaQuery from '../shared/CBMediaQuery';

import { getStyle, INVITATION_WORKSHOP } from '../../utils/utils';
import WorkshopNavbar from './HomeViewComponents/WorkshopNavbar';
import TasksLayoutView from '../TasksView/TasksLayoutView';
import CareServiceLayoutView from '../CareServiceView/CareServiceLayoutView';
import MessagesLayoutView from '../MessagesView/MessagesLayoutView';
import MaintenancesAndVehiclesLayoutView from '../MaintenancesAndVehiclesView/MaintenancesAndVehiclesLayoutView';
import MarketingLayoutView from '../MarketingView/MarketingLayoutView';
import { acceptInvitation as _acceptInvitation, declineInvitation as _declineInvitation } from '../../actions/authUserActions';
import { fetchVehiclesForCareService as _fetchVehiclesForCareService } from '../../actions/vehicleActions';
import { setLastListUrl as _setLastListUrl } from '../../actions/appActions';
import { Route } from 'react-router';
import NoOrganizationView from './NoOrganizationView';
import CBPromptModal from '../shared/CBPromptModal';
import PredictedMaintenancesLayoutView from '../PredictedMaintenancesView/PredictedMaintenancesLayoutView';

class HomeWorkshopUser extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    const { selectedWorkshopId, match, fetchVehiclesForCareService, setLastListUrl, location } = this.props;
    const workshopId = selectedWorkshopId || match.params.workshopId;
    if (workshopId) {
      fetchVehiclesForCareService(workshopId, { ordering: 'registration_number' });
    }

    setLastListUrl(location.pathname);
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, fetchVehiclesForCareService, setLastListUrl, location } = this.props;
    if (selectedWorkshopId != prevProps.selectedWorkshopId) {
      fetchVehiclesForCareService(selectedWorkshopId, { ordering: 'registration_number' });
    }

    if (location.pathname !== prevProps.location.pathname) {
      setLastListUrl(location.pathname);
    }
  };

  acceptInvitation = (invitation) => {
    const { acceptInvitation } = this.props;
    acceptInvitation(invitation.id);
  };

  declineInvitation = (invitation) => {
    const { declineInvitation } = this.props;
    declineInvitation(invitation.id);
  };

  render() {
    const {
      history,
      t,
      selectedWorkshopId,
      user,
      careServiceVehicles
    } = this.props;

    const workshop = user.workshops.find((ws) => ws.id == selectedWorkshopId)

    if (!workshop) {
      return <NoOrganizationView />;
    }

    const invitations = user.invitations.filter((inv) => inv.permission_type === INVITATION_WORKSHOP);
    const invitation = invitations && invitations.length > 0 && invitations[0];
    return (
      <CBMediaQuery>
        {screenSize => (
          <div style={getStyle(screenSize, styles, 'container')}>
            <WorkshopNavbar workshop={workshop} />
            <div>
              <Route
                exact
                path='/'
                render={() => <CareServiceLayoutView workshop={workshop} />}
              />
              <Route
                path='/workshop/:workshopId/tasks'
                render={() => <TasksLayoutView workshop={workshop} />}
              />
              <Route
                path='/workshop/:workshopId/care-service'
                render={() => <CareServiceLayoutView workshop={workshop} />}
              />
              <Route
                exact
                path='/workshop/:workshopId/predicted-maintenances/'
                render={() => <PredictedMaintenancesLayoutView />}
              />
              <Route
                path='/workshop/:workshopId/messages'
                render={() => <MessagesLayoutView workshop={workshop} />}
              />
              <Route
                path='/workshop/:workshopId/vehicles'
                render={() => <MaintenancesAndVehiclesLayoutView workshop={workshop} />}
              />
              <Route
                path='/workshop/:workshopId/marketing'
                render={() => <MarketingLayoutView workshop={workshop} />}
              />
            </div>
            {
              invitation && (
                <CBPromptModal
                  modalIsOpen={!!invitation}
                  closeModal={() => this.declineInvitation(invitation)}
                  afterOpenModal={() => { }}
                  title={t('workshopInvitationPromptTitle')}
                  text={t('workshopInvitationPromptText', { inviterName: invitation.inviter_name, workshopName: invitation.workshop_name })}
                  buttonText={t('invitationPromptAccept')}
                  cancelText={t('invitationPromptDecline')}
                  performAction={() => this.acceptInvitation(invitation)}
                />
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    container: {
    },
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    careServiceVehicles: state.vehicle.careServiceVehicles,
  };
}

export default connect(
  mapStateToProps,
  {
    acceptInvitation: _acceptInvitation,
    declineInvitation: _declineInvitation,
    fetchVehiclesForCareService: _fetchVehiclesForCareService,
    setLastListUrl: _setLastListUrl,
  },
)(translate('HomeView')(HomeWorkshopUser));
