import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import CBInputField from '../../shared/form_components/CBInputField';
import { updateWorkshopInfo as _updateWorkshopInfo } from '../../../actions/workshopActions';
import { fetchAuthUser as _fetchAuthUser } from '../../../actions/authUserActions';
import CBSubmitButton from '../../shared/form_components/CBSubmitButton';
import CBSpinnerFloating from '../../shared/CBSpinnerFloating';
import {
  getLengthValidator,
  getNumberValidator,
  getPhoneValidator,
} from '../../../utils/validators';
import CBLabelAndCheckbox from '../../shared/CBLabelAndCheckbox';

class WSSettingsProfileInfoForm extends React.Component {
  constructor(props) {
    super(props);
    const { workshops, selectedWorkshopId } = props;
    this.state = {
      formData: workshops.find((ws) => ws.id == selectedWorkshopId),
      formValid: {},
      selectedWorkshop: workshops.find((ws) => ws.id == selectedWorkshopId)
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkshopId, workshops } = this.props;

    if (selectedWorkshopId != prevProps.selectedWorkshopId) {
      this.setState({
        formData: workshops.find((ws) => ws.id == selectedWorkshopId),
        formValid: {},
        selectedWorkshop: workshops.find((ws) => ws.id == selectedWorkshopId)
      });
    }
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
    };
    newFormValid[name] = isValid;

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData, selectedWorkshop } = this.state;
    const { updateWorkshopInfo } = this.props;
    const newFormData = Object.assign({}, formData);
    delete newFormData.image;
    updateWorkshopInfo(newFormData, selectedWorkshop.id);
  };

  render() {
    const { user, userRequest, t } = this.props;
    const { formData } = this.state;
    return (
      <div style={styles.formContainer}>
        {userRequest.profileStatus.loading && <CBSpinnerFloating />}
        <CBInputField
          labelText={t('companyNameLabel')}
          name="organization_name"
          onChange={this.updateFormState}
          value={formData.organization_name}
          serverErrors={userRequest.profileStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(1, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('workshopNameLabel')}
          name="name"
          onChange={this.updateFormState}
          value={formData.name}
          serverErrors={userRequest.profileStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(5, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('addressLabel')}
          name="street_address"
          onChange={this.updateFormState}
          value={formData.street_address}
          serverErrors={userRequest.profileStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(5, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('zipCodeLabel')}
          name="zip_code"
          onChange={this.updateFormState}
          type="number"
          value={formData.zip_code}
          serverErrors={userRequest.profileStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getNumberValidator(), getLengthValidator(5, 10)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('cityLabel')}
          name="city"
          onChange={this.updateFormState}
          value={formData.city}
          serverErrors={userRequest.profileStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getLengthValidator(2, 100)]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBInputField
          labelText={t('phoneNumberLabel')}
          name="phone_number"
          onChange={this.updateFormState}
          value={formData.phone_number}
          serverErrors={userRequest.profileStatus.error}
          isValidCallback={this.setIsValid}
          validators={[getPhoneValidator()]}
          labelTextStyle={styles.fieldContainerLeftAlign}
        />
        <CBLabelAndCheckbox
          checked={formData.use_vehicle_identifier}
          labelText={t('useVehicleIdentifier')}
          color='white'
          onChange={() => this.updateFormState({ target: 'use_vehicle_identifier', value: !formData.use_vehicle_identifier })}
        />
        <div style={styles.fill} />
        <CBSubmitButton
          disabled={userRequest.profileStatus.loading || !this.getIsValid()}
          onClick={this.submitForm}
        />
      </div>
    );
  }
}

const styles = {
  formContainer: {
    position: 'relative',
  },
  personalInformationContainer: {},
  fieldContainerLeftAlign: {
    marginBottom: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  fill: {
    height: 25,
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    userRequest: state.authUser,
    selectedWorkshopId: state.workshop.selectedWorkshop,
    workshops: state.workshop.workshops,
  };
}

export default connect(
  mapStateToProps,
  {
    updateWorkshopInfo: _updateWorkshopInfo,
    fetchAuthUser: _fetchAuthUser,
  },
)(translate('WSSettingsView')(WSSettingsProfileInfoForm));
