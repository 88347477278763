import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';

import { getStyle, SERVICE_WORKSHOP } from '../../../utils/utils';

import {
  setMassVehicleTaskStatus as _setMassVehicleTaskStatus,
} from '../../../actions/vehicleActions';

import CBModal from '../../shared/CBModal';
import CBSelectField from '../../shared/form_components/CBSelectField';


class ChangeTaskStatusesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        status: ''
      },
    };
  }

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { selectedTaskIds, setMassVehicleTaskStatus, service, selectedWorkshopId, closeModal } = this.props;
    const { formData } = this.state;

    setMassVehicleTaskStatus({...formData, task_ids: selectedTaskIds}, service === SERVICE_WORKSHOP && selectedWorkshopId);

    closeModal();
  };

  getStatusOptions = () => {
    return [
      {
        label: '-',
        value: ''
      },
      {
        label: 'Luotu',
        value: 'created'
      },
      {
        label: 'Työn alla',
        value: 'in_progress'
      },
      {
        label: 'Valmis',
        value: 'done'
      },
      {
        label: 'Peruutettu',
        value: 'cancelled'
      },
    ]
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      selectedTaskIds
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title='Muuta tehtävien tilaa'
            descriptionText={`Muuta kaikkien valittujen tehtävien tilaa. ${selectedTaskIds.length} tehtävää valittu.`}
            cancelText='Peruuta'
            submitText='Lähetä'
            submitForm={this.submitForm}
            isValid={formData.status !== ''}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <CBSelectField
                  name='status'
                  inputStyle={getStyle(screenSize, styles, 'input')}
                  value={formData.status}
                  options={this.getStatusOptions()}
                  placeholder='Valitse'
                  onChange={this.updateFormState}
                  labelText={'Tila'}
                  labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                />
              </div>
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    inputContainer: {
      marginBottom: 16,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    setMassVehicleTaskStatus: _setMassVehicleTaskStatus,
  },
)(translate('TaskListView')(ChangeTaskStatusesModal));
