import React from 'react';
import { Route } from 'react-router';
import { translate } from 'react-i18next';
import axios from 'axios';
import isEqual from "lodash/isEqual";
import VehicleDetailsMileageContent from './VehicleDetailsMileageContent';
import VehicleDetailsMileageList from './VehicleDetailsMileageList';
import VehicleDetailsSectionHeader from './VehicleDetailsSectionHeader';
import CBMediaQuery from '../../shared/CBMediaQuery';
import MileageMultiSelectActionBar from './MileageMultiSelectActionBar';

class VehicleDetailsMainMileage extends React.Component {
  state = {
    selectedMileage: [],
    filteredMileageIds: null,
    filters: {
      search: '',
    }
  }

  componentDidMount() {
    this.filterMileageLocal(this.state.filters);
  }

  componentDidUpdate(prevProps) {
    const { vehicle } = this.props;
    const { vehicle: prevVehicle } = prevProps;
    if (!isEqual(vehicle.mileages, prevVehicle.mileages)) {
      this.filterMileageLocal(this.state.filters);
    }
  };

  getFilteredMileage = () => {
    const { mileages } = this.props.vehicle;
    const { filteredMileageIds } = this.state;

    if (filteredMileageIds === null) {
      return mileages;
    }

    return mileages.filter(mileage =>
      filteredMileageIds.includes(parseInt(mileage.id)),
    );
  };

  filterMileageLocal = (filters) => {
    return;
    const { vehicle } = this.props;

    const mileageIds = vehicle.mileages.filter(mileage => {
      return true
    }).map((t) => t.id);

    const newSelectedMileage = this.state.selectedMileage.filter(id => mileageIds.includes(id));
    this.setState({
      selectedMileage: newSelectedMileage,
      filteredMileageIds: mileageIds,
    });
  };

  selectMileage = (mileage) => {
    const { selectedMileage } = this.state;
    let newSelectedMileage = [...selectedMileage];

    if (newSelectedMileage.includes(mileage.id)) {
      newSelectedMileage = newSelectedMileage.filter(id => id !== mileage.id);
    } else {
      newSelectedMileage.push(mileage.id);
    }

    this.setState({
      selectedMileage: newSelectedMileage
    });
  };

  selectAllMileage = (mileage) => {
    const { selectedMileage } = this.state;
    const { mileages } = this.props.vehicle;
    const filteredMileage = this.getFilteredMileage(mileages);
    let newSelectedMileage = [];

    if (filteredMileage.length !== selectedMileage.length) {
      newSelectedMileage = this.getFilteredMileage(mileages).map(mileage => mileage.id);
    }

    this.setState({
      selectedMileage: newSelectedMileage
    });
  };

  setFilters = (filters) => {
    this.filterMileageLocal(filters);
    this.setState({ filters });
  };

  render() {
    const { t, vehicle } = this.props;
    const { filters, selectedMileage } = this.state;
    return (
      <CBMediaQuery>
        {screenSize => (
          <div>
            <VehicleDetailsSectionHeader
              type='mileage'
              text={t('mileagesHeading')}
              filters={this.state.filters}
              setFilters={this.setFilters}
            />
            <MileageMultiSelectActionBar
              selectedMileage={this.getFilteredMileage().filter((mileage) => selectedMileage.includes(mileage.id))}
            />
            {screenSize === 'small' && <VehicleDetailsMileageList selectedMileage={selectedMileage} selectAllMileage={this.selectAllMileage} selectMileage={this.selectMileage} filters={filters} vehicle={vehicle} mileages={this.getFilteredMileage()} />}
            <div style={styles.mainContentContainer}>
              {screenSize !== 'small' && <VehicleDetailsMileageList selectedMileage={selectedMileage} selectAllMileage={this.selectAllMileage} selectMileage={this.selectMileage} filters={filters} vehicle={vehicle} mileages={this.getFilteredMileage()} />}
              <Route
                exact
                path='/vehicle/:vehicleId/mileages/'
                render={() => <VehicleDetailsMileageContent mileages={this.getFilteredMileage()} vehicle={vehicle} />}
              />
              <Route
                path='/vehicle/:vehicleId/mileages/:mileageId'
                render={props => <VehicleDetailsMileageContent mileages={this.getFilteredMileage()} {...props} vehicle={vehicle} />}
              />
            </div>
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

export default translate('VehicleDetailsView')(VehicleDetailsMainMileage);
