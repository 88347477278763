import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import CBMediaQuery from '../../shared/CBMediaQuery';
import '../../../App.css';

import { getStyle, SERVICE_WORKSHOP } from '../../../utils/utils';

import {
  createAutofuturWorkOrder as _createAutofuturWorkOrder,
} from '../../../actions/vehicleActions';

import CBModal from '../../shared/CBModal';


class CreateAutofuturWorkOrderModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        status: ''
      },
    };
  }

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { createAutofuturWorkOrder, closeModal, vehicle, selectedTasks, selectedWorkshop } = this.props;
    const { formData } = this.state;

    const validTasks = selectedTasks.filter(task => task.autofutur_work_order_task === null);

    createAutofuturWorkOrder({
      vehicle_id: vehicle.id,
      task_ids: validTasks.map(task => task.id),
      workshop_id: selectedWorkshop.id,
    });

    closeModal();
  };


  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      closeModal,
      afterOpenModal,
      t,
      selectedTasks,
      vehicle
    } = this.props;

    const validTasks = selectedTasks.filter(task => task.autofutur_work_order_task === null);
    const invalidTasks = selectedTasks.filter(task => task.autofutur_work_order_task !== null);

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title='Luo työmääräin AutoFuturiin'
            cancelText='Peruuta'
            submitText='Lähetä'
            submitForm={this.submitForm}
            isValid={validTasks && validTasks.length > 0}
            vehicleText={vehicle ? vehicle.registration_number : ''}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              {validTasks.length > 0 && (
                <div>
                  <p style={getStyle(screenSize, styles, 'headingText')}>Seuraavat työtehtävät lisätään työmääräimelle</p>
                  <ul>
                    {validTasks.map(task => (
                      <li style={getStyle(screenSize, styles, 'text')} key={task.id}>{task.title}</li>
                    ))}
                  </ul>
                </div>
              )}
              {invalidTasks.length > 0 && (
                <div>
                  <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 16}}>
                    <p style={{...getStyle(screenSize, styles, 'headingText'), margin: 0, marginRight: 8}}>Seuraavat tehtävät on jo viety työmääräimelle ja niitä ei viedä</p>
                    <img style={{height: 25, width: 25}} src='/img/icon_warning_blue.svg' alt='info' />
                  </div>

                  <ul>
                    {invalidTasks.map(task => (
                      <li style={getStyle(screenSize, styles, 'text')} key={task.id}>{`${task.title} (Työmääräimen numero: ${task.autofutur_work_order_task.autofutur_work_order.external_id})`}</li>
                    ))}
                  </ul>
                </div>
              )}

            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px 32px'
    },
    inputContainer: {
      marginBottom: 16,
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    text: {
      color: 'black',
    },
    headingText: {
      color: 'black',
      fontSize: 18,
    }
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    },
  }
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createAutofuturWorkOrder: _createAutofuturWorkOrder,
  },
)(translate('VehicleDetailsView')(CreateAutofuturWorkOrderModal));
